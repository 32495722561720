<template>
  <div class="result">
    <Table
      v-if="results.headers"
      :headers="headers"
      :actions="actions"
      id="custom-table-for-width"
      :class="{'dynamic-width': customTableWidth < 1114 }"
      hideDefaultFooter>

      <template v-slot:body>
        <template v-for="group in items">
          <tr
            :key="group.id"
            class="row-group"
            @click="toggleRowGroup(group.name)"
            :class="{
              'expand-row-group': expandedRowsGroup.includes(group.name),
            }"
          >
            <td class="totalTableSupWidth">
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <img :src="getImgWithExt(['icons', group.name === 'Survey' ? 'result-group-survey' : group.name === 'Blood' ? 'result-group-blood' : 'result-group-default', 'svg'])" alt="icon" />
                  <v-icon v-if="!expandedRowsGroup.includes(group.name)">mdi-chevron-down</v-icon>
                  <v-icon v-if="expandedRowsGroup.includes(group.name)">mdi-chevron-up</v-icon>
                  <span class="group-values">{{group.name}} {{getSelectedItems(group.resultSupplements.$values)}} ({{group.count}})</span>
                </div>
              </div>
            </td>
            <td class="pa-0 dosageUponArisingWidth">
              <div class="d-flex align-center justify-center">
                <span class="group-values">{{group.totalDosageUponArising}}</span>
              </div>
            </td>
            <td class="pa-0 dosageBreakfastWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosageBreakfast}}
              </div>
            </td>
            <td class="pa-0 dosage10amWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosage10am}}
              </div>
            </td>
            <td class="pa-0 dosageLunchWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosageLunch}}
              </div>
            </td>
            <td class="pa-0 dosage3pmWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosage3pm}}
              </div>
            </td>
            <td class="pa-0 dosageDinnerWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosageDinner}}
              </div>
            </td>
            <td class="pa-0 dosage7pmWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosage7pm}}
              </div>
            </td>
            <td class="pa-0 dosageBeforeSleepWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalDosageBeforeSleep}}
              </div>
            </td>
            <td class="pa-0 daysPerWeekWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.daysPerWeek}}
              </div>
            </td>
            <td class="patientNotesWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.patientNotes}}
              </div>
            </td>
            <td class="totalScoreWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.averageScore}}
              </div>
            </td>
            <td class="daysSupplyWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.daysSupply}}
              </div>
            </td>
            <td class="bottlesWidth">
              <div class="d-flex align-center justify-center group-values">
                {{group.totalBottles}}
              </div>
            </td>
            <td class="totalCostWidth">
              <div class="d-flex align-center justify-center group-values">
                ${{group.totalPrice}}
              </div>
            </td>
            <td>
            </td>
          </tr>
          <template v-for="(item, index) in group.resultSupplements.$values">
            <tr
              :key="item.id"
              class="custom-result-table__dynamic"
              v-if="expandedRowsGroup.includes(item.sourceName)"
              :class="{
              'expand-row': expandedRows.includes(item.id),
              'is-manual-entered': item.manuallyEntered,
              'is-default': item.isDefault
            }"
            >
              <td ref="totalTableSupWidth" class="totalTableSupWidth" :class="{'red-line': item.sourceName === 'Blood', 'blue-line': item.sourceName === 'Survey'}"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center">
                    <v-icon @click="toggleRow(item.id)">mdi-chevron-down</v-icon>
                    <checkbox :class="{'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}" :color="item.isDuplicated ? '#F68E87' : '#008C89'" :inputValue="item.selected" @change="updateSupplementUse(item, $event)"></checkbox>
                    <span
                      class="mr-2 font-weight-bold supplement-width d-flex align-center"
                      :class="{'disabled': visit.status === 'Completed'}"
                      @click="editResult(item)">{{item.supplementName}}
                    <v-tooltip top :max-width="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click.stop="TOGGLE_DIALOG({ name: 'modalCrgNotes', isOpen: true, title: item.supplementName, data: item })" :color="item.isDuplicated ? '#F68E87' : '#A0AEC0'" class="mr-1" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      <span>{{item.supplementDescription}}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.brandLogo">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" @click.stop style="width: 28px;background: transparent">
                          <Img width="28px" :src="item.brandLogo" />
                        </div>
                      </template>
                      <span>{{item.brandName}}</span>
                    </v-tooltip>
                  </span><!--:style="{'max-width': 145 - dynamicSupplementNameMaxWidth([item.manuallyEntered, item.fromProtocol, item.fromBlood, item.fromBloodPattern, item.fromBloodMarkerLab, item.fromBloodMarkerOptimal, item.onlyOneBottle]) + 'px!important'}"-->
                    <!--                  <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon color="#A0AEC0" class="mr-1" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>{{item.supplementName}}</span>
                                      </v-tooltip>-->
                  </div>
                  <div class="d-flex align-center tooltips">
                    <!--<v-tooltip top color="#75DC98" v-if="item.manuallyEntered">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1" style="width: 28px">
                          <Img width="28px" :src="getImgWithExt(['icons', 'manually_added_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Manually entered</span>
                    </v-tooltip>-->
                    <v-tooltip top v-if="item.vegetarian">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1" style="width: 28px">
                          <Img width="28px" :src="getImgWithExt(['icons', 'vegan_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Vegetarian</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.glutenFree" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1" style="width: 28px">
                          <Img width="28px" :src="getImgWithExt(['icons', 'gluten_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Gluten Free</span>
                    </v-tooltip>
                    <v-tooltip top color="#F7936F" v-if="item.fromProtocol">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1" style="width: 28px">
                          <Img width="28" :src="getImgWithExt(['icons', 'protocol_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>{{item.protocolName}}</span>
                    </v-tooltip>
                    <v-tooltip top color="#28605A" v-if="item.fromBlood">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1" style="width: 28px">
                          <Img width="28" :src="getImgWithExt(['icons', 'blood_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Blood</span>
                    </v-tooltip>
                    <v-tooltip top color="#499D66" v-if="item.fromBloodPattern">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="circle-dot green" v-bind="attrs" v-on="on"></div>
                      </template>
                      <span>Blood Pattern</span>
                    </v-tooltip>
                    <v-tooltip top color="#F16063" v-if="item.fromBloodMarkerLab">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="circle-dot dark-orange" v-bind="attrs" v-on="on"></div>
                      </template>
                      <span>Blood Marker</span>
                    </v-tooltip>
                    <v-tooltip top color="#00E0FF" v-if="item.fromBloodMarkerOptimal">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="circle-dot light-blue" v-bind="attrs" v-on="on"></div>
                      </template>
                      <span>Blood Marker Optimal</span>
                    </v-tooltip>
                    <!--                  <v-tooltip top color="#537CFF" v-if="item.onlyOneBottle">
                                        <template v-slot:activator="{ on, attrs }">
                                          <div class="circle-dot blue" v-bind="attrs" v-on="on"></div>
                                        </template>
                                        <span>One bottle</span>
                                      </v-tooltip>-->
                  </div>
                </div>
              </td>
              <td class="pa-0 dosageUponArisingWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageUponArising ? item.dosageUponArising : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosageUponArising'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosageBreakfastWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageBreakfast ? item.dosageBreakfast : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosageBreakfast'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosage10amWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage10am ? item.dosage10am : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosage10am'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosageLunchWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageLunch ? item.dosageLunch : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosageLunch'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosage3pmWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage3pm ? item.dosage3pm : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosage3pm'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosageDinnerWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageDinner ? item.dosageDinner : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosageDinner'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosage7pmWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage7pm ? item.dosage7pm : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosage7pm'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 dosageBeforeSleepWidth">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageBeforeSleep ? item.dosageBeforeSleep : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosages"
                    :itemRow="item"
                    :propertyKey="'dosageBeforeSleep'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="pa-0 daysPerWeekWidth" :style="item.customDayPerWeek ? 'background: #f9ffd1' : ''">
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.daysPerWeek ? item.daysPerWeek : ''}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="supplementDosagesDaysPerWeek"
                    :itemRow="item"
                    :propertyKey="'daysPerWeek'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="patientNotesWidth" :style="item.patientNotes || item.patientNotesSpanish || item.clinicianNotes ? 'background: #f4f49b' : ''">
                <div class="d-flex align-center justify-center">
                  <!--<v-tooltip right v-if="item.patientNotes || item.patientNotesSpanish || item.clinicianNotes">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="#008C89"
                        v-on="on"
                        @click.native="editPatientNotes(item)"
                        class="mx-2">
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <div>
                      <div v-show="item.patientNotes">English notes: {{item.patientNotes}}</div>
                      <div v-show="item.patientNotesSpanish">Spanish notes: {{item.patientNotesSpanish}}</div>
                      <div v-show="item.clinicianNotes">clinician notes: {{item.clinicianNotes}}</div>
                    </div>
                  </v-tooltip>
                  <v-icon
                    v-else
                    color="#A0AEC0"
                    @click.native="editPatientNotes(item)"
                    class="mx-2">
                    mdi-square-edit-outline
                  </v-icon>-->
                  <v-tooltip top :max-width="400" v-if="item.specialNotes">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click.native="warningNotes(item)"
                        color="#A0AEC0" class="mr-1" v-bind="attrs" v-on="on">
                        mdi-alert-outline
                      </v-icon>
                    </template>
                    <span>{{item.specialNotes}}</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="totalScoreWidth">
                <div class="d-flex align-center justify-center">
                  <span class="font-weight-bold">{{item.totalScore ? item.totalScore : ''}}</span>
                </div>
              </td>
              <td class="daysSupplyWidth">
                <div class="d-flex align-center justify-center">
                  <span class="font-weight-bold">{{item.daysSupply ? item.daysSupply : ''}}</span>
                </div>
              </td>
              <td class="bottlesWidth">
                <div class="d-flex align-center justify-center">
                  <span class="font-weight-bold">{{item.bottles ? item.bottles : ''}}</span>
                </div>
              </td>
              <td class="totalCostWidth">
                <div class="d-flex align-center justify-center">
                  <span class="font-weight-bold">${{item.totalCost ? item.totalCost.toFixed(2) : ''}}</span>
                </div>
              </td>
              <td>
                <div class="d-flex align-center justify-center" :class="{ 'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status) }">
                  <actions :items="actions"
                           v-if="!visit.finishedOn || !['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                           @action="runAction"
                           :item="item"
                  /><!-- v-on="actionListener"-->
                </div>
              </td>
            </tr>
            <td colspan="100%"
                class="product-supplement"
                :key="index + item.id"
                v-if="expandedRows.includes(item.id)">
              <v-card elevation="2">
                <Table hideDefaultFooter>
                  <template v-slot:body>
                    <tr :key="index + item.id" class="sub-row">
                      <th style="width: 60%; text-align: left; padding-left: 20px">
                        <span class="sub-row__title">Supplement Bottles and Prices</span>
                      </th>
                      <th style="width: 90px">
                        <span class="sub-row__title">Product</span>
                      </th>
                      <th style="width: 37px">
                        <span class="sub-row__title">Size</span>
                      </th>
                      <th style="width: 100px">
                        <span class="sub-row__title">Unit Price</span>
                      </th>
                      <th style="width: 49px">
                        <span class="sub-row__title">Q-TY</span>
                      </th>
                      <th style="padding: 0 5px;">
                        <span class="sub-row__title">Total Price</span>
                      </th>
                      <th></th>
                    </tr>
                    <tr :key="index" class="sub-row">
                      <td style="display: flex;flex-direction: column;border-bottom: unset;padding-top: 10px;">
                        <span class="font-weight-bold">{{item.supplementName}}</span>
                        <div class="product-bottles">
                          <div class="main-row">
                            <div class="bottle"
                                 v-for="bottle in item.supplementProducts.$values"
                                 :class="{
                                 'discontinued': bottle.discontinued,
                                 'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)
                               }"
                                 :key="bottle.id"
                                 :id="`bottle-${bottle.id}`"
                                 @click="createNewSupplementProduct(item.id, bottle)"
                            >
                              <div style="width: 75%">
                                <div class="d-flex" style="justify-content: space-evenly">
                                  <div class="btn-bottle">
                                    <v-badge
                                      :color="bottle.discontinued ? '#A0AEC0' : '#008C89'"
                                      :content="getQuantityBySupplementProductId(item.resultProducts.$values, bottle.id)"
                                      overlap
                                    >
                                      <Img style="width: 30px" :class="{ 'discontinued': bottle.discontinued }" :src="getImgWithExt(['icons', 'bottle', 'svg'])" />
                                      <!--<v-icon :class="{ 'discontinued': bottle.discontinued }">mdi-bottle-tonic-outline</v-icon>-->
                                    </v-badge>
                                  </div>
                                  <div class="vitamins"></div>
                                  <div class="bottle-info" :class="{ 'discontinued': bottle.discontinued }">
                                    <p>{{bottle.productNumber}}</p>
                                    <p style="font-size: 17px">{{bottle.dosesPerBottle}}</p>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="bottle-cost" :class="{ 'discontinued': bottle.discontinued }">
                                    <span>${{bottle.listPrice}} SLP</span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="bottle-plus" :class="{ 'discontinued': bottle.discontinued }">
                                  <v-icon>mdi-plus</v-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td colspan="6">
                        <Table hideDefaultFooter>
                          <template v-slot:body>
                            <tr class="inner-product-bottles-row"
                                v-for="product in item.resultProducts.$values"
                                :key="product.id"
                            >
                              <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'" style="width: 89px">
                                <span class="font-weight-bold">{{product.number}}</span>
                                <!--<v-icon style="margin-top: -5px">mdi-chevron-down</v-icon>-->
                              </td>
                              <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'" style="width: 37px">
                                <span class="font-weight-bold">{{product.doses}}</span>
                              </td>
                              <td style="padding: 0 5px;width: 100px" :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'">
                                <span class="font-weight-bold">${{product.price.toFixed(2)}}</span>
                              </td>
                              <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'"
                                  style="width: 49px" :class="{ 'adding-in-process': isStartCreateNewSupProduct === product.supplementProductId }">
                                <div class="d-flex align-center justify-center">
                                  <span class="font-weight-bold">{{product.quantity}}</span>
                                  <div class="d-flex flex-column">
                                    <v-icon :class="{ 'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status) }" @click="updateQuantityBottles(1, product, item.id)">mdi-chevron-up</v-icon>
                                    <v-icon :class="{ 'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status) }" v-show="product.quantity > 1" @click="updateQuantityBottles(-1, product, item.id)">mdi-chevron-down</v-icon>
                                  </div>
                                </div>
                              </td>
                              <td style="padding: 0 5px;" :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'">
                                <div class="remove-supplement-product">
                                  <span class="font-weight-bold">${{product.amount.toFixed(2)}}</span>
                                  <v-icon v-if="item.resultProducts.$values.length > 1" @click="deleteSupplementProduct(item.id, product.id)">mdi-close</v-icon>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </Table>
                      </td>
                    </tr>
                    <!--<tr :key="item.id.substr(0, item.id.length - 5)" class="sub-row" v-if="newSupplementProductRowID === item.id">
                      <td>
                        <span class="font-weight-bold"></span>
                      </td>
                      <td>
                        <Select outlined
                                itemText="value"
                                itemValue="id"
                                :items="productsSupplement"
                                :value="productsSupplement[0]"
                                @change="product.id=$event.id"
                        />
                      </td>
                      <td>
                        <Input outlined
                               tabindex="1"
                               background-color="#EDF2F7"
                               placeholder="Size"
                               @input="size=$event"
                        />
                      </td>
                      <td>
                        <Input outlined
                               tabindex="1"
                               background-color="#EDF2F7"
                               placeholder="Unit price"
                               @input="unitPrice=$event"
                        />
                      </td>
                      <td>
                        <Input outlined
                               tabindex="1"
                               background-color="#EDF2F7"
                               placeholder="Quantity"
                               @input="quantity=$event"
                        />
                      </td>
                      <td>
                        <Input outlined
                               tabindex="1"
                               background-color="#EDF2F7"
                               placeholder="Amount"
                               @input="amount=$event"
                        />
                      </td>
                      <td>
                        <v-icon style="color: #4faa6e">mdi-content-save-outline</v-icon>
                      </td>
                    </tr>-->
                    <!--Variant 2-->
                    <!--            <tr :key="index" class="custom-result-table__dynamic">
                                  <td colspan="10">
                                    <span>{{item.supplementName}}</span>
                                  </td>
                                  <td colspan="5" style="padding: 0">
                                    <table>
                                      <tbody>
                                        <tr class="custom-result-table__dynamic">
                                          <th>Number</th>
                                          <th>Size</th>
                                          <th>Cost</th>
                                          <th>Quantity</th>
                                          <th>Total</th>
                                        </tr>
                                        <tr class="custom-result-table__dynamic">
                                          <td>14120</td>
                                          <td>150</td>
                                          <td>10.00$</td>
                                          <td>2</td>
                                          <td>20.00</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td></td>
                                </tr>-->
                  </template>
                </Table>
              </v-card>
            </td>
          </template>
        </template>
      </template>

    </Table>
<!--    <div class="pb-4">
      <Button @click.native="TOGGLE_DIALOG({ name: 'addResult', isOpen: true, title: 'Add result' })" color="primary">Add result</Button>
    </div>-->
    <Table
      class="table-total-cost"
      :headers="headers"
      hideDefaultFooter

      >

      <template v-slot:body>
        <tr class="custom-result-table__no-dynamic">
          <!-- :style="{'width': computedTotalTableSupWidth - 1 + 'px'}" -->
          <!-- <td ref="total-cell" :style="{'width': computedtotalTableSupWidth - 1 + 'px !important',  'table-layout': 'fixed', 'max-width': '400px' }">
            <span class="font-weight-black" style="color: #8492A6;font-size: 14px;font-weight: bold">
              Total
            </span>
          </td> -->
          <template v-for="(item, i) in headers.filter(el => !['actions'].includes(el.value))">
            <td :key="i" :my-attr="item.value" class="total-row" :style="{'width': runFunc('computed' + item.value + 'Width') + 'px'}"><!--v-show="item.type === 'Number'"-->
              <span class="font-weight-bold total" v-if="item.value === 'supplementName'">Total:</span>

              <span class="font-weight-bold" v-if="item.value === 'daysSupply'">
              {{results.record[item.value] !== 0 ? results.record[item.value] : ''}}
                <!--              {{results.record['total'+_.upperFirst(item.value)]}}-->
            </span>
              <span class="font-weight-bold" v-if="item.value === 'totalCost'">
              {{results.record['totalPrice'] !== 0 ? '$' + results.record['totalPrice'].toFixed(2) : ''}}
            </span>
              <span class="font-weight-bold" v-if="item.value === 'daysPerWeek'"></span>
              <span class="font-weight-bold" v-if="item.value === 'patientNotes'"></span>
              <span class="font-weight-bold" v-if="item.value === 'totalScore'"></span>
              <span class="font-weight-bold" v-else>
              {{results.record['total'+_.upperFirst(item.value)] !== 0 ? results.record['total'+_.upperFirst(item.value)] : ''}}
                <!--{{'total'+_.upperFirst(item.value)}}-->
            </span>
            </td>
          </template>
        </tr>
      </template>

    </Table>
    <add-result :orderMethod="orderMethod" v-if="dialogs.editResult.isOpen || dialogs.addResult.isOpen" />
    <confirm v-if="dialogs.confirm.isOpen" @close="updateAnything" />
    <edit-protocol-dialog v-if="dialogs.addProtocolDialog.isOpen || dialogs.editProtocolDialog.isOpen"/>
    <notes-dialog v-if="dialogs.notesDialog.isOpen" @showPopup="showPopup" />
    <modal-crg-notes v-if="dialogs.modalCrgNotes.isOpen"></modal-crg-notes>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
    <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
// import Button from '@/components/elements/Button'
import Table from '@/components/elements/Table'
import checkbox from '@/components/elements/checkbox'

import addResult from '@/components/layouts/dialogs/add-result'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
// import SpinInput from '@/components/elements/spin-input'
import actions from '@/components/layouts/actions'
import DosageControl from '@/components/elements/dosage-control'
// import Select from '@/components/elements/Select'
// import Input from '@/components/elements/Input'
import Number from '@/mixins/Number'
import NotesDialog from '@/components/layouts/dialogs/notes-dialog'
import EditProtocolDialog from '@/components/layouts/dialogs/edit-protocol-dialog'
import Image from '@/mixins/Image'
import ModalCrgNotes from '@/components/layouts/dialogs/modal-crg-notes'

/* const IS_SLOTABLE = [
  // 'selected',
  'actions',
  'bottles',
  'clinicianNotes',
  'daysPerWeek',
  'daysSupply',
  'dosage3pm',
  'dosage7pm',
  'dosage10am',
  'dosageBeforeSleep',
  'dosageBreakfast',
  'dosageDinner',
  'dosageLunch',
  'dosageUponArising',
  'fromBlood',
  'fromBloodMarkerLab',
  'fromBloodMarkerOptimal',
  'fromBloodPattern',
  'fromProtocol',
  'manuallyEntered',
  'onlyOneBottle',
  'patientNotes',
  'patientNotesSpanish',
  'selected',
  'supplementId',
  'supplementName',
  'surveyGroupId',
  'totalCost',
  'totalScore',
  'warnings'
] */
const IS_CUSTOM_WIDTH = [
  'dosageUponArising',
  'dosageBreakfast',
  'dosage10am',
  'dosageLunch',
  'dosage3pm',
  'dosageDinner',
  'dosage7pm',
  'dosageBeforeSleep',
  'daysPerWeek',
  'patientNotes',
  'totalScore',
  'daysSupply',
  'bottles',
  'totalCost',
  'actions'
]

export default {
  name: 'results',
  props: {
    orderMethod: {
      type: Object
    },
    calcMethod: {
      type: Object
    },
    isUpdateResults: {
      type: Boolean,
      default: false
    },
    isUpdateDaysSupply: {
      type: Boolean,
      default: false
    },
    isUpdateVisit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [Number, Image],
  components: { ModalCrgNotes, EditProtocolDialog, NotesDialog, Table, checkbox, addResult, confirm, actions, DosageControl }, // , Select, Input, SpinInput, Button,
  data: () => ({
    customTableWidth: null,
    supplementDosages: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 0,
        text: 'x'
      }
    ],
    supplementDosagesDaysPerWeek: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 6,
        text: '6'
      },
      {
        id: 7,
        active: false,
        value: 7,
        text: '7'
      }
    ],
    expandedRows: [],
    expandedRowsGroup: [],
    newSupplementProductRowID: null,
    product: null,
    productsSupplement: [
      {
        id: 1,
        value: 'Product 1'
      },
      {
        id: 2,
        value: 'Product 2'
      },
      {
        id: 3,
        value: 'Product 3'
      }
    ],
    totalTableSupWidth: 0,
    dosageUponArisingWidth: 0,
    dosageBreakfastWidth: 0,
    dosage10amWidth: 0,
    dosageLunchWidth: 0,
    dosage3pmWidth: 0,
    dosageDinnerWidth: 0,
    dosage7pmWidth: 0,
    dosageBeforeSleepWidth: 0,
    daysPerWeekWidth: 0,
    patientNotesWidth: 0,
    totalScoreWidth: 0,
    daysSupplyWidth: 0,
    bottlesWidth: 0,
    totalCostWidth: 0,
    isStartCreateNewSupProduct: false,
    alertIsOpen: false,
    popupMessage: null,
    loading: true
  }),
  methods: {
    runFunc (name) {
      return this[name]
    },
    ...mapActions('results', ['getResults', 'updateResult', 'getNotes', 'createSupplementProduct', 'removeSupplementProduct', 'updateSupplementProduct']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('results', ['UPDATE_DATA', 'UPDATE_STORE_DATA']),
    handleResize () {
      this.customTableWidth = document.querySelector('#custom-table-for-width').clientWidth
    },
    editResult (item) {
      if (item.params) {
        this.TOGGLE_DIALOG({ id: item.data.id, data: item.data, name: 'editResult', isOpen: true, title: `Edit supplement: ${item.data.supplementName}` })
      } else {
        this.TOGGLE_DIALOG({ id: item.id, data: item, name: 'editResult', isOpen: true, title: `Edit supplement: ${item.supplementName}` })
      }
    },

    async deleteResult (item) {
      await this.TOGGLE_DIALOG({
        id: item.data.id,
        name: 'confirm',
        isOpen: true,
        action: 'results/deleteResult',
        // afterAction: {
        //   id: this.$route.params.id,
        //   name: 'results/getResults',
        //   orderMethod: this.orderMethod.code,
        //   calcMethod: this.calcMethod.code
        // },
        callback: (async () =>  {
          const result = await this.getResults({ id: this.$route.params.id, orderMethod: this.orderMethod.code })

          await this.$store.dispatch('visits/updateTotalSelectedResultSupplements', { totalSelectedResultSupplements: result.data.record.totalSelectedResultSupplements })
          this.getNotes(this.$route.params.id)
        }),
        description: 'Are you sure you want to delete the supplement?',
        title: 'Confirm deleting the Supplement'
      })



    },
    warningNotes (item) {
      this.TOGGLE_DIALOG({ name: 'notesDialog', title: 'Special Notes', isOpen: true, data: item }) // action: 'ResultSupplements/ClinicianNotes'
    },
    updateAnything() {
      this.getNotes(this.$route.params.id)
    },
    showPopup ({ res, supplementName }) {
      this.alertIsOpen = true
      this.popupMessage = `Notes for ${supplementName} was updated successfully`
      this.TOGGLE_DIALOG({ name: 'notesDialog', isOpen: false })
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 1300)
    },
    async updateSupplementUse (item, val) {
      const result = await this.updateResult({ id: item.id, form: { ...item, selected: val } })
      await this.$store.dispatch('visits/updateTotalSelectedResultSupplements', { totalSelectedResultSupplements: result.record.totalSelectedResultSupplements })
      // this.updateDataStore(result, 'selected')
      this.getNotes(this.$route.params.id)
    },
    async updateSupplementDosage ({ data, keyProp }) {
      const result = await this.updateResult(data)
      // this.updateDataStore(result, keyProp)
    },
    updateDataStore (result, key) {
      const payload = {
        dailyPrice: result.record.dailyPrice,
        daysSupply: result.record.daysSupply,
        monthlyPrice: result.record.monthlyPrice,
        totalBottles: result.record.totalBottles,
        totalDosage: result.record.totalDosage,
        totalDosage10am: result.record.totalDosage10am,
        totalDosage3pm: result.record.totalDosage3pm,
        totalDosage7pm: result.record.totalDosage7pm,
        totalDosageBeforeSleep: result.record.totalDosageBeforeSleep,
        totalDosageBreakfast: result.record.totalDosageBreakfast,
        totalDosageDinner: result.record.totalDosageDinner,
        totalDosageLunch: result.record.totalDosageLunch,
        totalDosageUponArising: result.record.totalDosageUponArising,
        totalPrice: result.record.totalPrice,
        weeklyPrice: result.record.weeklyPrice
      }
      this.UPDATE_DATA(payload)

      this.items.map(item => {
        if (item.id === result.record.resultSupplements.$values[0].id) {
          return Object.assign(item, { [key]: result.record.resultSupplements.$values[0][key] })
        }
      })
    },
    /* actionListener: function () {
      console.log('actionListener')
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          action: function (event) {
            vm.$emit('click:action', { action: event.params, id: event.id })
          }
        }
      )
    }, */
    runAction (val) {
      val.params.action(val)
      // this.editResult(val.id)
    },
    toggleRowGroup(key) {
      const index = this.expandedRowsGroup.indexOf(key)

      if (index !== undefined) {
        if (index > -1) {
          this.expandedRowsGroup.splice(index, 1)
          this.expandedRows = []
        } else {
          this.expandedRowsGroup.push(key)
        }
      }
    },
    toggleRow (id) {
      const index = this.expandedRows.indexOf(id)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = []
        this.expandedRows.push(id)
      }
    },
    dynamicSupplementNameMaxWidth (array) {
      let countPixel = 0
      for (let i = 0; i < array.length; i++) {
        countPixel += array[i] ? 13 : 0
      }
      return countPixel
    },
    addNewSupplementProduct (id) {
      this.newSupplementProductRowID = id
    },
    async createNewSupplementProduct (resultSupplementId, data) {
      this.isStartCreateNewSupProduct = data.id
      const bottle = document.getElementById(`bottle-${data.id}`)
      const vitamin = bottle.querySelector('.vitamins')
      if (!vitamin.classList.contains('active-animation')) {
        const obj = {
          resultSupplementId: resultSupplementId,
          supplementProductId: data.id,
          numberOfBottles: 1, // data.quantity,
          price: data.listPrice
        }
        const res = await this.createSupplementProduct(obj)
        this.UPDATE_STORE_DATA(res)

        await this.getResults({ id: this.$route.params.id, orderMethod: this.orderMethod.code })
        this.getNotes(this.$route.params.id)

        vitamin.classList.add('active-animation')
        const self = this
        setTimeout(function () {
          vitamin.classList.remove('active-animation')
          self.isStartCreateNewSupProduct = false
        }, 1000)
      }
    },
    async deleteSupplementProduct (resultSupplementId, supplementProductId) {
      const res = await this.removeSupplementProduct({ resultSupplementId, supplementProductId })

        await this.getResults({ id: this.$route.params.id, orderMethod: this.orderMethod.code })
        this.getNotes(this.$route.params.id)

      this.UPDATE_STORE_DATA(res)
    },
    async updateQuantityBottles (val, product, resultSupplementId) {
      const res = product.quantity + val
      if (res >= 1) {
        const response = await this.updateSupplementProduct({ resultSupplementId, supplementProductId: product.id, numberOfBottles: res })


        await this.getResults({ id: this.$route.params.id, orderMethod: this.orderMethod.code })
        this.getNotes(this.$route.params.id)

        this.UPDATE_STORE_DATA(response)
      }
    },
    getQuantityBySupplementProductId (resultProducts, productId) {
      const product = resultProducts.find(item => item.supplementProductId === productId)
      return product && product.quantity ? product.quantity : '0'
    },
    getSelectedItems(array) {
      let count = 0
      array.map(item => {
        if (item.selected) {
          count++
        }
      })
      return count
    }
  },
  async mounted () {
    this.loading = true
    const { id } = this.$route.params
    // const orderMethod = this.orderMethod.code
    const res = await this.getResults({ id, orderMethod: null, calcMethod: null })
    if (res) {
      const obj = this.patientOrderMethods.find(item => item.code === this.results.record.orderMethodCode)
      this.$emit('updateOrderMethod', obj)
      if (res.data.record.surveyScore >= 0) {
        this.$emit('updateSurveySummary', res.data.record.surveyScore)
      }
      this.loading = false
      this.getNotes(id)
      this.expandedRowsGroup = this.items.map(itemGroup => itemGroup.name)
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },


  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  updated: function () {
    this.$nextTick(function () {
      const totalTableSupWidth = this?.$refs?.['totalTableSupWidth']?.[0]?.clientWidth

      if(totalTableSupWidth) {
        // this?.$refs?.['total-cell']?.clientWidth = totalTableSupWidth

        this?.$refs?.['total-cell']

        this.totalTableSupWidth = totalTableSupWidth;
      }
    })
  },



  computed: {
    ...mapState('results', {
      headers (state) {
        const headers = Object.assign([], state?.results?.headers?.$values)
        headers.push({ icon: 'mdi-cog', value: 'actions', isIcon: true, sortable: false })
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id', 'width']),
          text: el.value !== 'actions' ? el.text.toUpperCase().replace(/ /gi, '<br/>') : el.text,
          isReplacingSpace: el.value !== 'actions' ? 1 : 0,
          // isSlotable: IS_SLOTABLE.includes(el.value),
          width: (IS_CUSTOM_WIDTH.includes(el.value)) ? 40 + 'px' : 'auto',
        })).filter(el => {
          return el.text !== 'USE'
        })
      },

      items (state) {
        /* const updatedValues = state?.results?.record?.resultSupplements?.$values.map(item => {
          const newItem = Object.assign(item, { supplementWidth: '200px' }) // temporary code
          return newItem
        })
        const items = Object.assign([], updatedValues)
        return items */

        const updatedValues = state?.results?.record?.resultSupplementGroups?.$values.map(group => {
          return {
            ...group,
            resultSupplements: {
              $id: group.$id,
              $values: group.resultSupplements.$values.map(item => {
                return Object.assign(item, { supplementWidth: '200px' }) // temporary code
              })
            }
          }
        })


        const items = Object.assign([], updatedValues)


        return items
      }
    }),

    ...mapState({
      patientOrderMethods: state => state.settings.patients.patientOrderMethods.$values.map(item => {
        return {
          ...item,
          text: item.name,
          value: item.code,
          id: item.$id
        }
      }),
      visit: state => state.visits?.visit
    }),
    actions () {
      return [
        { icon: 'mdi-pencil', color: 'grey', name: 'Edit', action: this.editResult },
        { icon: 'mdi-trash-can-outline', color: 'grey', name: 'Delete', action: this.deleteResult }
      ]
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    results () {
      return this.$store.state?.results?.results
    },
    drawerState () {
      return this.$store.state.drawerState
    },

    computeddaysSupplyWidth () {
      return this.daysSupplyWidth
    },
    computedtotalCostWidth () {
      return this.totalCostWidth
    },
    computedtotalTableSupWidth () {
      return this.totalTableSupWidth
    },
    computeddosageBreakfastWidth () {
      return this.dosageBreakfastWidth
    },
    computeddosageUponArisingWidth () {
      return this.dosageUponArisingWidth
    },
    computeddosage10amWidth () {
      return this.dosage10amWidth
    },
    computeddosageLunchWidth () {
      return this.dosageLunchWidth
    },
    computeddosage3pmWidth () {
      return this.dosage3pmWidth
    },
    computeddosageDinnerWidth () {
      return this.dosageDinnerWidth
    },
    computeddosage7pmWidth () {
      return this.dosage7pmWidth
    },
    computeddosageBeforeSleepWidth () {
      return this.dosageBeforeSleepWidth
    },
    computeddaysPerWeekWidth () {
      return this.daysPerWeekWidth
    },
    computedpatientNotesWidth () {
      return this.patientNotesWidth
    },
    computedtotalScoreWidth () {
      return this.totalScoreWidth
    },
    computedbottlesWidth () {
      return this.bottlesWidth
    }
  },
  watch: {
    items (val) {
      if (val && val.length > 0) {
        this.$nextTick(() => {
          this.totalTableSupWidth = document.querySelector('.totalTableSupWidth').getBoundingClientRect().width
          this.dosageUponArisingWidth = document.querySelector('.dosageUponArisingWidth').getBoundingClientRect().width
          this.dosageBreakfastWidth = document.querySelector('.dosageBreakfastWidth').getBoundingClientRect().width
          this.dosage10amWidth = document.querySelector('.dosage10amWidth').getBoundingClientRect().width
          this.dosageLunchWidth = document.querySelector('.dosageLunchWidth').getBoundingClientRect().width
          this.dosage3pmWidth = document.querySelector('.dosage3pmWidth').getBoundingClientRect().width
          this.dosageDinnerWidth = document.querySelector('.dosageDinnerWidth').getBoundingClientRect().width
          this.dosage7pmWidth = document.querySelector('.dosage7pmWidth').getBoundingClientRect().width
          this.dosageBeforeSleepWidth = document.querySelector('.dosageBeforeSleepWidth').getBoundingClientRect().width
          this.daysPerWeekWidth = document.querySelector('.daysPerWeekWidth').getBoundingClientRect().width
          this.patientNotesWidth = document.querySelector('.patientNotesWidth').getBoundingClientRect().width
          this.totalScoreWidth = document.querySelector('.totalScoreWidth').getBoundingClientRect().width
          this.daysSupplyWidth = document.querySelector('.daysSupplyWidth').getBoundingClientRect().width
          this.bottlesWidth = document.querySelector('.bottlesWidth').getBoundingClientRect().width
          this.totalCostWidth = document.querySelector('.totalCostWidth').getBoundingClientRect().width
        })

        this.expandedRowsGroup = this.items.map(itemGroup => itemGroup.name)
      }
    },
    // async 'orderMethod.code' (val) {
    //   const { id } = this.$route.params
    //   const res = await this.getResults({ id, orderMethod: val, calcMethod: this.calcMethod.code })
    //   if (res) {
    //     this.getNotes(id)
    //   }
    // },
    // async 'calcMethod.code' (val) {
    //   const { id } = this.$route.params
    //   const res = await this.getResults({ id, orderMethod: this.orderMethod.code, calcMethod: val })
    //   if (res) {
    //     this.getNotes(id)
    //   }
    // },
    async 'isUpdateResults' (val) {
      if (val) {
        const { id } = this.$route.params
        // const orderMethod = this.orderMethod.code
        const res = await this.getResults({ id, orderMethod: null })
        if (res) {
          const obj = this.patientOrderMethods.find(item => item.code === this.results.record.orderMethodCode)
          this.getNotes(this.$route.params.id)
          this.$emit('updateOrderMethod', obj)
        }
        this.$emit('updateIsUpdateResults', false)
      }
    },
    async 'isUpdateDaysSupply' (val) {
      if (val) {
        const { id } = this.$route.params
        const res = await this.getResults({ id, orderMethod: this.orderMethod.code, calcMethod: this.calcMethod.code })
        if (res) {
          this.getNotes(id)
          this.$emit('updateIsUpdateDaysSupply', false)
        }
      }
    },
    async 'isUpdateVisit' (val) {
      if (val) {
        const { id } = this.$route.params
        const res = await this.getResults({ id, orderMethod: this.orderMethod.code, calcMethod: this.calcMethod.code })
        if (res) {
          this.getNotes(id)
          this.$emit('updateIsUpdateVisit', false)
        }
      }
    },
    drawerState (val) {
      setTimeout(() => {
        this.totalTableSupWidth = document.querySelector('.totalTableSupWidth').getBoundingClientRect().width
        this.dosageUponArisingWidth = document.querySelector('.dosageUponArisingWidth').getBoundingClientRect().width
        this.dosageBreakfastWidth = document.querySelector('.dosageBreakfastWidth').getBoundingClientRect().width
        this.dosage10amWidth = document.querySelector('.dosage10amWidth').getBoundingClientRect().width
        this.dosageLunchWidth = document.querySelector('.dosageLunchWidth').getBoundingClientRect().width
        this.dosage3pmWidth = document.querySelector('.dosage3pmWidth').getBoundingClientRect().width
        this.dosageDinnerWidth = document.querySelector('.dosageDinnerWidth').getBoundingClientRect().width
        this.dosage7pmWidth = document.querySelector('.dosage7pmWidth').getBoundingClientRect().width
        this.dosageBeforeSleepWidth = document.querySelector('.dosageBeforeSleepWidth').getBoundingClientRect().width
        this.daysPerWeekWidth = document.querySelector('.daysPerWeekWidth').getBoundingClientRect().width
        this.patientNotesWidth = document.querySelector('.patientNotesWidth').getBoundingClientRect().width
        this.totalScoreWidth = document.querySelector('.totalScoreWidth').getBoundingClientRect().width
        this.daysSupplyWidth = document.querySelector('.daysSupplyWidth').getBoundingClientRect().width
        this.bottlesWidth = document.querySelector('.bottlesWidth').getBoundingClientRect().width
        this.totalCostWidth = document.querySelector('.totalCostWidth').getBoundingClientRect().width
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>

      .total {
        width: 100%;
        display: flex;
        padding-left: 32px;
      }


  .result {
    min-height: 100px;

    .disabled {
      pointer-events: none;
    }

    .table {
      padding: 16px 2px 0px!important; // 16px!important;
      overflow-y: scroll;
      max-height: 55vh;
      ::v-deep .v-menu {
        .v-menu__content {
          z-index: 100!important;
        }
      }
    }
    .table.total-table {
      background: #ededed; // #FAFAFB
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding-left: 16px!important;
      padding-right: 16px!important;
      padding-top: 0!important;
    }

    .table.table-total-cost {
      border-top: 2px solid #0000001f;
      padding-top: 12px !important;

      font-size: 16px !important;

      ::v-deep .v-data-table {
        .v-data-table-header tr th:first-child {
          max-width: 396px!important;
          min-width: 396px!important;
        }
        .v-data-table-header {
          visibility: collapse!important;
        }
      }
    }

    .v-icon {
      cursor: pointer;
    }
    .table {
      ::v-deep .v-data-table .v-data-table__wrapper .v-data-table-header th {
        padding: 0 4px!important;
      }
      ::v-deep .v-data-table {
        background: transparent!important;
      }
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
    .is-manual-entered {
      background-color: #E4FFD5;
    }
    .is-default {
      background-color: #E3F7EA;
    }
    .tooltips {
      div {
        display: flex;
      }
      div:hover {
        background: unset!important;
      }
    }
    .loading {
      height: 100%;
      min-height: 100px;
      position: absolute;
      width: 100%;
      top: 0;
      background: rgb(213 213 213 / 50%);
      border-radius: 16px;
    }
    #custom-table-for-width {
      .group-values {
        font-weight: 600;
        color: #A6B7D4;
      }
      .row-group {
        background-color: #F7FAFC;
        height: 40px;
        cursor: pointer;
        td {
          border-left: thin solid #F7FAFC!important;
        }
      }
      .row-group:hover {
        background-color: #e4eef4;
      }
    }
  }
  .circle-dot {
    height: 8px;
    width: 8px;
    background-color: grey;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
  }
  .circle-dot.light-green {
    background-color: #75DC98!important;
  }
  .circle-dot.light-orange {
    background-color: #F7936F!important;
  }
  .circle-dot:first-child {
    margin-left: 10px;
  }
  .circle-dot:last-child {
    margin: 0 0 0 2px;
  }
  .circle-dot.dark-green {
    background-color: #28605A!important;
  }
  .circle-dot.green {
    background-color: #499D66!important;
  }
  .circle-dot.dark-orange {
    background-color: #F16063!important;
  }
  .circle-dot.light-blue {
    background-color: #00E0FF!important;
  }
  .circle-dot.blue {
    background-color: #537CFF!important;
  }
  .total-row {
    text-align: center;
  }
  .supplement-width {
    //max-width: 425px;
    text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
    cursor: pointer;
    word-wrap: break-word;
    //width: 300px;
    max-width: 300px;
    width: max-content;
  }
  ::v-deep .v-tooltip__content {
    padding: 3px 3px;
  }
  .dinner-tooltip {
    padding: 3px!important;
  }
  ::v-deep table .v-data-table-header th + th { border-left:thin solid #0000001f !important; }
  ::v-deep table td + td:not(.total-row) { border-left:thin solid #0000001f !important; }
  .days-per-week {
    ::v-deep .v-text-field__details {
      display: none !important;
    }
  }
  .custom-result-table {
    table-layout : fixed;

    &__dynamic {
      border-bottom:thin solid #0000001f;


      td {
        padding: 0 4px; // 0 16px;
        border-bottom:thin solid #0000001f;
        height: 52px;

        .width100 {
          width: 100% !important;
        }
        .height100 {
          height: 100% !important;
        }
        div[aria-expanded=true] {
          background-color: #c3e5c399;
        }
        div[aria-expanded=false] {
          background-color: unset;
        }
      }
      .totalTableSupWidth {
        .disabled {
          pointer-events: none;
        }
      }
    }
    &__dynamic:last-child {
      td {
        border-bottom: unset;
      }
    }
    &__dynamic.expand-row {
      background-color: #e0fff4;
      .table {
        padding: 0px!important;
      }
    }
    &__dynamic.expand-row-group {
      height: 40px;
      background-color: #F7FAFC;
      cursor: pointer;
      .table {
        padding: 0px!important;
      }
    }
    &__dynamic.expand-row-group:hover {
      background-color: #e4eef4;
    }
    &__no-dynamic {
      border-bottom:thin solid #0000001f;
      td {
        //border-bottom:thin solid #0000001f;
        height: 52px;
        border-right: thin solid transparent;
      }
      td:last-child {
        border-right: unset;
      }
      //td:last-child {
      //  border-bottom: unset;
      //}
    }
  }
  .product-supplement {
    padding: 10px 10px;
    background: #EDF2F7;
    border-bottom: unset;
    ::v-deep .v-card {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
      .table {
        padding: 0px!important;
        .v-data-table .v-data-table__wrapper {
          table {
            .sub-row {
              .table {
                padding: 0!important;
              }
              th {
                padding-top: 15px;
                padding-bottom: 15px;
              }
              tr:last-child {
                td {
                  border-bottom: unset;
                }
              }
              td {
                //border-bottom: unset;
              }
            }
          }
        }
      }
    }
    .product-bottles {
      .main-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        column-gap: 1rem;
        row-gap: 1rem;
        padding: 5px 10px;
        align-items: center;
        .bottle {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 139px;
          align-items: center;
          column-gap: 0.4rem;
          cursor: pointer;
          background: #008c8933;
          border-radius: 10px;
          padding: 8px 3px;
          justify-content: flex-start;
          .btn-bottle {
            width: 50px;
            //height: 50px;
            //background: #56c8472e;
            //border-radius: 10px;
            .v-icon {
              margin-top: 10px;
              color: #008C89;
            }
            .v-icon.discontinued {
              margin-top: 10px;
              color: #A0AEC0;
            }
            ::v-deep .v-badge {
              margin-top: 10px;
            }
          }
          .bottle-info {
            p {
              margin-top: 0;
              margin-right: 0;
              margin-bottom: 0;
              margin-left: 8px;
            }
            font-weight: bold;
            color: #008C89;
          }
          .bottle-info.discontinued {
            color: #A0AEC0;
          }
          .bottle-plus {
            ::v-deep .v-icon {
              color: #008C89;
            }
          }
          .bottle-plus.discontinued {
            ::v-deep .v-icon {
              color: #A0AEC0;
            }
          }
          .bottle-cost {
            font-weight: bold;
            color: #008C89;
            font-size: 14px;
            margin-left: 8px;
          }
          .bottle-cost.discontinued {
            color: #A0AEC0;
          }
        }
        .bottle.discontinued {
          background: #CBD5E0;
          color: #A0AEC0;
        }
      }
    }
    .inner-product-bottles-row {}
    .remove-supplement-product {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
  .sub-row {
    &__title {
      font-size: 10px;
      font-weight: 600;
      color: #8492A6;
      white-space: nowrap;
      text-transform: uppercase;
    }
    th:first-child {
      border-left: unset;
      //border-bottom: unset;
    }
    th {
      border-bottom: 1px solid #0000001f;
      border-left: 1px solid #0000001f;
    }
    th:last-child {
      border-bottom: unset;
      border-left: unset;
    }
    tr:last-child {
      td {
        border-bottom: unset;
      }
    }
    td {
      border-bottom: 1px solid #0000001f;
      text-align: center;
    }
  }
  .adding-in-process {
    background-color: #e0fff4;
  }
  .vitamins {
    width: 30px;
    height: 30px;
    position: absolute;
    visibility: hidden;
    background-image: url("../../../assets/icons/vitamins.png");
    background-size: 25px;
    bottom: 20px;
  }
  .vitamins.active-animation {
    visibility: visible;
    animation: moveXandSpin 1s none;
  }
  @keyframes moveXandSpin {
    from {
      transform: translateX(0px) rotate(0);
    }
    to {
      transform: translateX(570px) rotate(720deg);
    }
  }
  .result {
    .table.dynamic-width {
      ::v-deep {
        .actions {
          .v-menu {
            [role="menu"] {
              left: 90%!important;
            }
          }
        }
      }
    }
  }
  .red-line {
    border-left: 3px solid #FCDFE0;
  }
  .blue-line {
    border-left: 3px solid #D0DCFF;
  }
</style>
