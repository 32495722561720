<template>
  <div class="datepicker">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <div class="input-with-icon">
          <Img :src="getImgWithExt(['icons', 'date', 'svg'])" class="input-icon" />
          <Input
            v-on="on"
            v-bind="$props"
            readonly
            outlined
            radius="8"
            :placeholder="placeholder"
            :label="label"
            :value="formatDate"
          />
        </div>
      </template>
      <v-date-picker
        no-title
        scrollable
        :show-current=false
        :max="$props.max"
        :min="$props.min"
        @change="changeDate"
        :value="datePickerValue"
      />
    </v-menu>
  </div>
</template>

<script>
import Input from '@/components/elements/Input'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'

export default {
  name: 'datepicker',
  props: {
    color: {
      default: '#212b37',
      type: String
    },
    format: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    max: {
      type: String
    },
    min: {
      type: String
    },
    placeholder: String,
    rules: {
      type: [Array, String, Object]
    },
    'type-picker': {
      type: String
    },
    showCurrent: {
      type: String
    },
    value: {
      type: [String, Date]
    },
    height: { type: [String, Number] }
  },
  mixins: [Image],
  components: { Input, Img },
  data: () => ({
    date: null,
    menu: false
  }),
  created () {
    // this.$emit('date:update', this.value)
  },
  computed: {
    datePickerValue () {
        return this.$moment(this.date || this.value || new Date()).format('YYYY-MM-DD');
    },

    formatDate () {
      return this.date ? this.$moment(this.date).format('MM/DD/YYYY') : this.value ? this.$moment(this.value).format('MM/DD/YYYY') : ''
    }
  },
  methods: {
    formatForDatePicker(date) {
        return this.$moment(date || new Date()).format('YYYY-MM-DD');
    },

    changeDate ($event) {
      this.$set(this.$data, 'date', $event)
      this.$emit('date:update', $event)
      this.$refs.menu.save()
    }
  }
}
</script>

<style lang="scss" scoped>
.datepicker {
  .input-with-icon {
    display: flex;
    align-items: center;
    background-color: #EDF2F7;
    border-radius: 8px;
    padding: 10px 16px;

    .image {
      width: 14px;
      margin-right: 8px;
    }

    .input {
      ::v-deep .v-input__control {
        .v-input__slot {
          height: auto !important;
          box-shadow: none;
          border: none !important;
          padding: 0 !important;

          fieldset {
            padding-left: 0 !important;
          }

          .v-text-field__slot {
            input {
              color: #7A828A !important;
              font-weight: 500;
              font-size: 13px;
              padding: 0;

              &::placeholder {
                color: #7A828A;
                font-weight: 500;
                font-size: 13px;
              }
            }
          }
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
  }
}
</style>
