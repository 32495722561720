<template>
	<div>
		<ConfirmDialog
			:callback="this.emailCallback"
			v-if="dialogs.confirm.isOpen"
		/>

		<Dialog
			class="evaluation_report"
			width="800px"
      :showCloseIcon="true"
			:styles="{ 'border-radius': '16px' }"
		>
			<template slot="title">
				<div>
					<span style="font-size: 28px; color: #27272E; font-weight: 600">
						Print / Download / Email to Patient
					</span>

					<div class="language-switcher">
						<Button
							block
							dark
							:class="{ 'active-language': activeLanguage === 'es' }"
							@click.native="switchLanguage('es')"
							tabindex="3"
							height="30"
						>
							Spanish
						</Button>

						<Button
							block
							dark
							:class="{ 'active-language': activeLanguage === 'en' }"
							@click.native="switchLanguage('en')"
							tabindex="3"
							height="30"
						>
							English
						</Button>
					</div>
				</div>
			</template>

			<template slot="body">
				<div class="reports">
					<v-row no-gutters style="gap: 15px; border-bottom: 1px solid #e4ecf7">
						<v-col style="width: 100%">
							<v-card class="px-2 py-1">
								<v-card-title class="report-label"
									>Clinician Reports</v-card-title
								>

								<v-card-text class="btn-group-report">
									<div class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Clinician Report"
											v-model="clinicReport"
											@click="
												selectReport(
													$event,
													'ClinicianFinalReport',
													clinicReport
												)
											"
										></v-checkbox>
									</div>
									<div ref="clinicianWarnings" class="btn-report">
										<v-checkbox
											id="clinicianWarnings"
											color="#008C89"
											label="Clinician Warnings"
											v-model="clinicianWarnings"
											@click="
												selectReport(
													$event,
													'ClinicianWarnings',
													clinicianWarnings
												)
											"
										></v-checkbox>
									</div>
									<div
										v-if="isSubscriptionIncludesBlood"
										ref="clinicianBloodPanelResults"
										class="btn-report"
									>
										<v-checkbox
											color="#008C89"
											label="Clinician Blood Panel Results"
											v-model="clinicianBloodPanelResults"
											@click="
												selectReport(
													$event,
													'BloodPanelClinician',
													clinicianBloodPanelResults
												)
											"
										>
										</v-checkbox>
									</div>
								</v-card-text>
							</v-card>
						</v-col>

						<v-col style="width: 100%">
							<v-card class="px-2 py-1">
								<v-card-title class="report-label"
									>Patient Reports</v-card-title
								>
								<v-card-text class="btn-group-report">
									<div ref="supplementSchedule" class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Supplement Schedule"
											v-model="supplementSchedule"
											@click="
												selectReport(
													$event,
													'PatientSchedule',
													supplementSchedule
												)
											"
										></v-checkbox>
									</div>
									<div class="btn-report custom">
										<v-checkbox
											color="#008C89"
											label="Group Description"
											v-model="localGroupDescription"
											@click="
												selectReport(
													$event,
													'PatientGroupsInfo',
													localGroupDescription
												)
											"
										></v-checkbox>
										<div class="wrapper-group-description-report">
											<div class="group-description-report">
												<v-select
													:disabled="!getIfAnyReportsSelected"
													:items="items"
													class="custom-select"
													:value="items[0]"
													v-model="groupDescription"
													solo
												></v-select>
											</div>
										</div>
									</div>
									<div ref="surveyScoreSheet" class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Survey Score Sheet"
											v-model="localSurveyScoreSheet"
											@click="
												selectReport(
													$event,
													'PatientSurveyScoreSheet',
													localSurveyScoreSheet
												)
											"
										></v-checkbox>
									</div>
									<div ref="supplementCostAndQuantities" class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Costs and Quantities"
											v-model="supplementCostAndQuantities"
											@click="
												selectReport(
													$event,
													'PatientSurveyCostsAndQuantities',
													supplementCostAndQuantities
												)
											"
										></v-checkbox>
									</div>
									<div ref="surveyFormSummary" class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Survey Form Summary"
											v-model="surveyFormSummary"
											@click="
												selectReport(
													$event,
													'PatientSurveyFormSummary',
													surveyFormSummary
												)
											"
										></v-checkbox>
									</div>
									<div ref="surveyComparisonChart" class="btn-report">
										<v-checkbox
											color="#008C89"
											label="Survey Comparison Chart"
											v-model="surveyComparisonChart"
											@click="
												selectReport(
													$event,
													'SurveyComparisonChart',
													surveyComparisonChart
												)
											"
										></v-checkbox>
									</div>
									<div
										v-if="isSubscriptionIncludesBlood"
										ref="bloodPanelResults"
										class="btn-report"
									>
										<v-checkbox
											color="#008C89"
											label="Blood Panel Results"
											v-model="bloodPanelResults"
											@click="
												selectReport(
													$event,
													'BloodPanelPatient',
													bloodPanelResults
												)
											"
										></v-checkbox>
									</div>
									<div
										v-if="isSubscriptionIncludesBlood"
										ref="bloodPanelResultsWithAboveBelowOptimal"
										class="btn-report"
									>
										<v-checkbox
											color="#008C89"
											label="Blood Panel Results w/ Optimals"
											v-model="bloodPanelResultsWithAboveBelowOptimal"
											@click="
												selectReport(
													$event,
													'BloodPanelPatientOptimals',
													bloodPanelResultsWithAboveBelowOptimal
												)
											"
										></v-checkbox>
									</div>
									<div
										v-if="isSubscriptionIncludesBlood"
										ref="bloodPanelCompare"
										class="btn-report"
									>
										<v-checkbox
											color="#008C89"
											label="Blood Panel Compare"
											v-model="bloodPanelCompare"
											@click="
												selectReport(
													$event,
													'BloodPanelComparison',
													bloodPanelCompare
												)
											"
										></v-checkbox>
									</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</template>

			<template slot="actions">
				<v-row
					class="d-flex"
					style="padding: 0 20px; align-items: center; width: 100%; gap: 10px"
				>
					<span class="value"> {{ getSelectedValue }} reports selected </span>

					<Button
						color="#EBF2FA"
						@click.native="downloadFile()"
						:styles="{
							padding: '12px 20px',
							'background-color': '#FFFFFF',
							border: '1px solid #DBE2EE',
							'margin-left': '10px'
						}"
					>
						<span
							style="
								color: #16192C;
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 10px;
								letter-spacing: 0;
							"
						>
							<img
								class="icon"
								:src="getImgWithExt(['icons', 'evaluation-download', 'svg'])"
								alt="icon"
							/>
							Download
						</span>
					</Button>

					<Button
						color="#EBF2FA"
						@click.native="print()"
						:styles="{
							padding: '12px 20px',
							'background-color': '#FFFFFF',
							border: '1px solid #DBE2EE'
						}"
					>
						<span
							style="
								color: #16192C;
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 10px;
								letter-spacing: 0;
							"
						>
							<img
								class="icon"
								:src="getImgWithExt(['icons', 'evaluation-print', 'svg'])"
								alt="icon"
							/>
							Print
						</span>
					</Button>

					<Button
						@click.native="sendToPatientEmail()"
						color="#EBF2FA"
						:styles="{
							padding: '12px 20px',
							'background-color': '#FFFFFF',
							border: '1px solid #DBE2EE'
						}"
					>
						<span
							style="
								color: #16192C;
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 10px;
								letter-spacing: 0;
							"
						>
							<img
								class="icon"
								:src="getImgWithExt(['icons', 'evaluation-email', 'svg'])"
								alt="icon"
							/>
							Email
						</span>
					</Button>

					<span style="
								color: #425466;
								font-size: 12px;
								font-weight: 600;
								letter-spacing: 0;
							"> to </span>

					<form>
						<Input
							outlined
							autocomplete="off"
							style="max-height: 40px; flex: 1"
							placeholder="email"
							type="email"
							@input="onChangeEmail($event)"
							:value="getPatientEmail"
						/>
					</form>
				</v-row>

				<v-row class="clinical-report-selected-warning">
					<div v-if="getIfClinicalReportSelected" class="d-flex align-center">
						<img
							class="icon"
							:src="getImgWithExt(['icons', 'warning', 'svg'])"
							alt="warning"
						/>

						It is not recommended to send Clinician reports to patients.
					</div>

					<div v-if="getIfEmailValidationError">
						<img
							class="icon"
							:src="getImgWithExt(['icons', 'warning', 'svg'])"
							alt="warning"
						/>

						Please enter valid email.
					</div>

					<div v-if="getIfIsNoSelectedReportsError">
						<img
							class="icon"
							:src="getImgWithExt(['icons', 'warning', 'svg'])"
							alt="warning"
						/>

						Select at least one report to do this.
					</div>
				</v-row>

				<iframe
					v-if="!loading"
					:src="fileUrl"
					width="100%"
					class="pdf-printer"
					id="pdfDocument-2"
					:height="590"
				>
				</iframe>

				<div class="custom-alert">
					<v-alert
						color="#7ac1fb"
						dismissible
						type="info"
						v-model="alertIsOpen"
					>
						<span style="font-size: 14px">{{ popupMessage }}</span>
					</v-alert>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Image from '@/mixins/Image';
import Input from '@/components/elements/Input';
import Dialog from '@/components/layouts/dialog';
import Button from '@/components/elements/Button';
import ConfirmDialog from '@/components/layouts/dialogs/confirm-dialog';

export default {
	name: 'EvaluationReports',
	mixins: [Image],
	components: { Dialog, Button, Input, ConfirmDialog },

	props: {
		visitId: {
			type: String
		}
	},

	data: () => ({
		initialDoc: null,
		docForZip: null,
		activeLanguage: 'en',
		items: [1, 2, 3, 4, 5],

		email: null,

		isNoSelectedReportsError: false,
		isInvalidEmail: false,

		localGroupDescription: false,
		localSurveyScoreSheet: false,

		includeCurrentPatient: false,
		includeCurrentDoctor: false,
		includeGroupDescriptions: false,

		groupDescription: +localStorage.getItem('groupDescription') || 1,
		patientSchedule: null,
		includeCosts: false,
		disabledIncludeCosts: true,
		printInColors: false,
		disabledPrintInColors: true,
		fileUrl: null,
		file: null,
		reportCode: null,
		refLocal: null,
		alertIsOpen: false,
		popupMessage: '',
		loading: false,
		reportSpanish: true,
		selectedReports: [],

		clinicReport: false,
		clinicianWarnings: false,
		clinicianBloodPanelResults: false,
		supplementSchedule: false,
		supplementCostAndQuantities: false,
		surveyFormSummary: false,
		surveyComparisonChart: false,
		bloodPanelResults: false,

		bloodPanelResultsWithAboveBelowOptimal: false,
		bloodPanelCompare: false,
		arrayOfSelectedReports: []
	}),

	mounted() {
		this.email = this.patient.email || null;
		this.reportSpanish = this.patient.spanish;
	},

	methods: {
		...mapMutations('dialogs', ['TOGGLE_DIALOG']),
		...mapActions('reports', [
			'gerReportByCode',
			'sendSurveyForm',
			'sendEmailToPatient',
			'sendEmailToPatientMultiple',
			'downloadZipReports'
		]),

		validateEmail() {
			const emailRegExp =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!this.email || !String(this.email).toLowerCase().match(emailRegExp)) {
				this.isInvalidEmail = true;
				return false;
			} else {
				this.isInvalidEmail = false;
				return true;
			}
		},

		switchLanguage(lang) {
			this.activeLanguage = lang;

			if (lang === 'es') {
				this.reportSpanish = true;
			} else {
				this.reportSpanish = false;
			}
		},

		close() {
			this.TOGGLE_DIALOG({ name: 'evaluationReportsModal', state: false });
		},

		async downloadFile() {
			if (
				this.arrayOfSelectedReports.length === 0 ||
				!this.arrayOfSelectedReports
			) {
				this.isNoSelectedReportsError = true;

				return;
			}

			const isMultipleReports = !!this.arrayOfSelectedReports.length;
			if (!isMultipleReports) {
				await this.getReport();
				const url = this.initialDoc; // window.URL.createObjectURL(blob)

				const link = document.createElement('a');
				document.getElementsByTagName('body')[0].appendChild(link);
				link.href = url;
				link.download = 'Report.pdf';
				link.click();
				document.getElementsByTagName('body')[0].removeChild(link);
			} else {
				this.downloadMultipleReports();
			}
		},

		async print() {
			if (
				this.arrayOfSelectedReports.length === 0 ||
				!this.arrayOfSelectedReports
			) {
				this.isNoSelectedReportsError = true;

				return;
			}

			this.loading = true;
			const selectedVisitId = localStorage.getItem('selectedVisitId');

			const payload = {
				entityId: this.dialog.visitId || selectedVisitId,
				reportSpanish: this.reportSpanish,
				reportCode: localStorage.getItem('reportCode')
			};

			if (this.arrayOfSelectedReports.includes('PatientGroupsInfo')) {
				payload.surveyGroups = this.groupDescription;
			}

			const res = await this.gerReportByCode(payload);

			if (res) {
				const reader = new FileReader();
				reader.onloadend = () => {
					this.fileUrl = URL.createObjectURL(res);
					this.initialDoc = this.fileUrl;

					this.loading = false;

					setTimeout(() => {
						const doc = document.getElementById('pdfDocument-2');

						if (doc) {
							doc.contentWindow.print();
						}
					}, [500]);
				};

				reader.readAsDataURL(res);
			} else {
				this.loading = false;
				this.fileUrl = null;
			}
		},

		async emailCallback() {
			const selectedVisitId = localStorage.getItem('selectedVisitId');

			const data = {
				email: this.email,
				appUserId: this.userProfile.userId,
				reportSpanish: this.reportSpanish,
				reportCode: localStorage.getItem('reportCode'),
				entityId: this.dialog.visitId || selectedVisitId
			};

			if (this.arrayOfSelectedReports.includes('PatientGroupsInfo')) {
				data.surveyGroups = this.groupDescription;
			}

			delete data.appUserId;
			delete data.reportCode;

			const payload = {
				...data,
				reports: this.arrayOfSelectedReports.map((reportCode) => {
					return {
						reportCode,
						surveyGroups: data.surveyGroups,
						reportSpanish: this.reportSpanish,
						entityId: this.dialog.visitId || selectedVisitId
					};
				})
			};

			const res = await this.sendEmailToPatientMultiple(payload);

			if (res.success) {
				this.alertIsOpen = true;
				this.popupMessage =
					'Survey Form was send successfully to Patient Email';
			}
		},

		async sendToPatientEmail() {
			const isValid = this.validateEmail();

			if (!isValid) return;

			if (
				this.arrayOfSelectedReports.length === 0 ||
				!this.arrayOfSelectedReports
			) {
				this.isNoSelectedReportsError = true;

				return;
			}

			this.isLoading = true;

			await this.TOGGLE_DIALOG({
				id: this.email,
				name: 'confirm',
				isOpen: true,
				description: 'Are you sure you want to send email?',
				callback: async () => await this.emailCallback(),
				title: 'Confirm sending email'
			});
		},

		async getReport(ref, reportCode) {
			localStorage.setItem('reportCode', reportCode);
			localStorage.setItem(
				'reportName',
				this.upperCaseFirst(this.splitByUpperCase(ref))
			);

			if (!this.$refs[ref].classList.contains('report-active')) {
				this.loading = true;

				Array.from(document.getElementsByClassName('btn-report')).forEach(
					(item) => {
						item.classList.remove('report-active');
					}
				);

				this.$refs[ref].classList.add('report-active');

				if (ref === 'patientSurveyScoreSheet') {
					this.disabledIncludeCosts = false;
				}
				if (ref === 'surveyComparisonChart') {
					this.disabledPrintInColors = false;
				}

				const selectedVisitId = localStorage.getItem('selectedVisitId');

				const data = {
					reportSpanish: this.reportSpanish,
					appUserId: this.userProfile.userId,
					entityId: this.dialog.visitId || selectedVisitId
				};

				if (
					this.arrayOfSelectedReports.includes('PatientGroupsInfo') ||
					this.reportCode === 'PatientGroupsInfo'
				) {
					data.surveyGroups = this.groupDescription;
				}

				this.reportCode = reportCode;
				this.refLocal = ref;

				const payload = {
					...data,
					reportCode,
					email: this.email
				};

				if (reportCode === 'PatientSurveyScoreSheet') {
					payload.includeCosts = this.includeCosts;
				}

				const res = await this.gerReportByCode(payload);
				this.file = res;
				this.parseFile(this.file);
				this.initialDoc =
					'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf';
				this.loading = false;
			}
		},

		async selectReport(event, reportCode, value) {
			localStorage.setItem('reportCode', reportCode);

			event.stopPropagation();

			if (value) {
				this.arrayOfSelectedReports.push(reportCode);
			} else {
				this.arrayOfSelectedReports = this.arrayOfSelectedReports.filter(
					(item) => item !== reportCode
				);
			}

			if (this.arrayOfSelectedReports.length > 0 && value) {
				// this.localGroupDescription = true;
				this.isNoSelectedReportsError = false;

				// if(reportCode === 'PatientSchedule') {
				//   this.bloodPanelCompare = true
				//   this.arrayOfSelectedReports.push('BloodPanelComparison')
				// }
			}

			// if(!this.arrayOfSelectedReports || this.arrayOfSelectedReports.length === 0 ) {
			//   this.localGroupDescription = false;
			// }
		},

		async downloadMultipleReports() {
			if (
				this.arrayOfSelectedReports.length === 0 ||
				!this.arrayOfSelectedReports
			) {
				this.isNoSelectedReportsError = true;

				return;
			}

			const selectedVisitId = localStorage.getItem('selectedVisitId');
			const data = {};

			if (this.arrayOfSelectedReports.includes('PatientGroupsInfo')) {
				data.surveyGroups = this.groupDescription;
			}

			const payload = {
				surveyGroups: data.surveyGroups,
				reportSpanish: this.reportSpanish,
				entityId: this.dialog.visitId || selectedVisitId,
				reports: this.arrayOfSelectedReports.map((reportCode) => {
					return {
						reportCode,
						surveyGroups: data.surveyGroups,
						reportSpanish: this.reportSpanish,
						entityId: this.dialog.visitId || selectedVisitId
					};
				})
			};
			await this.downloadZipReports(payload);
		},

		resetReportFields() {
			this.clinicReport = false;
			this.clinicianWarnings = false;
			this.clinicianBloodPanelResults = false;
			this.supplementSchedule = false;
			this.supplementCostAndQuantities = false;
			this.surveyFormSummary = false;
			this.surveyComparisonChart = false;
			this.bloodPanelResults = false;
			this.bloodPanelResultsWithAboveBelowOptimal = false;
			this.bloodPanelCompare = false;
		},

		parseFile(file) {
			// this.initialDoc = null
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					this.fileUrl = URL.createObjectURL(file);
					this.initialDoc = this.fileUrl;

					console.log({ here: this.initialDoc });
				};

				reader.readAsDataURL(file);
			} else {
				this.fileUrl = null;
			}
		},
		splitByUpperCase(str) {
			return str.split(/(?=[A-Z])/).join(' ');
		},
		upperCaseFirst(str) {
			return str[0].toUpperCase() + str.slice(1);
		},

		onChangeEmail(email) {
			const emailRegExp =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (String(email).toLowerCase().match(emailRegExp)) {
				this.isInvalidEmail = false;
				this.email = email;

				return email;
			}

			this.isInvalidEmail = true;
		}
	},

	computed: {
		...mapState({
			visit: (state) => state.visits?.visitId,
			userProfile: (state) => state.userProfile,
			patient: (state) => state.patients?.patient,
			subscriptionFeatures: (state) => state.subscription,
			dialog(state) {
				const modalName = this._.findKey(state.dialogs, ['isOpen', true]);
				return state.dialogs[modalName];
			}
		}),

		getPatientEmail() {
			return this.patient.email;
		},

		isSubscriptionIncludesBlood() {
			return this.subscriptionFeatures.features.$values.includes('Blood');
		},

		getSelectedValue() {
			return this.arrayOfSelectedReports.length;
		},

		getIfClinicalReportSelected() {
			return (
				this.arrayOfSelectedReports.includes('ClinicianFinalReport') ||
				this.arrayOfSelectedReports.includes('ClinicianWarnings') ||
				this.arrayOfSelectedReports.includes('BloodPanelClinician')
			);
		},

		getIfIsNoSelectedReportsError() {
			return this.isNoSelectedReportsError;
		},

		getIfEmailValidationError() {
			return this.isInvalidEmail;
		},

		getIfAnyReportsSelected() {
			return (
				this.arrayOfSelectedReports && this.arrayOfSelectedReports.length > 0
			);
		},

		dialogs() {
			return this.$store.state.dialogs;
		}
	},
	watch: {
		async groupDescription(val) {
			localStorage.setItem('groupDescription', val);

			const selectedVisitId = localStorage.getItem('selectedVisitId');

			const payload = {
				surveyGroups: val,
				reportCode: this.reportCode || 'PatientGroupsInfo',
				reportSpanish: this.reportSpanish,
				appUserId: this.userProfile.userId,
				entityId: this.dialog.visitId || selectedVisitId
			};

			return this.gerReportByCode(payload);
		},

		async includeCosts(val) {
			const payload = {
				appUserId: this.userProfile.userId,
				reportCode: this.reportCode,
				entityId: this.dialog.visitId,
				includeCosts: val,
				reportSpanish: this.reportSpanish
			};
			const res = await this.gerReportByCode(payload);
			this.file = res;
			this.parseFile(this.file);
			this.TOGGLE_DIALOG({
				name: 'openReportPdfView',
				isOpen: true,
				title: `Report ${this.upperCaseFirst(
					this.splitByUpperCase(this.refLocal)
				)}`
			});
		},

		async reportSpanish(val) {
			const selectedVisitId = localStorage.getItem('selectedVisitId');

			if (this.reportCode) {
				const payload = {
					appUserId: this.userProfile.userId,
					reportCode: this.reportCode,
					entityId: this.dialog.visitId || selectedVisitId,
					includeCosts: this.includeCosts,
					reportSpanish: val
				};

				const res = await this.gerReportByCode(payload);
				this.file = res;
				this.parseFile(this.file);
				this.TOGGLE_DIALOG({
					name: 'openReportPdfView',
					isOpen: true,
					title: `Report ${this.upperCaseFirst(
						this.splitByUpperCase(this.refLocal)
					)}`
				});
			}
		},

		alertIsOpen(val) {
			const self = this;
			if (val) {
				setTimeout(function () {
					self.alertIsOpen = false;
				}, 5000);
			}
		}
	}
};
</script>

<style scoped lang="scss">
.custom-alert {
	position: absolute !important;
	max-width: 300px;
	top: 10px;
	right: 20px;
	z-index: 100;
}

.pdf-printer {
	display: none;
}

.icon {
	height: 16px;
	color: #008c89;
	fill: #008c89;
}

.bottom-divider {
	width: 100%;
	padding: 0 20px;
	border-bottom: 1px solid #e4ecf7;
}

.language-switcher {
	display: flex;
	flex-direction: row-reverse;
  justify-content: space-between;
	max-width: 182px;
	background-color: #f7fafc;
	border-radius: 10px;
	padding: 2px;
	margin-top: 12px;

	.button {
		::v-deep .v-btn {
			background-color: #F7FAFC !important;
			color: #425466 !important;
			font-weight: 600 !important;
      letter-spacing: 0;
      padding: 0 12px;
      width: 88px;
		}
	}
	.button.active-language {
		::v-deep .v-btn {
			background-color: $dark-green !important;
			color: white !important;
			//font-weight: 600 !important;
		}
	}
}

.evaluation_report {
	::v-deep .v-dialog {
		overflow-x: hidden !important;
		overflow-y: hidden !important;
	}

  ::v-deep .v-card__title {
    .image {
      margin-bottom: auto;
      cursor: pointer;
    }
  }

	::v-deep .v-dialog {
		min-height: 500px;

		.v-card {
			padding: 0 !important;
      background-color: $white;

      .v-card__text {
        padding: 0;
      }

			.report-label {
				color: #27272E;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
				padding: 14px 0 !important;
				background: transparent !important;
				background-color: transparent !important;
				border-bottom: 1px solid #e4ecf7;
			}

			&__title {
				padding: 32px !important;
				padding-bottom: 16px !important;
				background: transparent;
				position: relative;
			}

			&__actions {
				display: flex;
				flex-direction: column;
				flex: 0;
				gap: 10px;
				padding-top: 24px;
        padding-bottom: 33px;

        .input {
          width: 190px;
          margin-left: auto;

          .v-input {
            .v-input__slot {
              font-size: 12px;
              box-shadow: none !important;
              border-color: #DBE2EE !important;
              border-radius: 8px;
              padding: 0 12px !important;

              input {
                color: #16192C !important;
              }
            }

            .v-text-field__details {
              display: none;
            }
          }
        }

        .value {
          color: #425466;
          font-size: 14px;
          font-weight: 600;
          line-height: 23px;
        }

				& > div {
					margin: 0;
					padding: 0;
					flex: 0;
				}

				.clinical-report-selected-warning {
					min-height: 30px;
					flex: 0;
					width: 100%;
					padding: 0 22px;

					display: flex;
					flex-direction: column;

					text-align: start;

					color: #d46a51;

					& > div {
						color: inherit;

						gap: 15px;
						display: flex;
						justify-content: flex-start;
						height: fit-content;
					}
				}
			}
		}
	}
}

.reports {
	.loading {
		min-height: 100px;
		position: absolute;
		height: 99%;
		width: 99%;
		top: 4px;
		left: 5px;
		background: rgba(213, 213, 213, 0.5);
		border-radius: 16px;
	}

	padding: 0 32px;

	.btn-group-report {
		display: flex;
		flex-direction: column;
		row-gap: 4px;

		.btn-report {
			height: 36px;
			display: flex;
			align-items: center;
      padding: 10px;
			cursor: pointer;

			&:hover {
				background-color: #edf2f7;
				border-radius: 8px;

				.wrapper-group-description-report {
					.group-description-report {
						::v-deep .v-input {
							.v-input__control {
								.v-input__slot {
									background: #cbd5e0 !important;
								}
							}
						}
					}
				}
			}

			::v-deep .v-input {
				margin: 0 !important;
				padding: 0 !important;
				width: 20px;
				white-space: nowrap;
				position: absolute;
				.v-input__control {
					.v-messages {
						display: none !important;
					}
					.v-text-field__details {
						display: none !important;
					}
					.v-input__slot {
            margin: 0;

            .v-icon {
              color: #A0AEC0;
            }
						label {
							width: 100%;
              color: #425466;
              font-size: 16px;
              font-weight: 400;
              line-height: 14px;

						}
					}
				}
			}

			.wrapper-group-description-report {
				position: absolute;
				right: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				.group-description-report {
					width: 70px;
					::v-deep .v-input {
						width: 70px !important;
						position: relative !important;
						.v-input__control {
							min-height: 33px !important;
							.v-input__slot {
								background: #edf2f7 !important;
								box-shadow: none !important;
							}
						}
					}
				}
			}
			.wrapper-group-cost-report {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 0 10px;
				.group-cost-report {
					width: 40px;
					::v-deep .v-input {
						width: 40px !important;
						position: relative !important;
					}
				}
			}
		}
		.btn-report.custom {
			::v-deep .v-input {
				width: 100% !important;
				white-space: normal !important;
				.v-input__control {
					.v-input__slot {
						label {
							width: 100% !important;
							pointer-events: all !important;
						}
					}
				}
			}
		}
		.btn-report:first-child {
			margin-top: 13px;
		}
		.btn-report.report-active {
			border: 2px solid $dark-green;
			background-color: #edf2f7;
			border-radius: 8px;
		}
	}

	.main-row {
		display: flex;
		flex-wrap: wrap;
		column-gap: 1rem;
		justify-content: space-between;
		row-gap: 1rem;
		.btn-report {
			width: fit-content;
			height: fit-content;
			background: #f7fafc;
			border-radius: 8px;
			padding: 7px;
			border: 2px solid transparent;
			cursor: pointer;
			.custom-select {
				width: 50px;
				height: 21px;
				::v-deep .v-input__control {
					min-height: unset !important;
					.v-text-field__details {
						display: none !important;
					}
					.v-input__slot {
						height: 21px !important;
						background: transparent !important;
						box-shadow: unset !important;
						padding: 0 !important;
					}
				}
			}
			.select-label {
				position: absolute;
				top: -20px;
				right: -50px;
				font-weight: 500;
				font-size: 12px;
				color: #718096;
				width: max-content;
			}
			.checkbox-label {
				position: absolute;
				top: -20px;
				right: -37px;
				font-weight: 500;
				font-size: 12px;
				color: #718096;
				width: max-content;
			}
			.report-title {
				font-weight: 500;
				font-size: 14px;
				color: #425466;
			}
			.bold {
				font-weight: 600;
				padding-left: 5px;
			}
			.check-box-additional {
				margin-left: 20px;
				::v-deep .v-input {
					.v-label {
						font-size: 14px !important;
						color: #425466 !important;
					}
					.v-label.v-label--is-disabled {
						font-size: 14px !important;
						color: grey !important;
					}
				}
			}
			.additional-filters {
				display: flex;
				column-gap: 1rem;
			}
		}
		.btn-report.report-active {
			border: 2px solid $dark-green;
			background: $light-dark-green;
		}
	}
}
</style>
