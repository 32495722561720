import Vue from 'vue'

const state = () => ({
  patients: null,
  patient: null,
  doctors: [],
  patientPortalPreviewInvite: null,
  auditEvents: null,
  patientListIsLoaded: false
})

const getters = { }

const actions = {
  async getPatients ({ commit }, params) {
    // console.log('getPatients called from', params.from)
    commit('SET_DATA', ['patientListIsLoaded', false])
    const result = await Vue.$http.post('/Patients/GetPatients', { ...params })
    if (result?.status === 200) {
      commit('SET_DATA', ['patients', result?.data])
    }
    commit('SET_DATA', ['patientListIsLoaded', true])
    console.log(result)
  },
  async getPatient ({ commit, rootGetters }, params) {
    const { id, urlParams } = params
    const paramsStr = rootGetters.BUILD_URL_PARAMS(urlParams)
    const result = await Vue.$http.get(`/Patients/${id}/Get${paramsStr}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['patient', result.data?.record])
    }
  },
  async addPatient ({ commit }, params) {
    const result = await Vue.$http.post('/Patients/Add', { ...params })
    if (result?.status === 200) {
      // commit('dialogs/TOGGLE_DIALOG', { name: 'addPatient', isOpen: false }, { root: true })
      console.log(`Respons: The patient was added; Status: ${result?.status}`)
      commit('PREPEND_PATIENT', ['patients', result?.data.record])
      return result?.data.record
    }
  },
  async updatePatient ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.put(`/Patients/${id}/Update`, { ...form })
    if (result?.status === 200) {
      // commit('dialogs/TOGGLE_DIALOG', { name: 'editPatient', isOpen: false }, { root: true })
      console.log(`Respons: The patient is updated; Status: ${result?.status}`)
      return result?.data.record
    }
  },
  deactivatePatient ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.delete(`/Patients/${id}/Deactivate`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The patient was delete; Status: ${result?.status}`)
        }
      })
    })
  },
  activatePatient ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.put(`/Patients/${id}/Activate`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The patient was delete; Status: ${result?.status}`)
        }
      })
    })
  },
  async getDoctors ({ commit }) {
    const result = await Vue.$http.get('/Patients/DoctorsDropdown')
    if (result?.status === 200) {
      commit('SET_DATA', ['doctors', result?.data?.record])
    }
  },
  async validateEmail ({ _ }, email) {
    const result = await Vue.$http.post('/Patients/ValidateEmail', email)
    // return !!(result?.status === 200)
    return result
  },
  async getPatientPortalPreviewInvite ({ commit }, params) {
    const { id, name, notes } = params
    const arr = []
    if (id) {
      arr.push(`id=${id}`)
    }
    if (name) {
      arr.push(`name=${name}`)
    }
    if (notes) {
      arr.push(`notes=${notes}`)
    }
    const queryString = arr.join('&')
    const result = await Vue.$http.post(`/Patients/PreviewInvite?${queryString}`)
    if (result?.status === 200) {
      // commit('TOGGLE_DIALOG', { name: 'addVisit', isOpen: false }, { root: true })
      console.log(`Respons: The visit was added; Status: ${result?.status}`)
      commit('SET_DATA', ['patientPortalPreviewInvite', result.data?.record])
      return result?.data
    }
  },
  async getAuditEvents ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.get(`/Patients/${id}/audit-events`)
    if (result?.status === 200) {
      commit('SET_DATA', ['auditEvents', result.data.data.$values])
      return true
    }
  },
  async remindPatient({ commit }, params) {
    const { patientId, visitId } = params
    const result = await Vue.$http.put(`/Patients/${patientId}/remind?visitId=${visitId}`)
    if (result?.status === 200) {
      return result?.data;
    }
  },
  async quickFiltersSummary({ commit }, params) {
    const { formattedDateFrom, formattedDateTo } = params
    const requestData = {
      showInactive: true,
      followUpDateFrom: formattedDateFrom,
      FollowUpDateTo: formattedDateTo
    }
    const result = await Vue.$http.post('/Patients/QuickFiltersSummary', requestData)
    if (result?.status === 200) {
      return result?.data.summary
    }
  },
  async statusesSummary({ commit }, params) {
    const { formattedDateFrom, formattedDateTo } = params
    const requestData = {
      showInactive: true,
      followUpDateFrom: formattedDateFrom,
      FollowUpDateTo: formattedDateTo
    }
    const result = await Vue.$http.post('/Patients/StatusesSummary', requestData)
    if (result?.status === 200) {
      return result?.data.summary
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  PREPEND_PATIENT: (state, payload) => {
    if (state.patients) {
      state.patients.data.$values.unshift(payload[1])
    }
  },
  APPEND_PATIENT: (state, payload) => {
    const [property, value] = payload
    const data = Object.assign([], state[property].data.$values)
    state[property].data.$values = [...data, {
      ...value,
      name: `${value.firstName} ${value.lastName}`,
      address: `${value.line1} ${value.line2} ${value.line3} ${value.line4}`,
      number: `${value.cellPhone} ${value.homePhone} ${value.workPhone}`
    }]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
