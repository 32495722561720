<template>
  <div class="d-flex align-center">
    <textarea
      class="message-input scroll-px-5"
      :value="text"
      tabindex="1"
      @input="text = $event.target.value"
      @keydown="handleKeydown"
      @focus="markAsReadMessage"
      placeholder="Type your message"
      rows="1"
    ></textarea>
    <Img
      v-if="showIcon"
      :src="getImgWithExt(['icons', 'functional', 'svg'])"
      style="margin-left: 17px; cursor: pointer"
      @click.native="send"
      alt="send massage" />
  </div>
</template>

<script>
import Input from '@/components/elements/Input'
import Image from "@/mixins/Image"
import Img from '@/components/elements/Img'
export default {
  name: 'ChatForm',
  mixins: [Image],
  components: { Input, Img },
  props: {
    showIcon: { type: Boolean, default: false },
  },
  data: () => ({
    text: ''
  }),
  methods: {
    send() {
      if (this.text.trim()) {
        this.$emit('sendNewMessage', this.text)
        this.text = ''
      }
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.send()
      }
    },
    markAsReadMessage() {
      this.$emit('markAsReadMessage')
    }
  }
}
</script>

<style scoped lang="scss">
.message-input {
  flex-grow: 1;
  resize: none;
  background-color: #EDF2F7;
  border-radius: 16px;
  padding: 22px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #2D3748;
  outline: none;

  &::placeholder {
    color: #A0AEC0;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }

  &:focus {
    border-color: #cbd5e0;
  }
}
</style>
