<template>
  <div class="table">
    <v-data-table v-bind="$props" :options.sync="options">
      <template v-for="(iter, i) in headers" :slot="`header.${iter.value}`" slot-scope="{item}">
        <div v-if="iter.filterable" :key="`${i}-filterable`" class="d-flex float-left" style="position:relative;">
          <filter-box
            :field-name="iter.value"
            :title="iter.text"
            :type="iter.type"
            :statusCounts="statusCounts"
            @update:filter-box="emitFilterBox"
            @reset:filter-box="emitResetFilter" />
          <span v-if="iter.isFiltered" class="green-label"></span>
          <span
            class="ml-2"
            :key="i"
            :class="{
              'brown-text': activeFilteredColumn && activeFilteredColumn.name.toLowerCase() === iter.value.toLowerCase()
            }"
          >
          </span>
          <slot :name="`header:${iter.value}`" :item="item" />
        </div>
        <div v-if="iter.isReplacingSpace" :key="`${i}-isReplacingSpace`" class="d-flex justify-center">
          <div v-html="iter.text" style="text-align: center"></div>
        </div>
        <div v-else-if="(iter.isIcon || iter.customFilter) && !currentHeader" :key="`${i}-custom-filter`" class="d-flex float-left" :class="{ 'cursor-pointer': iter.action }" >
          <div class="d-flex align-center">
            <div class="search-icon mr-2" @click="handleEvent(iter)">
              <img :src="getImgWithExt(['icons', 'search', 'svg'])" alt="icon" />
            </div>
            <span v-if="iter.text">{{iter.text}}</span>
          </div>
          <div v-if="iter.customFilter" class="d-flex justify-end align-center" id="filter-activator" style="cursor: pointer;" @click.stop="showCustomFilter">
            <img v-if="customFilterIsActive" :src="getImgWithExt(['icons', 'blood-custom-filter-active', 'svg'])" alt="icon" />
            <img v-else :src="getImgWithExt(['icons', 'blood-custom-filter', 'svg'])" alt="icon" />
            <v-menu activator="#filter-activator" offset-y :close-on-content-click="true" v-model="isFilterActive">
              <v-list style="padding: 10px 10px;">
                <v-list-item
                  v-for="(item, index) in customFilterItems"
                  :key="index"
                  :value="item.value"
                  class="filter-list-item"
                  @click="filterData(item)"
                >
                  <v-list-item-title style="display: flex;align-items: center;column-gap: 10px">
                    <img :src="getImgWithExt(['icons', item.icon, 'svg'])" alt="icon" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="filter-label">{{selectedFilterValue}}</span>
          </div>
        </div>
        <div v-else :key="i" class="column-title d-flex float-left">
          <span v-if="currentHeader?.text !== iter.text">{{iter.text}}</span>
<!--          <slot :name="`header:${iter.value}`" :item="item" />-->
        </div>
        <div :key="`${i}-input`"
             v-if="JSON.stringify(currentHeader) === JSON.stringify(iter)"
             class="d-flex justify-center align-center search-input-wrapper"
        >
          <Input outlined
                 isAutofocus
                 height="30px"
                 class="search-input"
                 @blur="currentHeader = null"
                 v-model="searchInputValue"
                 @input="inputSearchDelayed"
                 :style="{'width': '100%'}"
          >
            <div slot="prepend-inner" style="cursor: pointer">
              <img :src="getImgWithExt(['icons', 'search', 'svg'])" alt="icon" />
            </div>
            <div v-if="searchInputValue" slot="append" style="cursor: pointer" @click="clearSearchInput">
              <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
            </div>
          </Input>
          <div v-if="iter.customFilter"
               id="filter-activator-2"
               class="d-flex justify-end align-center"
               style="cursor: pointer;"
               @click.stop="showCustomFilter">
            <img v-if="customFilterIsActive" :src="getImgWithExt(['icons', 'blood-custom-filter-active', 'svg'])" alt="icon" />
            <img v-else :src="getImgWithExt(['icons', 'blood-custom-filter', 'svg'])" alt="icon" />
            <v-menu activator="#filter-activator-2" offset-y :close-on-content-click="true" v-model="isFilterActive">
              <v-list style="padding: 10px 10px;">
                <v-list-item
                  v-for="(item, index) in customFilterItems"
                  :key="index"
                  :value="item.value"
                  class="filter-list-item"
                  @click="filterData(item)"
                >
                  <v-list-item-title style="display: flex;align-items: center;column-gap: 10px">
                    <img :src="getImgWithExt(['icons', item.icon, 'svg'])" alt="icon" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="filter-label">{{selectedFilterValue}}</span>
          </div>
        </div>
      </template>
      <template v-for="(iter, i) in headers" :slot="`item.${iter.value}`" slot-scope="{item}">
        <slot v-if="iter.isSlotable" :name="`item:${iter.value}`" :item="item"></slot>
        <span v-else :key="i">{{item[iter.value]}}</span>
      </template>

      <template slot="body.append">
        <slot name="body:append"></slot>
      </template>

      <template slot="body">
        <slot name="body"></slot>
      </template>

      <template slot="item.actions" slot-scope="{ item }">
        <actions :items="item.portal && item.active ? actions : item.portal && !item.active ? actionsWithActivate : !item.portal && !item.active ? extendedActionsWithActivate : extendedActions"
          v-on="actionListener"
          :item="item"
        />
      </template>

      <template v-slot:footer="{ item }">
        <slot name="footer" :item="item"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'
import actions from '@/components/layouts/actions'
import filterBox from '@/components/layouts/filter-box'
import Input from '@/components/elements/Input.vue'
import Image from '@/mixins/Image'

export default {
  name: 'Table',
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    actions: { type: Array },
    actionsWithActivate: { type: Array },
    extendedActions: { type: Array },
    extendedActionsWithActivate: { type: Array },
    deactivatePatient: Object,
    activatePatient: Object,
    statusCounts: Object,
    serverItemsLength: {
      type: Number
    },
    hideDefaultFooter: {
      type: Boolean
    }
  },
  mixins: [Validation, Image],
  components: { actions, filterBox, Input },
  data: () => ({
    activeFilteredColumn: null,
    currentHeader: null,
    searchInputValue: null,
    searchTimer: null,
    selectedFilterValue: 'Groups',
    customFilterIsActive: false,
    isFilterActive: false,
    customFilterItems: [
      { title: 'Show Groups', value: 0, icon: 'blood-show-groups', label: 'Groups' },
      { title: 'Out of Range', value: 1, icon: 'blood-out-of-range', label: 'Out of Range' },
      { title: 'Sort by Name', value: 2, icon: 'blood-sort-by-name', label: 'Sorted by Name' },
    ]
  }),
  computed: {
    options: {
      get () {
        return {}
      },
      set (val) {
        this.$emit('update:option', val)
      }
    },
    actionListener: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          action: function (event) {
            vm.$emit('click:action', { action: event.params, id: event.id, data: event.data })
          }
        }
      )
    }
  },
  methods: {
    emitFilterBox (value) {
      const { form, statuses } = value
      const column = this.headers.find(header => header.value === (form.rangeSearch ? form.rangeSearch.data : form.name.toLowerCase()))

      if (form.rangeSearch) {
        this.activeFilteredColumn = {
          name: form.rangeSearch.data,
          type: form.rangeSearch.type
        }
        if (column) {
          column.isFiltered = true
        }
      } else {
        this.activeFilteredColumn = form
        if (column) {
          column.isFiltered = statuses.length ? true : false
        }
      }
      this.$emit('filterBox', form)
    },
    emitResetFilter (fieldName) {
      const column = this.headers.find(header => header.value === fieldName)

      if (column) {
        column.isFiltered = false
      }
      this.activeFilteredColumn = null
      this.$emit('resetFilter', fieldName)
    },
    handleEvent (iter) {
      if (iter.action) {
        this.currentHeader = iter
      }
    },
    showCustomFilter() {
      this.customFilterIsActive = true
    },
    filterData(obj) {
      this.searchInputValue = null
      this.selectedFilterValue = obj.label
      this.$emit('filer:update', obj.value)
    },
    clearSearchInput() {
      this.searchInputValue = ''
      this.$emit('searchByInput', this.searchInputValue )
    },
    inputSearchDelayed() {
      clearTimeout(this.searchTimer);

      if (this.searchInputValue.trim() === '') {
        this.searchTimer = setTimeout(() => {
          this.$emit('searchByInput', this.searchInputValue );
        }, 1000);
        return;
      }

      if (this.searchInputValue.length >= 2) {
        this.searchTimer = setTimeout(() => {
          this.$emit('searchByInput', this.searchInputValue)
          this.currentHeader = null
        }, 1000);
      }
    }
  },
  watch: {
    isFilterActive (val) {
      if (!val) {
        this.customFilterIsActive = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table {

    ::v-deep .v-data-table {
       filter: drop-shadow(0px 10px 20px rgba(113, 128, 150, 0.06));

      &__wrapper {
        .v-data-table-header {
          tr {
            th {
              font-size: 10px;
              font-weight: 600;
              color: #8492A6;
              white-space: nowrap;
            }
          }
        }
        tbody {
          tr {
            &:hover { background-color: transparent !important; cursor: pointer; }
            &:last-child {
              td {
                 border-bottom: thin solid #EDF2F9;
              }
            }
          }
        }
      }
      .v-input {
        .v-input__control {
          .v-text-field__details {
            display: none!important;
          }
        }
      }
    }
    .brown-text {
      color: brown;
    }
    .cursor-pointer {
      cursor: pointer;
    }
  }
  ::v-deep .v-menu__content {
    .v-list {
      .v-list-item:hover {
        background: #f6f6f6!important;
      }
    }
  }
  .filter-label {
    margin-left: 10px;color: black;
    background: #f6f6f6;
    padding: 5px;
    border-radius: 5px;
  }
  .search-input {
    ::v-deep .v-input {
      .v-input__control .v-input__slot .v-text-field__slot input {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  ::v-deep .v-input__append-inner {
    position: absolute;
    right: 10px;
    top: 3px;
  }

  .green-label {
    position: absolute;
    right: 12px;
    bottom: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $dark-green;
  }

  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #FAFAFB;

    &:hover {
      background-color: #EDF2F7;
    }

    .image {
      width: 16px;
      height: 16px;
    }
  }
</style>
