<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class="history" v-if="patient">
    <div class="visits-list">
      <v-timeline dense>
        <v-timeline-item
          v-for="(visit, key) in sortedHistory"
          :key="key"
          class="pb-0"
          :color="getStatusCodeName[visit.status].class"
        >
          <card class="history__list">
            <template v-slot:body>
              <v-row
                class="history__list-content d-flex no-margin"
                style="cursor: pointer;"
                @click="navToSurvey(visit.id)"
              >
                <v-col class="date-status px-0 py-0">
                  <div>
                    <div class="visits-list__date">
                      {{ $moment(visit.date).format('MM/DD/YYYY') }}
                    </div>
                    <div class="visits-list__doctor">
                      <v-tooltip right :open-on-hover="true">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-show="visit.status !== 'None'"
                               v-bind="attrs"
                               v-on="on">
                            <status
                              v-if="getStatusCodeName[visit.status]"
                              :style="{
                                backgroundColor: getStatusCodeName[visit.status]?.background || '#EDF2F7',
                                color: getStatusCodeName[visit.status]?.text || '#A0AEC0'
                              }"
                            >
                              {{ getStatusCodeName[visit.status].name || '-' }}
                            </status>
                          </div>
                        </template>
                        <div
                          style="display: block; max-width: 300px"
                          v-if="getStatusCodeName[visit.status]"
                        >
                          {{
                            getStatusCodeName[visit.status].description(
                              visit.statusDays
                            ) || '-'
                          }}
                        </div>
                      </v-tooltip>
                    </div>
                  </div>

                  <div class="more-description">
                    <div class="sub-label">
                      Supply Days
                      <span class="label">{{ visit.supplyDays || '-' }}</span>
                    </div>
                    <div v-if="visit.surveyChange" class="survey-label">
                      {{ getSurveyLabel(visit.surveyChange) }}:
                      <span
                        class="label"
                        :style="{ color: getLabelColor(visit.surveyChange) }"
                      >{{ visit.surveyScore || '-' }}
                      </span>
                    </div>
                  </div>
                  <!--Evaluation to -->
                </v-col>

                <v-col class="info-notes py-0 pr-0">
                  <div class="d-flex justify-space-between pb-4">
                    <div class="d-flex align-center grow-1 doctor-name">
                      <Img :src="getImgWithExt(['icons', 'doctor-icon', 'svg'])" />
                      <div style="font-size: 14px">{{ patient.doctorName }}</div>
                    </div>

                    <div class="notifications-buttons">
                      <div v-if="visit.showRemind" class="d-flex notifications-buttons__btn">
                        <Button
                          color="#008C8929"
                          @click.native.stop="sendReminder(patient.id, visit.id)"
                        >
												<span style="color: #008C89">
                          Remind
													<Img class="icon" :src="getImgWithExt(['icons', 'right-arrow-green', 'svg'])"
                          />
												</span>
                        </Button>
                      </div>
                      <div class="d-flex notifications-buttons__btn">
                        <Button
                          color="#EBF2FA"
                          @click.native.stop="toggleModalReports(true, visit.id)"
                        >
												<span style="color: #425466">
                          Reports
													<Img class="icon" :src="getImgWithExt(['icons', 'right-arrow', 'svg'])"
                          />
												</span>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div v-if="visit.evaluationNotes" class="visits-list__note pb-4">
                    <span class="sub-label">Note: </span>
                    {{ visit.evaluationNotes }}
                  </div>

                  <v-row class="stats-block">
                    <div class="stats-block__wrapper">
                      <v-col v-if="visit.totalSurveyAnsweredQuestions !== -1" class="text-right" @click="navToSurvey(visit.id)">
                        <span class="grey--text">Survey</span>
                        <span style="color: #008C89; font-size: 14px">{{ visit.totalSurveyAnsweredQuestions }}</span>
                      </v-col>
                      <v-col v-if="visit.totalBloodEnteredValues !== -1" class="text-right" @click="navToBlood(visit.id)">
                        <span class="grey--text">Blood</span>
                        <span class="font-weight-bold" style="color: #008C89; font-size: 14px">{{ visit.totalBloodEnteredValues }}</span>
                      </v-col>
                      <v-col v-if="visit.totalSelectedResultSupplements !== -1" class="text-right" @click="navToResults(visit.id)">
                        <span class="grey--text">Results</span>
                        <span class="font-weight-bold" style="color: #008C89; font-size: 14px">{{ visit.totalSelectedResultSupplements }}</span>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
                <!-- <v-divider vertical /><v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col> -->
                <!-- <v-col class="actions py-2" cols="2">
                </v-col> -->
              </v-row>
            </template>
          </card>
        </v-timeline-item>
      </v-timeline>
    </div>

    <v-dialog
      v-model="showReport"
      width="60%"
      @click:outside="(initialDoc = ''), (loading = false)"
    >
      <div
        v-if="loading"
        style="
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				"
        class="d-flex justify-center px-2 py-2 align-center"
      >
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
      <ReportView
        :disabledSpanish="true"
        :activeLanguage="'en'"
        :initialDoc="initialDoc"
        :showActionButton="false"
        :frameHeight="'800'"
      />
    </v-dialog>

    <EvaluationReports v-if="dialogs.evaluationReportsModal.isOpen" />
    <div v-if="loading" class="loading d-flex justify-center align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Pagination from '@/mixins/Pagination';
import card from '@/components/layouts/card';
import Mapping from '@/mixins/Mapping';
import EvaluationReports from '@/components/elements/EvaluationReports';
import status from '@/components/layouts/status.vue';
import Button from '@/components/elements/Button.vue';

import ReportView from '@/components/layouts/dialogs/reportView.vue';
import Image from '@/mixins/Image';

const SURVEY_TAB_INDEX = 0;
const BLOOD_TAB_INDEX = 1;
const RESULTS_TAB_INDEX = 2;
const RESULT_TAB_INDEX = 1;

export default {
  name: 'history',
  mixins: [Pagination, Mapping, Image],
  components: {
    card,
    status,
    Button,
    ReportView,
    EvaluationReports
  },
  data: () => ({
    showReport: false,
    fileUrl: null,
    initialDoc: null,
    loading: false,
    selectedVisit: null,
    alertIsOpen: false,
    popupMessage: null
  }),
  async created() {
    const { id } = this.$route?.params;
    if (id) {
      this.addUrlParam({ includeVisitsHistory: true });
      // await this.getPatient({ id, urlParams: this.query })
      // this.patient = Object.assign({}, this.$store.state.patients.patient)
      this.SET_PAGE_SETTINGS({
        title: `${this.patient.firstName} ${this.patient.lastName}`
      });
    }
  },
  destroyed() {
    this.SET_PAGE_SETTINGS(null);
  },
  methods: {
    ...mapActions('patients', ['getPatient', 'remindPatient']),
    ...mapActions('reports', ['gerReportByCode']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations(['SET_PAGE_SETTINGS']),

    toggleModalReports(value, visitId) {
      localStorage.setItem('selectedVisitId', visitId);

      this.TOGGLE_DIALOG({
        name: 'evaluationReportsModal',
        isOpen: value,
        visitId
      });
    },

    async getReport(reportCode, id) {
      const payload = {
        appUserId: this.userProfile.userId,
        reportCode,
        entityId: id
      };
      const res = await this.gerReportByCode(payload);
      this.file = res;
      this.parseFile(this.file);
      this.loading = false;
    },
    parseFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.fileUrl = URL.createObjectURL(file);
          this.initialDoc = this.fileUrl;
        };
        reader.readAsDataURL(file);
      } else {
        this.fileUrl = null;
      }
    },
    navToResult(id) {
      this.$router.push({
        path: `visit/${id}`,
        query: {tabIndex: RESULT_TAB_INDEX}
      });
    },
    navToSurvey(id) {
      this.$router.push({
        path: `visit/${id}`,
        query: {tabIndex: SURVEY_TAB_INDEX}
      });
    },
    navToBlood(id) {
      this.$router.push({
        path: `visit/${id}`,
        query: {tabIndex: BLOOD_TAB_INDEX}
      });
    },
    navToResults(id) {
      this.$router.push({
        path: `visit/${id}`,
        query: {tabIndex: RESULTS_TAB_INDEX}
      });
    },
    runAction(val) {
      val.params.action(val.id, val);
    },
    patientSchedule(id) {
      this.showReport = true;
      this.loading = true;
      this.getReport('PatientSchedule', id);
    },
    patientSurveyScoreSheet(id) {
      this.showReport = true;
      this.loading = true;
      this.getReport('PatientSurveyScoreSheet', id);
    },
    clinicianReport(id) {
      this.showReport = true;
      this.loading = true;
      this.getReport('ClinicianFinalReport', id);
    },
    clinicianWarnings(id) {
      this.showReport = true;
      this.loading = true;
      this.getReport('ClinicianWarnings', id);
    },
    getSurveyLabel(surveyChange) {
      switch (surveyChange) {
        case 1:
          return 'Improving';
        case 0:
          return 'Unchanged';
        case -1:
          return 'Declining';
        default:
          return '';
      }
    },
    getLabelColor(surveyChange) {
      switch (surveyChange) {
        case 1:
          return '#008C89';
        case 0:
          return '#718096';
        case -1:
          return '#F16063';
        default:
          return '#718096';
      }
    },
    async sendReminder(patientId, visitId) {
      try {
        this.loading = true;
        const res = await this.remindPatient({ patientId, visitId });

        if (res) {
          this.showPopup({
            type: 'reminder',
            data: {
              message: 'Evaluation reminder email sent to patient.'
            }
          });
        } else {
          this.showPopup({
            type: 'error',
            data: {
              message: 'Failed to send reminder. Please try again later.'
            }
          });
        }
      } catch (error) {
        console.error('Error in sendReminder: ', error);
        this.showPopup({
          type: 'error',
          data: {
            message: 'An error occurred while sending the reminder.'
          }
        });
      } finally {
        this.loading = false;
      }
    },

    showPopup(res) {
      this.alertIsOpen = true;
      this.popupMessage = res.data.message;
    },
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      userProfile: (state) => state.userProfile
    }),

    dialogs() {
      return this.$store.state.dialogs;
    },

    sortedHistory() {
      if (this.patient.visits && this.patient.visits.$values) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.patient.visits.$values.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
      } else {
        return [];
      }
    },
    actions() {
      return [
        {
          color: 'primary',
          name: 'Patient Schedule',
          action: this.patientSchedule
        },
        {
          color: 'red',
          name: 'Patient Survey Score Sheet',
          action: this.patientSurveyScoreSheet
        },
        {
          color: 'red',
          name: 'Clinician Report',
          action: this.clinicianReport
        },
        {
          color: 'red',
          name: 'Clinician Warnings',
          action: this.clinicianWarnings
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
.no-margin {
  margin: 0 !important;
}

.history {
  padding-top: 10px;
  overflow-y: auto;

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(213, 213, 213, 0.5);
    z-index: 1;
  }

  .visits-list {
    max-height: 100vh;
    min-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #F7FAFC;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CBD5E0;
      border: 1px solid #CBD5E0;
      border-radius: 10px;
    }

    ::v-deep .v-timeline {
      padding: 0;

      &:before {
        left: 6px !important;
      }
      .v-timeline-item {
        position: relative;
        //box-shadow: 0px 10px 20px rgba(113, 128, 150, 0.06);
        border-radius: 0 16px 16px 0;

        +.v-timeline-item {
          margin-top: 10px;
        }

        &__dot {
          width: 14px;
          height: 14px;
          box-shadow: none;
        }

        &__inner-dot {
          width: 14px;
          height: 14px;
          margin: 0;
          border: 3px solid;

          &.in-progress,
          &.in-protocol {
            border-color: #537CFF;
          }

          &.reviewed,
          &.survey-sent {
            border-color: #008C89;
          }

          &.need-review,
          &.need-survey,
          &.evaluation-due {
            border-color: #D46A51;
          }

          &.completed {
            border-color: #A0AEC0;
          }

          &.canceled,
          &.need-product,
          &.protocol-ending {
            border-color: #CF4655;
          }
        }

        &__divider {
          min-width: 14px !important;
        }

        &__body {
          background-color: $white;
          border-radius: 0 16px 16px 0;
          margin-left: -6px;
          max-width: 100% !important;
          box-shadow: 0px 10px 20px rgba(113, 128, 150, 0.06);
        }
      }
    }

    &__date {
      font-weight: 600;
      font-size: 14px;
      color: #425466;
    }

    &__doctor {
      display: inline-flex;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &__note {
      color: #425466;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0;
    }

    &__navs {
      height: 100%;
      .v-icon {
        border-radius: 6px;\padding: 4px;
      }
      .file {
        background: rgba(115, 217, 149, 0.2);
        border-radius: 10px;
      }
      .briefcase {
        background: rgba(208, 220, 255, 0.5);
        border-radius: 10px;
      }
    }
  }

  &__list {
    ::v-deep .v-card {
      padding: 20px 26px !important;
    }

    .date-status {
      max-width: 147px;
      flex-basis: 147px;
      border-right: 1px solid #E2E8F0;
    }

    .info-notes {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 23px;

      .description {
        width: 100%;
      }

      .reports {
        padding: 0 0 0 20px;
      }

      .label {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #425466;
      }

      .sub-label {
        color: #718096;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;

      ::v-deep .actions {
        .v-btn {
          max-width: 25px;
        }
      }
    }
  }

  ::v-deep .status {
    border-radius: 8px !important;
    text-align: center !important;

    .v-chip {
      height: auto;
      justify-content: center !important;
      border-radius: 8px !important;
      background: unset !important;
      color: unset !important;
      padding: 4px 10px;
      cursor: pointer !important;
    }
  }

  .more-description {
    .sub-label {
      color: #718096;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;

      .label {
        font-weight: 600;
      }
    }

    .survey-label {
      display: inline-flex;
      background-color: #EDF2F7;
      border-radius: 8px;
      color: #718096;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.96px;
      padding: 5px 10px;
      margin-top: 4px;
      text-transform: uppercase;

      .label {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding-left: 5px;
      }
    }
  }
}


.doctor-name {
  color: #425466;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  img {
    margin-right: 10px;
  }
}

.notifications-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__btn {
    margin-left: 10px;

    ::v-deep .v-btn {
      height: 36px;
      padding: 8px 22px;
      border-radius: 6px;

      span {
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        font-size: 12px;
        line-height: 9px;
        letter-spacing: 0;

        .icon {
          width: 10px;
          height: 10px;
          margin-left: 8px;
        }
      }
    }
  }
}

.stats-block {
  margin: 0;
  flex-grow: 0;

  &__wrapper {
    display: flex;
    background-color: #F7FAFC;
    border-radius: 6px;
  }

  .text-right {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    flex-grow: 0;
    padding: 5px 10px;
  }

  .grey--text {
    color: #718096 !important;
    font-weight: 600;
    margin-right: 10px;
    letter-spacing: 0;
  }
}

.custom-alert {
  max-width: 300px;
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 100;
}

@media (max-width: 1100px) {
  .history {
    &__list {
      ::v-deep .v-card {
        padding: 13px 20px 20px !important;
      }

      .date-status {
        display: flex;
        justify-content: space-between;
        flex-basis: 100%;
        max-width: 100%;
        border-right: none;
        border-bottom: 1px solid #E2E8F0;
      }

      .info-notes {
        padding-left: 0;
        padding-top: 13px !important;
      }
    }
    &__list-content {
      flex-direction: column;
    }
    .more-description {
      .survey-label {
        margin-top: 10px;
      }
    }
  }
}

//@media (max-width: 767px) {
//  ::v-deep .v-application .tab-container {
//    padding-left: 0 !important;
//  }
//}
</style>
