<template>
	<div class="confirm-dialog">
		<Dialog width="450">
			<template slot="title">
				<div class="image">
					<Img
						:src="getImgWithExt(['icons', 'confirm-logo-title', 'svg'])"
						width="50"
					/>
				</div>
				<div class="title">
					{{ confirmState.title }}
				</div>
			</template>

			<template slot="body">
				<div class="description" v-html="confirmState.description"></div>
			</template>

			<template slot="actions">
				<div class="d-flex justify-space-between w-100 pb-4 px-4">
					<Button
						dark
						outlined
						color="grey"
						@click.native="close"
						:styles="{ 'border-radius': '8px' }"
					>
						<span style="color: #425466">Cancel</span>
					</Button>
					<Button
						:disabled="isLoading"
						btn-bg="dark-green"
						@click.native="confirm"
						:styles="{ 'border-radius': '8px' }"
					>
						<span style="color: #ffffff" v-if="!isLoading"> Confirm </span>
						<span style="color: #ffffff" v-if="isLoading"> Loading... </span>
					</Button>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Dialog from '@/components/layouts/dialog';
import Button from '@/components/elements/Button';
import Image from '@/mixins/Image';

export default {
	name: 'confirm-dialog',
	props: {
		query: {
			type: Object
		}
	},
	data: () => ({
		isLoading: false
	}),
	components: { Dialog, Button },
	mixins: [Image],
	methods: {
		...mapMutations('dialogs', ['TOGGLE_DIALOG']),
		async confirm() {
			const {
				id,
				action,
				afterAction,
				whose,
				surveyId,
				resultId,
				questionNumber,
				visitId,
				data,
				callback
			} = this.confirmState;
			if (whose === 'quickEntry') {
				await this.$store.dispatch(action, { surveyId, questionNumber });
				await this.$store.dispatch(afterAction.name, { surveyID: surveyId });

				this.close()

			} else if (whose === 'survey') {
        const res = await this.$store.dispatch(action, { surveyId, questionNumber });
        if (res) {
          window.location.reload()
        }
        this.close()

      } else if (whose === 'confirmationDeleteVisit') {
				await this.$store.dispatch(action, visitId);
				this.close();
				this.TOGGLE_DIALOG({ name: 'modalConfirmationDeleteVisit', isOpen: false, root: true });

				this.$router.push(afterAction.name);

			} else if (whose === 'patientInviteToPortal') {
				this.TOGGLE_DIALOG({
					id: data.id,
					name: 'editPatient',
					isOpen: true,
					title: 'Edit patient',
					root: true
				});
				this.close()

			} else if (whose === 'confirmationMoveEvaluation') {
        await this.$store.dispatch(action, visitId)
        this.close()
      } else if (whose === 'confirmationDeleteReceipt') {
				const res = await this.$store.dispatch(action, {
					form: { resultId, receiptId: id }
				});
				await this.$store.dispatch('visits/updateTotalInvoices', {
					totalInvoices: res.totalInvoicesCount
				});
				this.$emit('showPopup', res);
				this.close()
			} else {
				if (!callback) {
					const res = await this.$store.dispatch(action, id);
					this.$emit('showPopup', { type: this.confirmState.type, data: res });

					if (this._.isObject(afterAction)) {
						if (this.query) {
							await this.$store.dispatch(afterAction.name, this.query);
						} else if (afterAction.protocol) {
							await this.$store.dispatch(
								afterAction.name,
								afterAction.protocol
							);
						} else if (afterAction.orderMethod) {
							await this.$store.dispatch(afterAction.name, {
								id: afterAction.id,
								orderMethod: afterAction.orderMethod
							});
						} else {
							await this.$store.dispatch(afterAction.name, afterAction.id);
						}
					}
				}

				if (callback) {

					if(action) {
						const res = await this.$store.dispatch(action, id);
						this.$emit('showPopup', { type: this.confirmState.type, data: res });
					}

					console.log({ callback });
					this.isLoading = true;

					await callback();

					this.isLoading = false;
				}

				this.$emit('close');
				this.close()
			}
		},
		close() {
			this.TOGGLE_DIALOG({ name: 'confirm', isOpen: false });
		}
	},
	computed: {
		...mapState({
			confirmState: (state) => state.dialogs.confirm
		})
	}
};
</script>

<style lang="scss" scoped>
.w-100 {
	width: 100%;
}
.confirm-dialog {
	//::v-deep {
	//  .v-card__title {
	//    position: relative!important;
	//    .v-icon {
	//      position: absolute!important;
	//      right: 15px;
	//      top: 0;
	//    }
	//  }
	//}
	//.title {
	//  width: 100%;
	//  text-align: center;
	//}
	//.description {
	//  padding: 0 16px;
	//}
	::v-deep .v-dialog__container {
		.v-card {
			&__title {
				//background: #edf2f7;
				padding: 25px 35px 17px 26px !important;
				justify-content: center !important;
				position: relative;
				.image {
					width: 100%;
					text-align: center;
				}
				.title {
					font-family: 'Poppins';
					font-weight: 600;
					font-size: 16px;
					text-align: center;
					color: #27272e;
				}
				.v-icon {
					display: none;
					position: absolute;
					right: 15px;
					top: 25px;
				}
			}
			&__text {
				.description {
					font-family: 'Poppins';
					font-weight: 400;
					font-size: 14px;
					text-align: center;
					color: #425466;
				}
				.v-input {
					.v-text-field__details {
						display: none;
					}
				}
			}
		}
	}
}
</style>
