<template>
  <div class="filter-box d-flex">
    <v-menu right buttom :close-on-content-click="false" ref="filterMenu">
      <template v-slot:activator="{ on, attrs }">
        <div class="filter-icon" v-on="on" v-bind="attrs">
          <Img :src="getImgWithExt(['icons', 'filter', 'svg'])" />
        </div>
      </template>
      <v-list max-width="290" class="filter-popup">
        <div style="padding: 0 12px;" v-if="title === 'Evaluation Status'">Evaluation Status <span v-if="Statuses.length > 0" class="status-length">{{ Statuses.length > 0 ? Statuses.length : '' }}</span></div>
        <div style="padding: 0 12px;" v-if="title === 'Evaluation Date'">Evaluation Date</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Name' || title === 'Email'">Show {{title}}s That:</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Next Visit' || title === 'Last Visit'">Show {{title}} by Date Range:</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Phone'">Show Phone Numbers That:</div>
        <div v-if="type !== 'Date' && type !== 'Enum'" style="padding: 0 15px;">
          <Select outlined :items="items" :value="items[0]" @change="setType" />
        </div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                :id="`filter-box-${fieldName}`"
                :style="fieldName === 'status' ? 'height: 200px; overflow-x: auto' : ''">
                <template v-if="type === 'Number' && searchType == 4"><!-- && form.search.searchType == 4-->
                  <div class="d-flex flex-column">
                    <Input class="mr-2" outlined  placeholder="from" radius="8" :rules="[notEmtpy]" @input="setFrom" @enter="filter"/>
                    <Input outlined placeholder="to" radius="8" :rules="[notEmtpy]" @input="setTo" @enter="filter"/>
                  </div>
                </template>
                <template v-if="type === 'Date'">
                  <div class="d-flex justify-space-between mt-5">
                    <div class="d-flex flex-column mr-1">
                      <datepicker @date:update="rangeSearch.from = $event, setTypeDate('from', $event)"
                                  :value="rangeSearch.from" placeholder="From" />
                    </div>
                    <div class="d-flex flex-column ml-1">
                      <datepicker @date:update="rangeSearch.to = $event, setTypeDate('to', $event)"
                                  :value="rangeSearch.to" placeholder="To" />
                    </div>
                  </div>
                </template>
                <template v-if="type === 'Enum'">
                  <v-checkbox
                    style="margin-top: 0"
                    :multiple="true"
                    v-model="Statuses"
                    v-for="status in getStatuses"
                    :key="status.statusCode"
                    :value="status.statusCode"
                    @change="applyStatuses"
                  >
                    <template v-slot:label>
                      <div class="status-label">
                        {{ status.statusName }}
                        <span v-if="statusCounts[status.statusCode]" class="status-count">{{ statusCounts[status.statusCode] || ' ' }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </template>
                <template v-if="type !== 'Date' && type !== 'Enum' && searchType !== 4">
                  <Input outlined placeholder="Some value" radius="8" :rules="[notEmtpy]" @input="form.search.value=$event" @enter="filter"/>
                </template>
              </v-form>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex justify-end">
              <Button
                class="clear-btn"
                dark
                outlined
                color="#008C89"
                @click.native="reset">Clear</Button>
              <Button dark class="filter-btn ml-4" btn-bg="dark-green" @click.native="filter">Filter</Button>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Select from '@/components/elements/Select'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import datepicker from '@/components/elements/datepicker'
import Validate from '@/mixins/Validation'
import Mapping from '@/mixins/Mapping'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'

const searchTypeEnum = ['StartsWith', 'Contains', 'EndsWith', 'Equals', 'MultipleRange']

export default {
  name: 'filter-box',
  props: {
    headers: {
      type: Array
    },
    fieldName: {
      type: String
    },
    type: {
      type: String
    },
    title: {
      type: String
    },
    statusCounts: Object
  },
  mixins: [Validate, Mapping, Image],
  components: { Select, Input, Button, datepicker, Img },
  data () {
    return {
      testItems: ['Foo', 'Bar', 'Fizz', 'Buzz', 'Buzz1', 'Buzz2', 'Buzz3', 'Buzz4 '],
      searchType: '',
      form: {
        /* type: this.type,
        name: this.fieldName, // "string",
        search: {
          searchType: '', // "StartsWith",
          value: ''
        },
        searchable: true */
        // new from SM-632
        /* Statuses: [] */
        // new from SM-632
      },
      search: {
        searchType: '', // "StartsWith",
        value: ''
      },
      rangeSearch: {
        type: this.type,
        data: this.fieldName,
        from: '',
        to: ''
      },
      Statuses: []
    }
  },
  methods: {
    filter () {
      if (this.validate()) {
        const filterData = {
          form: this.form,
          statuses: this.Statuses
        };
        this.$emit('update:filter-box', filterData)
        this.$refs.filterMenu.save()
      }
    },
    reset () {
      this.$emit('reset:filter-box', this.fieldName)
      this.$refs.form.reset()
      this.$refs.filterMenu.save()
    },
    setType ($event) {
      const searchType = this._.upperFirst(this._.camelCase($event))
      const serchIndex = searchTypeEnum.findIndex(el => el === searchType)
      this.searchType = serchIndex
      this.form = {
        type: this.type,
        name: this.fieldName,
        searchable: true,
        search: {
          ...this.search,
          searchType: serchIndex
        }
      }
      if (this.$refs?.form?.resetValidation) {
        this.$refs.form.resetValidation()
      }
    },
    setTypeDate (typeKey, value) {
      if (this.type === 'Date') {
        this.form = {
          rangeSearch: { ...this.rangeSearch }
        }
      }
    },
    applyStatuses () {
      this.form = {
        name: 'Status',
        type: 'Enum',
        constraints: [...this.Statuses]
      }
    },
    setFrom ($event) {
      const value = this.form.search.value
      let tmpVal = ''
      if (value.includes('|')) {
        tmpVal = value.split('|').unshift()
      }
      this.form.search.value = `${$event}|${tmpVal}`
    },
    setTo ($event) {
      const value = this.form.search.value
      let tmpVal = ''

      if (value.includes('|')) {
        tmpVal = value.split('|').pop()
      }
      this.form.search.value = `${tmpVal}|${$event}`
    }
  },
  computed: {
    items () {
      let slctItems = []
      switch (this.type) {
        case 'String':
          slctItems = ['Starts With', 'Contains', 'Ends With', 'Equals']
          break
        case 'Number':
          slctItems = ['Contains', 'Equals', 'Multiple Range']
          break
      }

      return slctItems
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-box {
    .filter-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 8px;
      background-color: #FAFAFB;

      &:hover {
        background-color: #EDF2F7;
      }

      .image {
        width: 16px;
        height: 16px;
      }
    }
  }
  ::v-deep .v-input {
    .v-input__control {
      .v-text-field__details {
        display: none !important;
      }
      .v-messages {
        display: none !important;
      }
    }
  }

  .filter-popup {
    padding: 14px 0;

    & > div:first-child {
      color: #718096;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    ::v-deep .v-list-item {
      padding: 0 6px 0 12px;

      &__content {
        padding: 0;
      }

      &__subtitle {
        margin-bottom: 0;
      }
    }

    ::v-deep .v-input--selection-controls {
      padding: 0 !important;
      margin-bottom: 6px !important;

      .v-input__slot {
        padding: 5px 0 5px 10px;
        margin-bottom: 0;

        .v-label {
          color: #425466;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
        }

        .v-input--selection-controls__input {
          i {
            color: #C9CED6;
          }

          .primary--text {
            color: $dark-green !important;
            caret-color: $dark-green !important;
          }

          &:hover {
            .v-input--selection-controls__ripple {
              &:before {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    ::v-deep .v-form {
      margin-bottom: 22px;
    }

    ::v-deep .v-form::-webkit-scrollbar-track {
      background-color: #FFFFFF !important;
    }

    ::v-deep .v-form::-webkit-scrollbar-thumb {
      background-color: #CBD5E0 !important;
      border-radius: 10px !important;
    }
  }

  .filter-btn,
  .clear-btn {
    ::v-deep .v-btn {
      border-radius: 8px;
      padding: 12px 20px;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 0;
      }
    }
  }
  .status-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .status-count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    color: #505780;
    font-weight: 500;
    font-size: 12px;
    background-color: #E4ECF7;
    border-radius: 18px;
    padding: 2px 10px;
    margin-left: 4px;
  }

  .status-length {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    min-width: 22px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    background-color: $dark-green !important;
    border-radius: 50%;
    margin-left: 4px;
  }
</style>

