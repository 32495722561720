<template>
  <div class="survey-summery">
    <Dialog width="895px" :showCloseIcon="true">
      <template slot="title">
        <div class="title">
          Survey Summary
        </div>
      </template>
      <template slot="body">
        <v-row class="mx-0 my-0">
          <div class="survey-report">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr class="survey-report__row" v-for="(group, index) in surveyGroupsResults" :key="index + '_' + group.id">
                  <div style="padding: 15px 18px; display: grid; grid-template-columns: 0.5fr 1fr; gap: 28px;">
                    <td class="survey-report__cell">
                      <div class="survey-report__cell-wrapper">
                        <div class="group-number">Group {{group?.groupNumber}}</div>
                        <div class="group-name">{{group.name}}</div>
                      </div>
                    </td>
                    <td class="d-flex align-center">
                      <div class="total-percentage"
                           :style="{
                        '--line-top-bottom': getLineTopBottom(index) + '%',
                        '--line-right': getLineRight(index) + 'px',
                        '--line-length': getLineLength(index) + 'px',
                        '--line-angle': getLineAngle(index) + 'deg'
                      }"
                           :class="{
                        'line-one': surveyGroupsResults[index].questions.$values.length === 1,
                        'line-two': surveyGroupsResults[index].questions.$values.length === 2,
                        'line-three': surveyGroupsResults[index].questions.$values.length >= 3
                      }">
                        <div class="total-percentage__wrapper">
                          {{ Math.round(group.totalPercentage) }} <span>%</span>
                          <div v-if="surveyGroupsResults[index].questions.$values.length >= 3" class="line-3"></div>
                        </div>
                      </div>
                      <div class="survey-items">
                        <div class="survey-item" v-for="item in surveyGroupsResults[index].questions.$values" :key="item.id">
                          <div class="d-flex align-center">
                            <div class="survey-value">
                              <div class="circle" :class="{'active-0': item.value === 0, 'active-1': item.value === 1, 'active-2': item.value === 2, 'active-3': item.value === 3}">
                                {{item.value}}
                              </div>
                            </div>
                            <div class="question-number">{{item.number}}.</div>
                            <div class="symptoms">{{item.text}}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
        <v-row class="mx-0 my-0 details" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; padding: 0 35px">
          <v-col class="px-0 py-0">
            <v-card style="box-shadow: none">
              <v-card-title>Five Main Complaints</v-card-title>
              <v-card-text>
                <v-list dense v-if="details && details.complaints?.$values">
                  <v-list-item v-for="(complaint, index) in Array.from({ length: 5 }, (_, i) => details.complaints.$values[i] || '-')" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ index + 1 }}.</span>
                        <span style="margin-left: 30px;">{{ complaint }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="px-0 py-0">
            <v-card style="box-shadow: none">
              <v-card-text>
                <v-list dense >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Are you Nursing/Pregnant?</v-list-item-title>
<!--                      <v-list-item-subtitle>{{ details?.nursingPregnant === true ? 'Yes' : details?.nursingPregnant === false ? 'No' : '-' }}</v-list-item-subtitle>-->
                      <v-list-item-subtitle>{{ details?.nursingPregnant ? 'Yes' : 'No' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Do you suffer from hypertension?</v-list-item-title>
<!--                      <v-list-item-subtitle>{{ details?.sufferFromHypertension === true ? 'Yes' : details?.sufferFromHypertension === false ? 'No' : '-' }}</v-list-item-subtitle>-->
                      <v-list-item-subtitle>{{ details?.sufferFromHypertension ? 'Yes' : 'No' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Weight (lb)</v-list-item-title>
                      <v-list-item-subtitle>{{ details?.weight || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Height (inch)</v-list-item-title>
                      <v-list-item-subtitle>{{ details?.height || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template slot="actions">
        <Button @click.native="downloadSurveySummary"
                class="download-btn">
          <Img :src="getImgWithExt(['icons', 'download-icon', 'svg'])" alt="download" />
          <span class="ml-2">Download</span>
        </Button>
      </template>
    </Dialog>
    <confirm v-if="dialogs.confirm.isOpen" @close="close"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from "@/components/elements/Button.vue";
import confirm from "@/components/layouts/dialogs/confirm-dialog.vue";
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'

export default {
  name: 'modal-survey-summary',
  components: { Dialog, Button, confirm, Img },
  mixins: [Image],
  data: () => ({
    surveyGroupsResults: null,
    details: null,
    lineProperties: {
      3: { topBottom: 35, right: 8, length: 18, angle: 45 },
      4: { topBottom: 30, right: 0, length: 35, angle: 55 },
      5: { topBottom: 30, right: 0, length: 45, angle: 65 },
      6: { topBottom: 32, right: -7, length: 55, angle: 70 },
    },
    fileUrl: null,
    email: null,
    reportSpanish: false,
  }),
  mounted () {
    this.fetchSurveyGroupsResults()
    this.fetchDetails()

    this.email = this.patient.email || null;
    this.reportSpanish = this.patient.spanish;
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('surveys', ['getSurveyGroupsResults', 'getDetailsTestComplaints']),
    ...mapActions('reports', ['gerReportByCode']),

    async fetchSurveyGroupsResults() {
      const result = await this.getSurveyGroupsResults({ surveyID: this.dialog.surveyId })
      this.surveyGroupsResults = result
    },
    async fetchDetails() {
      const result = await this.getDetailsTestComplaints({ surveyID: this.dialog.surveyId })
      this.details = result
    },
    getLineTopBottom(index) {
      const length = Math.min(this.surveyGroupsResults[index].questions.$values.length, 6)
      return this.lineProperties[length]?.topBottom || 0
    },
    getLineRight(index) {
      const length = Math.min(this.surveyGroupsResults[index].questions.$values.length, 6)
      return this.lineProperties[length]?.right || 0
    },
    getLineLength(index) {
      const length = Math.min(this.surveyGroupsResults[index].questions.$values.length, 6)
      return this.lineProperties[length]?.length || 0
    },
    getLineAngle(index) {
      const length = Math.min(this.surveyGroupsResults[index].questions.$values.length, 6)
      return this.lineProperties[length]?.angle || 0
    },
    async downloadSurveySummary() {
      const payload = {
        email: this.email,
        appUserId: this.userProfile.userId,
        reportCode: 'PatientSurveyFormSummary',
        entityId: this.$route.params.id,
        reportSpanish: false
      }
      const res = await this.gerReportByCode(payload)
      await this.parseFile(res)

      const link = document.createElement('a')
      document.body.appendChild(link)
      link.href = this.initialDoc
      link.download = 'Report.pdf'
      link.click()
      document.body.removeChild(link)
    },

    async parseFile(file) {
      if (file) {
        const reader = new FileReader()
        return new Promise((resolve) => {
          reader.onloadend = () => {
            this.fileUrl = URL.createObjectURL(file)
            this.initialDoc = this.fileUrl
            resolve()
          }
          reader.readAsDataURL(file)
        })
      } else {
        this.fileUrl = null
      }
    },
  },
  computed: {
    ...mapState({
      modalSurveySummary: (state) => state.dialogs.modalSurveySummary,
      patient: (state) => state.patients?.patient,
      userProfile: (state) => state.userProfile,
      dialog (state) {
        const modalName = this._.findKey(state.dialogs, ['isOpen', true])
        return state.dialogs[modalName]
      }
    }),
    dialogs () {
      return this.$store.state.dialogs
    },
  }
}
</script>

<style lang="scss" scoped>
.survey-summery {
  ::v-deep .v-card__title {
    padding: 40px 35px 0 !important;

    .image {
      cursor: pointer;
    }
  }

  ::v-deep .v-card__actions {
    padding: 40px 35px;
  }

  .title {
    color: #27272E;
    font-size: 28px !important;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 0 !important;
  }

  .survey-items {
    .survey-item {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  .survey-report {
    width: 100%;
    padding: 15px 35px 40px;

    &__row {
      border-radius: 0;

      &:nth-child(2n) {
        background-color: #F7FAFC;
      }

      .survey-report__cell {
        border-bottom: none !important;
        padding: 0;

        &-wrapper {
          display: flex;
          flex-direction: column;
          border-bottom: none !important;
          padding: 0;
        }

        .group-number {
          color: #718096;
          font-size: 12px;
          font-weight: 500;
          line-height: 2.1;
          letter-spacing: 0;
        }

        .group-name {
          color: #1A202C;
          font-size: 16px;
          font-weight: 500;
          line-height: 2.1;
          letter-spacing: 0;
        }
      }
    }

    .survey-value {
      .circle {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
        margin-right: 25px;

        &.active-1 {
          color: #D4CE15;
          background-color: rgba(212, 206, 21, 0.2);
        }
        &.active-2 {
          color: #FF7A4A;
          background-color: rgba(255, 122, 74, 0.2);
        }
        &.active-3 {
          color: #F23A4E;
          background-color: rgba(242, 58, 78, 0.2);
        }
      }
    }

    .question-number {
      color: #1A202C;
      font-size: 14px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: 0;
    }

    .symptoms {
      color: #1A202C;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0;
      margin-left: 10px;
    }

    .total-percentage {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: #A0AEC0;
        border-radius: 15px;
      }

      &.line-one {
        &::before {
          content: none;
        }
        &::after {
          width: 12px;
          top: 50%;
          right: 9px;
        }
      }

      &.line-two {
        &::before {
          width: 16px;
          top: 40%;
          right: 8px;
          transform: rotate(-30deg);
        }
        &::after {
          width: 16px;
          bottom: 40%;
          right: 8px;
          transform: rotate(30deg);
        }
      }

      &.line-three {
        &::before {
          width: var(--line-length);
          top: var(--line-top-bottom);
          right: var(--line-right);
          transform: rotate(calc(-1 * var(--line-angle)));
        }

        &::after {
          width: var(--line-length);
          bottom: var(--line-top-bottom);
          right: var(--line-right);
          transform: rotate(var((--line-angle)));
        }
      }

      .line-3 {
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: #A0AEC0;
        border-radius: 15px;
        top: 50%;
        left: calc(100% + 8px);
      }

      &__wrapper {
        position: relative;
        flex-shrink: 0;
        margin-right: 30px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EBF2FA;
        color: #505780;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;

        span {
          font-size: 10px;
        }
      }
    }
  }

  .download-btn {
    margin-left: auto;

    ::v-deep .v-btn {
      color: #16192C;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0;
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid #DBE2EE;
      padding: 10px 20px;
    }
  }
}

.details {
  ::v-deep .v-card__title {
    color: #1A202C;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
    padding: 0 !important;
  }

  ::v-deep .v-list {
    padding: 0;

    .v-list-item {
      min-height: 32px;
      padding: 0;

      .v-list-item__title,
      .v-list-item__subtitle {
        color: #1A202C;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0;
      }

      .v-list-item__content {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 10px;
      }
    }
  }
}
</style>
