<template>
  <div class="survey">
    <template v-if="!surveyReadOnly">
      <v-row no-gutters class="d-flex justify-space-between align-center">
        <v-col md5 sm12 style="text-align: center">
          <span class="survey__title" v-if="surveyGroups && selectedGroup !== 'test&complaints'">{{ surveyGroups[selectedGroup].name }}</span>
        </v-col>
        <v-menu offset-y attach>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="quick-entry"
              color="#E4ECF7"
              v-bind="attrs"
              v-on="on"
            >
              Quick Entry
              <Img class="icon ml-2" :src="getImgWithExt(['icons', 'chevron-right', 'svg'])" alt="chevron right" style="width: 10px; height: 10px" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <Button btn-bg="dark-green"
                      :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                      :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                      @click.native="openQuickEntry('quickEntry_1')"
                     >
                <span style="color: white">Quick entry 1</span>
              </Button>

            </v-list-item>
            <v-list-item>
              <Button btn-bg="dark-green"
                      :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                      :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                      @click.native="openQuickEntry('quickEntry_2')"
                   >
                <span style="color: white">Quick entry 2</span>
              </Button>
            </v-list-item>
          </v-list>
        </v-menu>
        <Img @click="openModalConfirmationDeleteSurveyAnswers" :src="getImgWithExt(['icons', 'delete-icon', 'svg'])" alt="delete" style="margin-left: 9px; cursor: pointer" />
      </v-row>
      <v-row no-gutters class="mt-4" v-if="surveyGroups">
        <v-col cols="12">
          <v-slide-group
            v-model="selectedGroup"
            mandatory
            :show-arrows="false"
          >
            <v-slide-item
              v-for="(group, index) in surveyGroups"
              :key="group.id"
              v-slot="{ active, toggle }"
            >
              <!--'green-border': group.isCompleted && group.maxAnswerValue === 0,
                'red-border': group.isCompleted && group.maxAnswerValue > 0,
                'red-bold-border-and-grey-bg': group.isViewed && !group.isCompleted
                :style="!group.isCompleted && group.isViewed && selectedGroup !== index ? 'background-color: #d6d1d1!important' : ''"
                -->
              <v-btn
                class="mx-1 group-button"
                :input-value="active"
                active-class="blue-light"
                depressed
                rounded
                @click.native="onSetViewedGroup(group.id, index)"
                :class="{
                'green-border': group.isCompleted && group.maxAnswerValue > 0,
                // 'red-border': group.isViewed && group.maxAnswerValue === 0,
                'green-bg': (group.isViewed && group.maxAnswerValue > 0) && selectedGroup !== index
              }"
                style="font-weight: 600;font-size: 15px"
                @click="toggle"
              >
              <span :class="{
                // 'green-text': group.isCompleted && group.maxAnswerValue > 0 && selectedGroup !== index,
                // 'red-text': group.isViewed && group.maxAnswerValue === 0 && selectedGroup !== index,
                'white-text': group.isViewed && group.maxAnswerValue > 0 && selectedGroup !== index
              }">
                {{ group?.groupNumber }}
              </span>
                <v-icon
                  v-if="group.isViewed && selectedGroup !== index"
                  :color="group.isViewed && group.maxAnswerValue === 0 ? '#008C89' : group.isViewed && group.maxAnswerValue > 0 ? 'white' : ''"
                >
                  mdi-check
                </v-icon>
              </v-btn>
            </v-slide-item>
            <v-slide-item
              v-slot="{ active, toggle }"
              :value="'test&complaints'"
            >
              <v-btn
                class="mx-1 group-button"
                :input-value="active"
                active-class="blue-light"
                depressed
                rounded
                width="40px"
                style="font-weight: 600;font-size: 15px"
                @click="toggle"
              >
              <span style="text-transform: none"><!--:class="{ 'green-text': selectedGroup !== 'test&complaints' }"-->
                T&C
              </span>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
      <!--<v-row no-gutters class="align-center mt-4 progress-block justify-center"  v-if="surveyGroups">
        <v-col cols="12" sm="7">
          <span class="mobile-percent">{{ Math.ceil(progress) }}%</span>
          <div class="progress">
            <v-progress-linear
              :value="progress"
              color="#008C89"
              height="6"
              :style="{'border-radius': '10px'}"
            >
            </v-progress-linear>
          </div>
        </v-col>
        <v-col cols="12" sm="2" class="desktop-percent">
          <span>{{ Math.ceil(progress)}}%</span>
        </v-col>
        <v-col cols="12" sm="3" class="submit-btn">
        &lt;!&ndash;<Button
            v-if="canApprove || visit.status === 'NeedReview'"
            dark
            height="45"
            width="80%"
            btn-bg="dark-green"
            :style="surveyReadOnly ? 'width: 100%' : 'width: 80%'"
            @click.native="sendSurvey(visit)"
            :styles="{ 'border-radius': '8px' }"
          >
          &lt;!&ndash; :disabled="!canApprove"&ndash;&gt;
            <span>Approve</span>&lt;!&ndash;v-if="canApprove"&ndash;&gt;
&lt;!&ndash;            <span v-else style="color: grey">View only</span>&ndash;&gt;
          </Button>&ndash;&gt;
          <v-icon style="cursor:pointer;" v-if="!surveyReadOnly" @click.native="loadData">mdi-refresh</v-icon>
        </v-col>
      </v-row>-->
      <!--NEW CONTROLS-->
      <v-row class="pl-4 pr-8 pt-2 pb-2">
        <Button
          v-if="prevGroupId !== null"
          btn-bg="white"
          height="45"
          width="100"
          :disabled="!prevGroupIdValue"
          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
          @click.native="selectCurrentGroup(-1)"
        >
          <span style="color: #008C89;font-weight: 600">{{ prevGroupIdValue ? prevGroupIdValue.toUpperCase() : '' }}</span>
          <span style="color: black;font-weight: 600;padding-left: 5px">Prev</span>
        </Button>
        <Button
          btn-bg="blue-light"
          height="45"
          width="50"
          class="mx-2"
          :styles="{ 'border-radius': '8px', 'min-width': '50px' }"
        >
          <span style="color: black">{{ selectedGroupValue ? selectedGroupValue.toUpperCase() : '' }}</span>
        </Button>
        <Button
          v-if="nextGroupIdValue !== null"
          btn-bg="white"
          height="45"
          width="100"
          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
          @click.native="selectCurrentGroup(1)"
        >
          <span style="color: black;font-weight: 600;padding-right: 5px">Next</span>
          <span style="color: #008C89;font-weight: 600">{{ nextGroupIdValue ? nextGroupIdValue.toUpperCase() : '' }}</span>
        </Button>
      </v-row>
      <!--NEW CONTROLS-->
      <v-row no-gutters class="mt-4">
        <div class="survey-table-desktop-mobile" v-if="surveyGroups && selectedGroup !== 'test&complaints'">
          <v-simple-table class="not-readonly-mode">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th>SYMPTOMS</th>
                <th>NONE</th>
                <th>MILD</th>
                <th>MOD</th>
                <th>SEV</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in surveyQuestions" :key="item.id">
                <td class="dynamic-device">
                  <div class="small-device-version">SYMPTOMS</div>
                  <div class="symptoms">
                    <div class="number-of-index" style="width: 50px;margin-left: -50px;margin-bottom: -21px;margin-right: 0px;font-weight: 600;text-align: center">{{item.number}}</div>
                    {{item.text}}
                  </div>
                </td>
                <td class="dynamic-device">
                  <div class="small-device-version mobile-version">NONE</div>
                  <div class="survey-value">
                    <v-radio-group
                      :disabled="surveyReadOnly"
                      v-model="item.value"
                      column
                      @change="changeQuestionAnswer(item)"
                    >
                      <v-radio
                        label="0"
                        :value="0"
                      >
                        <template v-slot:label>
<!--                          <div style="width: 50px;margin-left: -50px;margin-right: 0px;font-weight: 600;text-align: center">{{item.number}}</div>-->
                          <div class="circle" :class="{'active-0': item.value === 0}">
                            0
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </td>
                <td class="dynamic-device">
                  <div class="small-device-version">MILD</div>
                  <div class="survey-value">
                    <v-radio-group
                      :disabled="surveyReadOnly"
                      v-model="item.value"
                      column
                      @change="changeQuestionAnswer(item)"
                    >
                      <v-radio
                        label="1"
                        :value="1"
                      >
                        <template v-slot:label>
                          <div class="circle" :class="{'active-1': item.value === 1}">
                            1
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </td>
                <td class="dynamic-device">
                  <div class="small-device-version">MODE</div>
                  <div class="survey-value">
                    <v-radio-group
                      :disabled="surveyReadOnly"
                      v-model="item.value"
                      column
                      @change="changeQuestionAnswer(item)"
                    >
                      <v-radio
                        label="2"
                        :value="2"
                      >
                        <template v-slot:label>
                          <div class="circle" :class="{'active-2': item.value === 2}">
                            2
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </td>
                <td class="dynamic-device">
                  <div class="small-device-version">SEV</div>
                  <div class="survey-value">
                    <v-radio-group
                      :disabled="surveyReadOnly"
                      v-model="item.value"
                      column
                      @change="changeQuestionAnswer(item)"
                    >
                      <v-radio
                        label="3"
                        :value="3"
                      >
                        <template v-slot:label>
                          <div class="circle" :class="{'active-3': item.value === 3}">
                            3
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="test-complaints-form" v-if="selectedGroup === 'test&complaints'">
          <v-col cols="12">
            <v-form ref="form" lazy-validation class="test-complaints__form mt-3">
              <div class="main-row">
                <div class="test-complaints-column">
                  <v-row class="align-center" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                    <v-col cols="10" class="py-0 pr-0">
                      <div class="label" style="margin-top: 14px;padding-bottom: 19px">5 Main Complaints</div>
                      <!--<Input
                        v-for="(complaint, index) in testComplaints.mainComplaints"
                        :key="index + complaint"
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        :placeholder="index + 1 + '.Complaint'"
                        @input="complaint.value = $event"
                        :value="complaint.value"
                      />-->
                      <!--   :key="index + complaint"                 -->
                      <div v-for="(complaint, index) in testComplaints.complaints" :key="index" class="d-flex">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          background-color="#EDF2F7"
                          type="String"
                          :ref="`complaintInput_${index}`"
                          @keyup.enter="addComplaint(index)"
                          @blur="saveTestsComplaints"
                          :placeholder="index + 1 + '.Complaint'"
                          @input="complaint.value = $event"
                          :value="complaint.value"
                        />
                        <!--<Button
                          v-if="testComplaints.complaints.length > 1"
                          class="mr-1 ml-1 mt-3"
                          btn-bg="dark-green"
                          width="30"
                          height="30"
                          @click.native="deleteComplaint(index)"
                          :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                          darkheight="56">
                          <v-icon color="#ffff">mdi-minus</v-icon>
                        </Button>-->
                      </div>
                    </v-col>
                    <!--<v-col cols="2" class="pl-0 align-self-end mb-5" v-if="testComplaints.complaints.length < 5">
                      <div class="d-flex">
                        <Button
                          btn-bg="dark-green"
                          width="30"
                          height="30"
                          @click.native="addComplaint(testComplaints.complaints.length)"
                          :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                          darkheight="56">
                          <v-icon color="#ffff">mdi-plus</v-icon>
                        </Button>
                      </div>
                    </v-col>-->
                  </v-row>
                  <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                    <v-col cols="12" class="py-0">
                      <div class="label">Blood Pressure</div>
                      <v-radio-group v-model="testComplaints.bloodPressure">
                        <v-radio
                          v-for="blood in 4"
                          :key="blood"
                          :label="blood === 1 ? 'Unknown' : blood === 2 ? 'Below normal for age' : blood === 3 ? 'Normal for age' : 'Above normal for age'"
                          :value="blood"
                          @change="saveTestsComplaints"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                    <v-col cols="10" class="py-0 pr-0">
                      <div class="label">Barnes Thyroid Test</div>
                      <v-row v-for="(barnesThyroid, index) in testComplaints.barnesThyroid" :key="index" class="d-flex">
                        <v-col cols="7" class="pr-0">
                          <Datepicker
                            placeholder="MM/DD/YY"
                            height="56"
                            :value="barnesThyroid.date"
                            @date:update="barnesThyroid.date = $event; saveTestsComplaints()"/>
                        </v-col>
                        <v-col cols="3" class="pr-0">
                          <Input
                            class="custom-input-temperature"
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            placeholder="Temperature"
                            background-color="#EDF2F7"
                            @input="barnesThyroid.value = +$event"
                            :value="+barnesThyroid.value"
                            @blur="saveTestsComplaints"
                          />
                        </v-col>
                        <Button
                          v-if="testComplaints.barnesThyroid.length > 1"
                          class="mr-1 ml-1 mt-6"
                          btn-bg="dark-green"
                          width="30"
                          height="30"
                          @click.native="deleteBarnesThyroid(index)"
                          :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                          darkheight="56">
                          <v-icon color="#ffff">mdi-minus</v-icon>
                        </Button>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="align-self-end mb-5">
                      <div class="d-flex">
                        <Button
                          btn-bg="dark-green"
                          width="30"
                          height="30"
                          @click.native="addBarnesThyroidTest"
                          :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                          darkheight="56">
                          <v-icon color="#ffff">mdi-plus</v-icon>
                        </Button>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                    <v-col cols="10" class="py-0">
                      <v-checkbox
                        color="#008C89"
                        v-model="testComplaints.nursingPregnant"
                        label="Are you Nursing/Pregnant?"
                        @change="saveTestsComplaints"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="10" class="py-0">
                      <v-checkbox
                        color="#008C89"
                        v-model="testComplaints.sufferFromHypertension"
                        label="Do you suffer from hypertension?"
                        @change="saveTestsComplaints"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Weight (lb)
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.weight = +$event"
                        @blur="saveTestsComplaints"
                        :value="+testComplaints.weight"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0 height-selection">
                      <div class="title">Height (ft, in)</div>
                      <v-menu
                        v-model="menuHeight"
                        :close-on-content-click="false"
                        offset-y
                        :nudge-bottom="-250"
                        attach
                      >
                        <template v-slot:activator="{ on }">
                          <Input
                            outlined
                            type="text"
                            height="56"
                            radius="16"
                            tabindex="1"
                            autocomplete="off"
                            background-color="#EDF2F7"
                            :min="0"
                            :value="displayHeight"
                            readonly
                            v-on="on"
                            @click="openSelector"
                          />
                        </template>
                        <v-card class="selector">
                          <v-card-text>
                            <div class="height-selector ">
                              <div class="picker arrows">
                                <div class="swiper-container feet">
                                  <div class="swiper-wrapper">
                                    <div class="swiper-slide"
                                         v-for="(feet, index) in extendedFeetOptions"
                                         :key="'feet-' + index">
                                      {{ feet }}'
                                    </div>
                                  </div>
                                </div>
                                <div class="swiper-container inches">
                                  <div class="swiper-wrapper">
                                    <div class="swiper-slide"
                                         v-for="(inch, index) in extendedInchesOptions"
                                         :key="'inch-' + index">
                                      {{ inch }}"
                                    </div>
                                  </div>
                                </div>
                                <div class="selected-values"></div>
                              </div>
                            </div>
                          </v-card-text>
                          <v-card-actions>
                            <Button btn-bg="white" class="white-btn selector__white-btn" @click.native="cancel">
                              <span style="color: #16192C; font-size: 13px; font-weight: 600; letter-spacing: 0">Cancel</span>
                            </Button>

                            <Button btn-bg="dark-green" class="ml-4 selector__green-btn" @click.native="confirm">
                              <span style="color: #ffffff; font-size: 12px; font-weight: 600; letter-spacing: 0">Ok</span>
                            </Button>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                </div>
                <div class="test-complaints-column">
                  <v-row class="align-center pt-3">
                    <v-col cols="12" class="py-0">
                      <span class="other-test-title">Other tests</span>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Blood pressure: <span class="sub-title">Recumbent</span>
                      </div>
                      <v-row class="align-center">
                        <v-col cols="5">
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            background-color="#EDF2F7"
                            @input="testComplaints.posturalBpRecumbent.systolic = $event"
                            :value="testComplaints.posturalBpRecumbent.systolic"
                            @blur="saveTestsComplaints"
                          />
                        </v-col>
                        <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                          <span class="divider-element">/</span>
                        </v-col>
                        <v-col cols="5">
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            background-color="#EDF2F7"
                            @input="testComplaints.posturalBpRecumbent.diastolic = $event"
                            :value="testComplaints.posturalBpRecumbent.diastolic"
                            @blur="saveTestsComplaints"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Blood pressure: <span class="sub-title">Standing</span>
                      </div>
                      <v-row class="align-center">
                        <v-col cols="5">
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            background-color="#EDF2F7"
                            @input="testComplaints.posturalBpStanding.systolic = $event"
                            :value="testComplaints.posturalBpStanding.systolic"
                            @blur="saveTestsComplaints"
                          />
                        </v-col>
                        <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                          <span class="divider-element">/</span>
                        </v-col>
                        <v-col cols="5">
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            background-color="#EDF2F7"
                            @input="testComplaints.posturalBpStanding.diastolic = $event"
                            :value="testComplaints.posturalBpStanding.diastolic"
                            @blur="saveTestsComplaints"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Pulse: <span class="sub-title">Recumbent</span>
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.posturalPulseRecumbent = $event"
                        :value="testComplaints.posturalPulseRecumbent"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Pulse: <span class="sub-title">Standing</span>
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.posturalPulseStanding = $event"
                        :value="testComplaints.posturalPulseStanding"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        H-C Urine readings - pH
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.hemaCombistixUrinePH = $event"
                        :value="testComplaints.hemaCombistixUrinePH"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Albumin %
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.hemaCombistixUrineAlbuminPct = $event"
                        :value="testComplaints.hemaCombistixUrineAlbuminPct"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Glucose %
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.hemaCombistixUrineGlucosePct = $event"
                        :value="testComplaints.hemaCombistixUrineGlucosePct"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Occult blood
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.occultBlood = $event"
                        :value="testComplaints.occultBlood"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        pH of saliva
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.salivaPH = $event"
                        :value="testComplaints.salivaPH"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                </div>
                <div class="test-complaints-column">
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title" style="margin-top: 35px;">
                        pH of stool
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.stoolPH = $event"
                        :value="testComplaints.stoolPH"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Blood clotting time
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.bloodClottingTime = $event"
                        :value="testComplaints.bloodClottingTime"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0">
                      <div class="title">
                        Hemoglobin
                      </div>
                      <Input
                        outlined
                        height="56"
                        radius="16"
                        tabindex="1"
                        background-color="#EDF2F7"
                        @input="testComplaints.hemoglobin = $event"
                        :value="testComplaints.hemoglobin"
                        @blur="saveTestsComplaints"
                      />
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="10" class="py-0 blood-type">
                      <div class="title">
                        Blood type
                      </div>
                      <v-slide-group
                        v-model="testComplaints.bloodType"
                        :show-arrows="false"
                        @change="saveTestsComplaints"
                      ><!--mandatory-->
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'A+'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        A+
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'A-'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        A-
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'B+'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        B+
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'B-'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        B-
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'AB+'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        AB+
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'AB-'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        AB-
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'O+'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        O+
                      </span>
                          </v-btn>
                        </v-slide-item>
                        <v-slide-item
                          v-slot="{ active, toggle }"
                          :value="'O-'"
                        >
                          <v-btn
                            class="mx-1 group-button"
                            :input-value="active"
                            active-class="green-bg white--text"
                            depressed
                            rounded
                            style="font-weight: 600;font-size: 15px"
                            @click="toggle"
                          >
                      <span>
                        O-
                      </span>
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="12" class="py-0">
                      <v-checkbox
                        v-model="testComplaints.raglanTestPositive"
                        color="#008C89"
                        label="Ragland’s Test is Positive (drop in excess of 9mm)"
                        @change="saveTestsComplaints"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pt-3">
                    <v-col cols="12" class="py-0 mt-3">
                      <p style="font-size: 13px">Except for Blood Pressure, data entered here is for informational purposes only and does not affect the supplement results.</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </v-col>
        </div>
      </v-row>
      <!--NEW CONTROLS-->
      <v-row class="pl-4 pr-8 pt-2 pb-2">
        <Button
          v-if="prevGroupId !== null"
          btn-bg="white"
          height="45"
          width="100"
          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
          @click.native="selectCurrentGroup(-1)"
        >
          <span style="color: #008C89;font-weight: 600">{{ prevGroupIdValue ? prevGroupIdValue.toUpperCase() : '' }}</span>
          <span style="color: black;font-weight: 600;padding-left: 5px">Prev</span>
        </Button>
        <Button
          btn-bg="blue-light"
          height="45"
          width="50"
          class="mx-2"
          :styles="{ 'border-radius': '8px', 'min-width': '50px' }"
        >
          <span style="color: black">{{ selectedGroupValue ? selectedGroupValue.toUpperCase() : '' }}</span>
        </Button>
        <Button
          v-if="nextGroupIdValue !== null"
          btn-bg="white"
          height="45"
          width="100"
          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
          @click.native="selectCurrentGroup(1)"
        >
          <span style="color: black;font-weight: 600;padding-right: 5px">Next</span>
          <span style="color: #008C89;font-weight: 600">{{ nextGroupIdValue ? nextGroupIdValue.toUpperCase() : '' }}</span>
        </Button>
      </v-row>
      <!--NEW CONTROLS-->
    </template>
    <template v-if="surveyReadOnly">
      <v-row no-gutters class="mt-2 readonly-mode">
        <div class="survey-table-desktop-mobile" v-if="surveyGroups">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr v-for="(group, index) in surveyGroups" :key="group.id" class="mobile-version-readonly-mode">
                <td class="dynamic-device" style="position:relative;width: 400px">
                  <div style="width: 300px;height: 200px;position: absolute;top: 0;margin-left: auto;margin-right: auto;left: 0;right: 0;padding: 10px 0;">
                    <div style="color: #8492A6;font-weight: 600;font-size: 14px;">Group {{group?.groupNumber}}</div>
                    <div style="color: #16192C;font-weight: 600;font-size: 20px;">{{group.name}}</div>
                    <div style="color: #61ddae;font-weight: 600;font-size: 20px;text-align: center;bottom: 0;position: absolute;left: 50%;">{{group.total}}</div>
                  </div>
                </td>
                <td class="dynamic-device">
                  <div v-for="item in surveyGroups[index].questions.$values" :key="item.id" style="padding-top: 5px;">
                    <div class="d-flex align-center">
                      <div class="survey-value">
                        <v-radio
                          :label="item.value + ''"
                          :value="item.value + ''"
                        >
                          <template v-slot:label>
                            <div class="circle" :class="{'active-0': item.value === 0, 'active-1': item.value === 1, 'active-2': item.value === 2, 'active-3': item.value === 3}">
                              {{item.value}}
                            </div>
                          </template>
                        </v-radio>
                      </div>
                      <div style="padding-left: 5px">—</div>
                      <div class="question-number">{{item.number}}</div>
                      <div class="symptoms">
                        {{item.text}}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="desktop-version-readonly-mode" v-for="(group, index) in surveyGroups" :key="index + '_' + group.id" style="width: 100%">
                <td class="dynamic-device" style="width: 30%;position: relative;height: 120px;min-height: 120px">
                  <div style="width: fit-content; padding: 10px 5px;position: absolute; top: 0">
                    <div style="color: #8492A6;font-weight: 600;font-size: 14px;">Group {{group?.groupNumber}}</div>
                    <div style="color: #16192C;font-weight: 600;font-size: 20px;">{{group.name}}</div>
                    <div style="color: #008C89;font-weight: 600;font-size: 20px;text-align: center">{{group.total}}</div>
                  </div>
                </td>
                <td class="dynamic-device">
                  <div v-for="item in surveyGroups[index].questions.$values" :key="item.id" style="padding: 5px 5px">
                    <div class="d-flex align-center">
                      <div class="survey-value">
                        <v-radio
                          :label="item.value + ''"
                          :value="item.value + ''"
                        >
                          <template v-slot:label>
                            <div class="circle" :class="{'active-0': item.value === 0, 'active-1': item.value === 1, 'active-2': item.value === 2, 'active-3': item.value === 3}">
                              {{item.value}}
                            </div>
                          </template>
                        </v-radio>
                      </div>
                      <div style="padding-left: 5px">—</div>
                      <div class="question-number">{{item.number}}</div>
                      <div class="symptoms">
                        {{item.text}}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-row>
      <v-row class="mt-6" style="padding: 0 16px">
        <v-card flat style="width: 100%;">
          <v-card-text>
            <v-row no-gutters class="flex-column readonly-mode-tests-complaints">
              <div class="label">Tests and Complaints</div>
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-row no-gutters>
                    <v-checkbox
                      color="#008C89"
                      disabled
                      v-model="testComplaints.nursingPregnant"
                      label="Are you Nursing/Pregnant?"
                    ></v-checkbox>
                  </v-row>
                  <v-row no-gutters>
                    <v-checkbox
                      color="#008C89"
                      disabled
                      v-model="testComplaints.sufferFromHypertension"
                      label="Do you suffer from hypertension?"
                    ></v-checkbox>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 135px">
                      Weight (lb)
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.weight || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 135px">
                      Height (inch)
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.height || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 107px">
                      Blood Pressure
                    </div>
                    <div class="readonly-mode-tests-complaints__value" style="margin-left: 15px;">
                      {{testComplaints.bloodPressure === 1 ? 'Unknown' : testComplaints.bloodPressure === 2 ? 'Below normal for age' : testComplaints.bloodPressure === 3 ? 'Normal for age' : 'Above normal for age'}}
                    </div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div style="color: rgb(22, 25, 44);font-weight: 600;font-size: 16px;">
                      Barnes Thyroid Test
                    </div>
                  </v-row>
                  <template v-if="testComplaints.barnesThyroid.length">
                    <v-row no-gutters class="align-center" v-for="(test, index) in testComplaints.barnesThyroid" :key="index">
                      <div class="title" style="width: 135px">
                        {{$moment(test.date).format('MM/DD/YYYY')}}
                      </div>
                      <div class="readonly-mode-tests-complaints__value">{{test.value}}</div>
                    </v-row>
                  </template>
                  <template v-else>
                    <span>-</span>
                  </template>
                </v-col>
                <v-col cols="4">
                  <v-row no-gutters class="align-center">
                    <div style="color: rgb(22, 25, 44);font-weight: 600;font-size: 16px;">
                      Other tests
                    </div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      Blood pressure: Recumbent
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.bpRecumbent_Systolic || '-'}}/{{testComplaints.bpRecumbent_Diastolic || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      Blood pressure: Standing
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.bpStanding_Systolic || '-'}}/{{testComplaints.bpStanding_Diastolic || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      Pulse: Recumbent
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.posturalPulseRecumbent || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      Pulse: Standing
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.posturalPulseStanding || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      H-C Urine readings - pH
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.hemaCombistixUrinePH || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 210px">
                      Albumin %
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.hemaCombistixUrineAlbuminPct || '-'}}</div>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      Glucose %
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.hemaCombistixUrineGlucosePct || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      Occult blood
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.occultBlood || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      pH of saliva
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.salivaPH || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      pH of stool
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.stoolPH || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      Blood clotting time
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.bloodClottingTime || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      Hemoglobin
                    </div>
                    <div class="readonly-mode-tests-complaints__value">{{testComplaints.hemoglobin || '-'}}</div>
                  </v-row>
                  <v-row no-gutters class="align-center">
                    <div class="title" style="width: 150px">
                      Blood Type
                    </div>
                    <div class="readonly-mode-tests-complaints__value">
                      <v-slide-item
                        v-if="testComplaints.bloodType"
                        :value="testComplaints.bloodType"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 15px;pointer-events: none"
                        >
                              <span>
                                {{testComplaints.bloodType}}
                              </span>
                        </v-btn>
                      </v-slide-item>
                      <span v-else>-</span>
                    </div>
                  </v-row>
                  <v-row no-gutters>
                    <v-checkbox
                      color="#008C89"
                      disabled
                      v-model="testComplaints.raglanTestPositive"
                      label="Regland's Test in Positive (drop in excess of 9mm)"
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </template>
    <confirm v-if="dialogs.confirm.isOpen" />
    <div class="custom-alert">
      <v-alert
        color="#64bb7a"
        dismissible
        type="success"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">Survey was approved. Survey Maestro calculating results based on the Survey data..</span>
      </v-alert>
    </div>
    <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import Datepicker from '@/components/elements/datepicker'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import Image from '@/mixins/Image'
import Swiper from 'swiper'
import Validation from '@/mixins/Validation'

export default {
  name: 'survey',
  mixins: [Image],
  components: { Button, Input, Datepicker, confirm },
  props: {
    isUpdateSurvey: {
      type: Boolean,
      default: false
    },
    isUpdateVisit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedGroup: 0,
    surveyProgress: 0,
    prevGroupIdValue: 0,
    selectedGroupValue: 0,
    nextGroupIdValue: 0,
    tcIsViewed: false,
    testComplaints: {
      complaints: [{ value: '' }],
      bloodPressure: 0,
      barnesThyroid: [
        {
          date: '',
          value: 0
        }
      ],
      posturalBpRecumbent: {
        systolic: '',
        diastolic: ''
      },
      posturalBpStanding: {
        systolic: '',
        diastolic: ''
      },
      posturalPulseRecumbent: '',
      posturalPulseStanding: '',
      hemaCombistixUrinePH: '',
      // exist
      hemaCombistixUrineAlbuminPct: '',
      hemaCombistixUrineGlucosePct: '',
      occultBlood: '',
      salivaPH: '', // salivaPH
      stoolPH: '', // stoolPH
      bloodClottingTime: '',
      bloodType: '',
      hemoglobin: '',
      weight: 0,
      raglanTestPositive: false, // raglanTestPositive
      // exist
      nursingPregnant: false,
      sufferFromHypertension: false
    },
    alertIsOpen: false,
    loading: true,
    menuHeight: false,
    selectedFeet: 6,
    selectedInches: 2,
    feetOptions: Array.from({ length: 9 }, (_, i) => i + 1),
    inchesOptions: Array.from({ length: 12 }, (_, i) => i)
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('results', ['refreshResults', 'getResults']),
    ...mapActions('surveys', ['getSurveyGroups', 'updateSurveyAnswer', 'getSurveyGroupByID', 'approveSurvey', 'getDetailsTestComplaints', 'updateTestComplaints', 'setIsViewedToGroup']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async refreshResultsData() {
      const { data } = await this.getResults({ id: this.$route.params.id })
      const { totalSelectedResultSupplements, surveyScore } = data.record

      await this.$store.dispatch('visits/updateTotalSelectedResultSupplements', {
        totalSelectedResultSupplements
      })

      if (surveyScore >= 0) {
        this.$emit('updateSurveySummary', surveyScore)
      }
    },

    async onSetViewedGroup (groupId, index) {
      this.setIsViewedToGroup({ surveyId: this.visit.surveyId, groupId })

      if ((index >= 0) && (index < this.surveyGroups.length)) {
        this.selectedGroup = index
        this.selectedGroupValue = this.surveyGroups[index]?.groupNumber
        const id = this.surveyGroups[index].id
        this.getSurveyGroupByID({ surveyID: this.visit.surveyId, groupID: '?groupId=' +id }).then(() => {
          if (index === this.surveyGroups.length - 1) {
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            }))?.groupNumber : null
            this.nextGroupIdValue = 'T&C'
            this.surveyProgress = this.surveyGroups[index].progress
          } else if (index === this.surveyGroups.length) {
            this.selectedGroup = 'test&complaints'
            this.selectedGroupValue = 'T&C'
            this.prevGroupIdValue = this.surveyGroups[index - 1]?.groupNumber
            this.nextGroupIdValue = null
          } else {
            this.surveyProgress = this.surveyGroups[index].progress
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            }))?.groupNumber : null
            this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
              return group.id === +this.nextGroupId
            }))?.groupNumber : null
          }
        })
      }
      if (index === this.surveyGroups.length - 1) {
        this.nextGroupIdValue = 'T&C'
      } else if (index === this.surveyGroups.length) {
        this.selectedGroup = 'test&complaints'
        this.selectedGroupValue = 'T&C'
        this.prevGroupIdValue = this.surveyGroups[index - 1]?.groupNumber
        this.nextGroupIdValue = null
      }

      if (this.selectedGroupValue === 'T&C') {
        const res = await this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
        delete res.complaints?.$id
        this.tcIsViewed = res.tcViewed
        this.surveyProgress = res.progress
        const complaints = []
        for (let i = 0; i < 5; i++) {
          complaints.push({ value: res.complaints.$values[i]})
        }
        /*res.complaints = res.complaints.$values.map(item => {
          return { value: item }
        })*/
        delete res.complaints
        this.testComplaints = { ...res, complaints: complaints }
      }
    },
    loadDataQuestionsByGroupID (index) {
      this.getSurveyGroupByID({ surveyID: this.visit.surveyId, groupID: '?groupId=' + this.surveyGroups[index].id })
    },
    async updateUserAnswerInSurvey (surveyGroupID, guestionID, questionValue) {
      const surveyID = this.visit.surveyId
      const data = {
        surveyId: surveyID,
        surveyGroupId: surveyGroupID,
        answers: [
          {
            questionId: guestionID,
            value: questionValue
          }
        ]
      }

      const { totalSurveyAnsweredQuestions, surveyScore, ...rest } = await this.updateSurveyAnswer({ surveyID, ...data })
      if (surveyScore >= 0) {
        this.$emit('updateSurveySummary', surveyScore)
      }
      await this.$store.dispatch('visits/updateTotalSurveyAnsweredQuestions', { totalSurveyAnsweredQuestions })

      await this.refreshResultsData()
    },

    async loadData () {
      this.loading = true
      this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' }).then((result) => {
        if (this.surveyGroups) {
          this.loading = false
        }

        if (result.surveyScore >= 0) {
          this.$emit('updateSurveySummary', result.surveyScore)
        }

        // this.selectedGroup = 0
        this.tcIsViewed = this.surveyData.tcViewed
        this.surveyProgress = this.progress
        // if (this.currentGroupId === 1) {
        //   this.getSurveyGroupByID({ surveyID: this.visit.surveyId, groupID: '?groupId=1' })
        // }
        this.selectedGroup = this.surveyGroups.findIndex((group => {
          return group.id === this.currentGroupId
        }))
        this.selectedGroupValue = this.surveyGroups[this.selectedGroup]?.groupNumber
        this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
          return group.id === +this.prevGroupId
        }))?.groupNumber : null
        this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
          return group.id === +this.nextGroupId
        }))?.groupNumber : null

        if (this.selectedGroup === (this.surveyGroups.length - 1)) {
          this.nextGroupIdValue = 'T&C'
        }

        this.getDetailsTestComplaints({ surveyID: this.visit.surveyId }).then(res => {
          delete res.complaints.$id
          const complaints = []
          for (let i = 0; i < 5; i++) {
            complaints.push({ value: res.complaints.$values[i]})
          }
          /* res.complaints = res.complaints.$values.map(item => {
            return { value: item }
          }) */
          delete res.complaints.$values
          delete res.barnesThyroid.$id
          res.barnesThyroid = res.barnesThyroid.$values.map(item => {
            if (item.date) {
              return item
            } else {
              return { value: item, date: new Date() }
            }
          })
          delete res.barnesThyroid.$values
          this.testComplaints = { ...res, complaints: complaints }
        })
      })
    },
    async sendSurvey (visit) {
      if (visit.status === 'NeedReview' || visit.status === 'SurveySent' || visit.status === 'SurveyDue') {
        const res = await this.approveSurvey({ surveyId: this.visit.surveyId, visitId: this.visit.id })
        this.alertIsOpen = true
        const self = this
        if (res) {
          setTimeout(function () {
            self.$emit('changeTab', { title: 'results', component: 'results' })
          }, 1300)
        }
      }
    },
    async changeQuestionAnswer (item) {
      if (!this.surveyReadOnly) {
        this.updateUserAnswerInSurvey(this.surveyGroups[this.selectedGroup].id, item.id, item.value)
      }
    },
    saveTestsComplaints () {
      const data = { ...this.testComplaints }
      data.complaints = data.complaints.map(item => {
        return item.value
      })
      data.weight = +data.weight
      this.updateTestComplaints({ surveyId: this.visit.surveyId, data })
    },
    deleteComplaint (index) {
      this.testComplaints.complaints.splice(index, 1)
    },
    deleteBarnesThyroid (index) {
      this.testComplaints.barnesThyroid.splice(index, 1)
    },
    addComplaint (index) {
      if (index !== 4) {
        this.$refs[`complaintInput_${index + 1}`][0].$el.querySelector('input[type="String"]').focus()
      } else {
        this.$refs[`complaintInput_${index}`][0].$el.querySelector('input[type="String"]').blur()
      }
      /* if (this.testComplaints.complaints.length < 5) {
        this.testComplaints.complaints.push({ value: '' })
        setTimeout(() => {
          this.$refs[`complaintInput_${index}`][0].$el.querySelector('input[type="String"]').focus()
        }, 300)
      } */
    },
    addBarnesThyroidTest () {
      this.testComplaints.barnesThyroid.push({ date: new Date(), value: 0 })
    },
    async selectCurrentGroup(val) {
      const currentIndex = this.selectedGroupValue === 'T&C' ? this.surveyGroups.length : this.surveyGroups.findIndex(group => {
        return group?.groupNumber === this.selectedGroupValue
      })
      const newCurrentIndex = currentIndex + val

      if ((newCurrentIndex >= 0) && (newCurrentIndex < this.surveyGroups.length)) {
        this.selectedGroup = newCurrentIndex
        this.selectedGroupValue = this.surveyGroups[newCurrentIndex]?.groupNumber
        const id = this.surveyGroups[newCurrentIndex].id
        this.getSurveyGroupByID({ surveyID: this.visit.surveyId, groupID: '?groupId=' +id }).then(() => {
          if (newCurrentIndex === this.surveyGroups.length - 1) {
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            }))?.groupNumber : null
            this.nextGroupIdValue = 'T&C'
            this.surveyProgress = this.surveyGroups[newCurrentIndex].progress
          } else if (newCurrentIndex === this.surveyGroups.length) {
            this.selectedGroup = 'test&complaints'
            this.selectedGroupValue = 'T&C'
            this.prevGroupIdValue = this.surveyGroups[newCurrentIndex - 1]?.groupNumber
            this.nextGroupIdValue = null
          } else {
            this.surveyProgress = this.surveyGroups[newCurrentIndex].progress
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            }))?.groupNumber : null
            this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
              return group.id === +this.nextGroupId
            }))?.groupNumber : null
          }
        })
      }
      if (newCurrentIndex === this.surveyGroups.length - 1) {
        this.nextGroupIdValue = 'T&C'
      } else if (newCurrentIndex === this.surveyGroups.length) {
        this.selectedGroup = 'test&complaints'
        this.selectedGroupValue = 'T&C'
        this.prevGroupIdValue = this.surveyGroups[newCurrentIndex - 1]?.groupNumber
        this.nextGroupIdValue = null
      }

      if (this.selectedGroupValue === 'T&C') {
        const res = await this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
        delete res.complaints?.$id
        this.tcIsViewed = res.tcViewed
        this.surveyProgress = res.progress
        const complaints = []
        for (let i = 0; i < 5; i++) {
          complaints.push({ value: res.complaints.$values[i]})
        }
        /*res.complaints = res.complaints.$values.map(item => {
          return { value: item }
        })*/
        delete res.complaints
        this.testComplaints = { ...res, complaints: complaints }
      }
    },
    openQuickEntry (type) {
      if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
        this.TOGGLE_DIALOG({ name: 'quickEntryDialog', title: 'Quick Entry Form', isOpen: true, data: { type } })
      }
    },
    openModalConfirmationDeleteSurveyAnswers () {
      this.TOGGLE_DIALOG({
        surveyId: this.visit.surveyId,
        name: 'confirm',
        whose: 'survey',
        isOpen: true,
        action: 'surveys/deleteAllQuestionEntries',
        title: 'Confirm delete all entries',
        description: 'Are you sure you want to delete all entries?'
      })
    },
    openSelector() {
      this.menuHeight = true
      this.$nextTick(() => {
        this.initializeSwiper()
      })
    },
    initializeSwiper() {
      const defaults = {
        slidesPerView: 5,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.25,
        freeModeVelocityRatio: 0.25,
        freeModeMinimumVelocity: 0.1,
        mousewheelControl: true,
        mousewheelSensitivity: 0.5,
        loop: true,
        loopAdditionalSlides: 3,
        direction: 'vertical',
        slideToClickedSlide: true,
        centeredSlides: true,
      }
      new Swiper('.swiper-container.feet', {
        ...defaults,
        initialSlide: this.selectedFeet - 1,
        on: {
          slideChange: (swiper) => {
            const currentSlide = swiper.slides[swiper.activeIndex]
            if (currentSlide) {
              this.selectedFeet = currentSlide.innerText.replace("'", "")
            }
          }
        }
      })
      new Swiper('.swiper-container.inches', {
        ...defaults,
        initialSlide: this.selectedInches,
        on: {
          slideChange: (swiper) => {
            const currentSlide = swiper.slides[swiper.activeIndex]
            if (currentSlide) {
              this.selectedInches = currentSlide.innerText.replace('"', "")

              if (this.selectedInches < 10) {
                this.selectedInches = `0${this.selectedInches}`
              }
            }
          }
        }
      })
    },
    confirm() {
      if (this.selectedFeet !== null && this.selectedInches !== null) {
        this.testComplaints.height = parseFloat(`${this.selectedFeet}.${this.selectedInches}`)
      }
      this.menuHeight = false
      this.saveTestsComplaints()
    },
    cancel() {
      this.selectedFeet = null
      this.selectedInches = null
      this.menuHeight = false
    },
  },
  watch: {
    /* selectedGroup (val) {
      if (val !== 'test&complaints') {
        this.loadDataQuestionsByGroupID(val)
      } else {
        this.getDetailsTestComplaints({ surveyID: this.visit.surveyId }).then(res => {
          delete res.complaints.$id
          if (res.complaints.$values.length === 0) {
            res.complaints = [{ value: '' }]
          } else {
            res.complaints = res.complaints.$values.map(item => {
              return { value: item }
            })
          }
          /!* res.complaints = res.complaints.$values.map(item => {
            return { value: item }
          }) *!/
          delete res.complaints.$values
          delete res.barnesThyroid.$id
          res.barnesThyroid = res.barnesThyroid.$values.map(item => {
            if (item.date) {
              return item
            } else {
              return { value: item, date: new Date() }
            }
          })
          delete res.barnesThyroid.$values
          this.testComplaints = { ...res }
        })
      }
    }, */
    'isUpdateSurvey' (val) {
      if (val) {
        this.loadData()
        this.$emit('updateIsUpdateSurvey', false)
      }
    },
    'isUpdateVisit' (val) {
      if (val) {
        this.loadData()
        this.$emit('updateIsUpdateVisit', false)
      }
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits.visit
    }),
    progress () {
      return this.$store.state.surveys.surveyGroups?.progress
    },
    surveyData () {
      return this.$store.state.surveys.surveyGroups
    },
    prevGroupId () {
      return this.$store.state.surveys.surveyGroups?.prevGroupId
    },
    currentGroupId () {
      return this.$store.state.surveys.surveyGroups.currentGroupId
    },
    nextGroupId () {
      return this.$store.state.surveys.surveyGroups.nextGroupId
    },
    surveyGroups () {
      return this.$store.state.surveys.surveyGroups?.groups?.$values
    },
    surveyReadOnly () {
      return this.$store.state.surveys.surveyGroups?.readonly
    },
    dueDate () {
      return this.$store.state.surveys.surveyGroups?.dueDate
    },
    canApprove () {
      return this.$store.state.surveys.surveyGroups?.canApprove
    },
    currentSelectedGroup () {
      return this.surveyGroups[this.selectedGroup]
    },
    surveyQuestions () {
      return this.currentSelectedGroup.questions?.$values
    },
    detailsComplaints () {
      return this.$store.state.surveys.detailsComplaints
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    submitSurveyDisabled () {
      return this.surveyGroups?.every(item => {
        return item.isCompleted === true
      })
    },
    extendedFeetOptions() {
      return [...this.feetOptions, ...this.feetOptions]
    },
    extendedInchesOptions() {
      return [...this.inchesOptions, ...this.inchesOptions]
    },
    displayHeight() {
      if (this.testComplaints.height && typeof this.testComplaints.height === 'number') {
        const [feet, inches] = this.testComplaints.height.toString().split('.')
        return `${feet}' ${inches || 0}"`
      }
      return 0
    },
  }
}
</script>

<style lang="scss" scoped>
.survey {
  padding: 16px!important;
  position: relative;
  min-height: 100px;

  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(213 213 213 / 50%);
    border-radius: 16px;
  }
  .custom-input-temperature {
    ::v-deep .v-input__slot {
      padding: 0 6px!important;
    }
  }
  .mobile-version-readonly-mode {
    display: none;
  }
  &__title {
      font-weight: 600;
      font-size: 22px;
      color: #1A202C;
    }
  &__due-date {
      font-weight: 600; // normal;
      font-size: 14px;
      color: #1A202C; //#A0AEC0;
    }
  &__date {
      font-weight: 600;
      font-size: 14px;
      color: #1A202C;
    }
  ::v-deep .v-btn.group-button {
    width: 40px;
    height: 40px;
    min-width: 40px!important;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background: transparent !important;
  }
  ::v-deep .v-btn.group-button.green-bg {
    background-color: $dark-green !important;
  }
  ::v-deep .v-btn.green-bg {
    background-color: $dark-green !important;
    border: 0px !important;
  }
  ::v-deep .v-btn.blue-light {
    background-color: $blue-light !important;
    border: 0px !important;
  }
  ::v-deep .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
    opacity: 0!important;
  }
  ::v-deep .v-slide-group__content .v-icon {
    font-size: 18px !important;
  }
  .progress-block {
    .mobile-percent {
      display: none;
    }
    .desktop-percent {
      text-align: center;
    }
    .submit-btn {
      //text-align: right;
      margin-left: 10px;
      display: flex;

      ::v-deep .v-btn.v-btn--disabled {
        background-color: #EDF2F7 !important;
        cursor: not-allowed !important;
      }
    }
  }
  .survey-table-head-desktop {
    width: 100%;
    position: relative;
    display: block;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .survey-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    //background-color: #F7FAFC;
    ::v-deep .v-data-table {
      margin-left: 20px;
    }
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        font-weight: 600;
        font-size: 12px;
        color: #CBD5E0;
      }
    }
    .dynamic-device {
      padding: 0 4px !important;
      .small-device-version {
        display: none;
      }
      .symptoms {
        padding-left: 10px;
        color: #1A202C;
        font-weight: 500;
        font-size: 14px;
      }
      .survey-value {
        ::v-deep .v-input--selection-controls__input {
          position: absolute !important;
          visibility: hidden;
        }
        ::v-deep .v-input__control {
          height: 30px !important;
          .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        ::v-deep .v-input--selection-controls {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
        .circle {
          width: 40px;
          height: 40px;
          border: 2px solid #CBD5E0;
          background: rgba(203, 213, 224, 0.2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #CBD5E0;
        }
        .circle.active-0 {
          border-color: $dark-green;
          background-color: $light-dark-green;
          color: #4FAA6E;
        }
        .circle.active-1 {
          border-color: #D4CE15;
          background-color: rgba(212, 206, 21, 0.2);
          color: #D4CE15;
        }
        .circle.active-2 {
          border-color: #FF7A4A;
          background-color: rgba(255, 122, 74, 0.2);
          color: #FF7A4A;
        }
        .circle.active-3 {
          border-color: #F23A4E;
          background-color: rgba(242, 58, 78, 0.2);
          color: #F23A4E;
        }
      }
    }
  }
  .survey-table-mobile {
    display: none;
    width: 100%;
    margin-top: 20px;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  ::v-deep .theme--light.v-data-table {
    background-color: transparent !important;
  }
  ::v-deep .v-data-table.not-readonly-mode {
    .v-data-table__wrapper {
      tr {
        background-color: transparent !important;
      }
      td {
        border-bottom: 0px !important;
      }
      overflow: unset !important;
    }
  }
  .readonly-mode {
    ::v-deep .v-data-table {
      .v-data-table__wrapper {
        table {
          .desktop-version-readonly-mode {
            tr {
              background-color: white !important;
            }
            td {
              //border-bottom: 1px solid #E2E8F0 !important;
              border-bottom: 0.17em dashed #008C89!important;
              border-right: 1px solid #E2E8F0!important;
            }
            td:last-child {
              border-right: unset!important;
            }
            border-radius: 10px!important;
            overflow-x: auto!important;
            overflow-y: hidden!important;
          }
          .mobile-version-readonly-mode {
            tr {
              background-color: transparent !important;
            }
            td {
              border-bottom: unset!important;
              border-right: unset!important;
            }
            tr:last-child > td {
              border-bottom: 1px solid #E2E8F0!important;
            }
          }
          .mobile-version-readonly-mode:last-child {
            tr:last-child > td {
              border-bottom: unset!important;
            }
          }
        }
      }
    }
  }
  .readonly-mode-tests-complaints {
    ::v-deep .v-input {
      margin-top: 0;
      &__control {
        .v-messages {
          display: none;
        }
      }
    }
    .title {
      font-size: 14px!important;
    }
    .label {
      color: rgb(22, 25, 44);
      font-weight: 600;
      font-size: 20px;
    }
    &__value {
      margin-left: 30px;
      color: rgb(22, 25, 44);
      font-weight: 600;
      font-size: 16px;
    }
  }
  .green-text {
    color: $dark-green;
  }
  .red-text {
    color: #F23A4E;
  }
  .white-text {
    color: white;
  }
  .green-bg {
    background: $dark-green !important;
  }
  .blue-light {
    background: $blue-light !important;
  }
  .green-border {
    border: 1px solid $dark-green !important;
  }
  .red-border {
    border: 1px solid #F23A4E !important;
  }
  //.red-bold-border-and-grey-bg {
  //  border: 2px solid #F23A4E !important;
  //}

  .quick-entry {
    border-radius: 6px;
    box-shadow: none;
    padding: 12px 20px;
    color: #101225;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0;
    text-transform: none;
  }

  ::v-deep .v-menu {
    .v-menu__content {
      //box-shadow: 0px 30px 40px 0px rgba(20, 37, 63, 0.08);
      box-shadow: none;
      border-radius: 8px;
      margin-top: 8px;

      .v-list  {
        background-color: transparent;
        box-shadow: none;
        padding: 0;

        .v-list-item {
          min-height: 38px;
          padding: 0;

          .button {
            width: 100%;
          }

          .v-btn {
            width: 100%;
          }
        }
      }
    }
  }

  .test-complaints-form {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    //background-color: #F7FAFC;
    .test-complaints__form {
      .label {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .other-test-title {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        color: #425466;
        .sub-title {
          font-weight: 600;
          font-size: 17px;
        }
      }
      .blood-type {
        ::v-deep .v-slide-group__content {
          display: unset !important;
          flex: unset !important;
          white-space: pre-wrap !important;
          .v-btn {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
          }
        }
      }

      .mobile-version-readonly-mode {
        display: none;
      }

      @media (max-width: 540px) {
        .desktop-version-readonly-mode {
          display: none;
        }
        .mobile-version-readonly-mode {
          display: block;
        }
      }

      @media screen and (min-width: 1000px) {
        .main-row {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          justify-content: space-between;
          .test-complaints-column {
            width: calc(92% / 3);
          }
          .test-complaints-column:first-child {
            .row:last-child {
              border-bottom: unset!important;
            }
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .main-row {
          .test-complaints-column:nth-child(1) {
            .row:first-child {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 610px){
    .col-sm-7 {
      flex: 0 0 58.6666666667% !important;
      max-width: 58.6666666667% !important;
    }
  }
  @media (min-width: 610px) {
    .col-sm-2 {
      flex: 0 0 8.3333333333% !important;
      max-width: 8.3333333333% !important;
    }
  }
  @media (min-width: 610px) {
    .col-sm-3 {
      flex: 0 0 28.3333333333% !important;
      max-width: 28.3333333333% !important;
    }
  }
  @media (max-width: 610px) {
    .progress-block {
      .submit-btn {
        text-align: center!important;
        margin-left: 0 !important;
        margin-top: 6px;
      }
    }
  }
  @media (max-width: 610px) {
    .desktop-percent {
      display: none !important;
    }
    .progress-block {
      .mobile-percent {
        display: block;
        text-align: center;
      }
    }
  }

  @media (max-width: 590px) {
    .survey-table-desktop-mobile {
      .header {
        display: none !important;
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .small-device-version {
          display: block;
        }
        .symptoms .number-of-index {
          display: none;
        }
      }
    }
  }
  @media (min-width: 530px) {
    .survey-table-desktop-mobile {
      .header {
        th {
          width: 60px;
        }
        //th:nth-child(4) {
        //  width: 100px;
        //}
        th:nth-child(1) {
          width: 55%;
        }
        th:last-child {
          width: auto;
        }
      }
    }
  }
  @media (min-width: 1497px) {
    ::v-deep .v-menu {
      .v-menu__content {
        margin-top: 2px;
      }
    }
  }

  .custom-alert {
    position: fixed;
    top: 50%;
    width: 400px;
    margin-left: -200px;
    left: 50%;
  }
}

.height-selection {
  ::v-deep .v-text-field__details {
    display: none;
  }

  .v-menu__content {
    min-width: 220px !important;
    border-radius: 16px !important;
    box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.08) !important;

    .v-text-field__details {
      display: none;
    }
  }

  ::v-deep .v-card.selector {
    width: 220px;
    max-width: 220px;
    border-radius: 16px;
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.1);
    padding: 15px;

    .v-card__text {
      padding: 0;
    }

    .height-selector {
      display: flex;
      justify-content: space-between;
    }

    .v-card__actions {
      justify-content: flex-end;
      padding: 15px 0 0;

      .selector__green-btn {
        .v-btn {
          border-radius: 8px;
          min-width: 57px;
        }
      }
      .selector__white-btn {
        .v-btn {
          border: 1px solid #EDF2F7;
          border-radius: 8px;
          padding: 0 18px;
        }
      }
    }
  }
}

.picker {
  position: relative;
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.swiper-container {
  width: 80px;
  height: 215px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #718096;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.swiper-slide-active {
  color: #425466;
  font-size: 20px;
  line-height: 50px;
}

.selected-values  {
  height: 54px;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  transform: translateY(-50%);
  font-size: 20px;
  border-radius: 18px;
  background-color: #EBF2FA;
}
</style>
