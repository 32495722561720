var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"patient-list",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"patient-list__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[(_vm.data)?_vm._l((_vm.data.$values),function(item){return _c('v-row',{key:item.id,staticClass:"main-row",on:{"click":function($event){return _vm.redirectByType(item.entryId, item.visitId, _vm.type, item)}}},[_c('div',{staticClass:"avatar-full-name"},[_c('div',{staticClass:"avatar"},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.type === 'follow-up' ? item.patient.profileAvatar : item.profileAvatar}})],1)],1),_c('div',{staticClass:"full-name"},[_vm._v(" "+_vm._s(_vm.type === 'follow-up' ? item.patient.firstName : item.title)+" "+_vm._s(_vm.type === 'follow-up' ? item.patient.lastName : '')+" ")])]),(_vm.type === 'follow-up')?_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.$moment(item.visitDate).format('MM/DD/YYYY')))]):_vm._e(),(_vm.type === 'follow-up')?_c('div',{staticClass:"status-block"},[_c('v-tooltip',{attrs:{"left":"","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.statusCode !== 'None'),expression:"item.statusCode !== 'None'"}],on:{"click":function($event){$event.stopPropagation();return _vm.openVisit(item)}}},'div',attrs,false),on),[(_vm.getStatusCodeName[item.statusCode])?_c('status',{style:({
                              backgroundColor: _vm.getStatusCodeName[item.statusCode]?.background || '#EDF2F7',
                              color: _vm.getStatusCodeName[item.statusCode]?.text || '#A0AEC0'
                            })},[_vm._v(" "+_vm._s(_vm.getStatusCodeName[item.statusCode].name)+" ")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticStyle:{"display":"block","max-width":"300px"}},[_vm._v(_vm._s(_vm.getStatusCodeName[item.statusCode].description(item.statusDays)))])])],1):_vm._e(),(_vm.type === 'recently')?_c('div',{staticClass:"status-block"},[_c('v-tooltip',{attrs:{"left":"","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.statusCode !== 'None'),expression:"item.statusCode !== 'None'"}],on:{"click":function($event){$event.stopPropagation();return _vm.openVisit(item)}}},'div',attrs,false),on),[(_vm.getStatusCodeName[item.statusCode])?_c('status',{style:({
                              backgroundColor: _vm.getStatusCodeName[item.statusCode]?.background || '#EDF2F7',
                              color: _vm.getStatusCodeName[item.statusCode]?.text || '#A0AEC0'
                            })},[_vm._v(" "+_vm._s(_vm.getStatusCodeName[item.statusCode].name)+" ")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticStyle:{"display":"block","max-width":"300px"}},[_vm._v(_vm._s(_vm.getStatusCodeName[item.statusCode].description(item.statusDays)))])])],1):_vm._e(),(_vm.type === 'recently')?_c('div',{staticClass:"action"},[_c('v-icon',{attrs:{"color":"#A0AEC0"}},[_vm._v("mdi-eye-outline")])],1):_vm._e()])}):_c('div',{staticClass:"loading d-flex justify-center px-2 py-2 align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#008C89"}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }