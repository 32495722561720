<template>
  <div class="home-dashboard mt-3 px-3">
<!--    <img src="../assets/images/back-dp.jpg" style="width: -webkit-fill-available;">-->
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="6">
            <patient-list :type="'follow-up'" :title="'Follow Up Reminders'" :data="patientsFollowUpReminders"/>
          </v-col>
          <v-col cols="6">
            <patient-list :type="'recently'" :title="'Recently Viewed'" :data="patientsRecentlyViewed"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <chart-wrapper v-if="patientsChartData" :type="'line'" :data="mockLineChartData" :title="'Patients Chart'" :period="periodLine" @updateChartLineBy="updateLineChartData"/>
          </v-col>
          <v-col cols="6">
            <chart-wrapper v-if="mockDoughnutChartData" :type="'doughnut'" :data="mockDoughnutChartData" :title="'Top 10 Supplement'" :period="periodDoughnut" @updateChartLineBy="updateLineChartData"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
<!--        <span style="font-weight: 500;font-size: 12px;color: #8492A6;">{{$moment(new Date()).format('MM/DD/YYYY')}}</span>-->
        <simple-info style="width: 285px;" :title="'New Patient'">
          <template v-slot:image>
            <Img :src="getImgWithExt(['icons', 'dashboard-doctor', 'svg'])" />
          </template>
          <template v-slot:new-patient>
            <Button
              style="display: flex;height: 100px;flex-direction: column;justify-content: flex-end;"
              :styles="{'border-radius': '8px'}"
              @click.native.stop="addNewPatient"
              btn-bg="dark-green">
              <v-icon color="white">mdi-plus</v-icon>
              <span style="color: white">Patient</span>
            </Button>
          </template>
        </simple-info>
        <simple-info style="width: 285px;" v-if="subscriptionFeatures.productName.includes('Patient Portal')" :title="'Open Chats'" :code="'OpenChats'">
          <template v-slot:image>
            <Img :src="getImgWithExt(['icons', 'dashboard-open-chats', 'svg'])" />
          </template>
          <template v-slot:info>
            <div>{{openChatsCount}}</div>
          </template>
        </simple-info>
        <simple-info style="width: 285px;" v-if="subscriptionFeatures.productName.includes('Survey Only') || subscriptionFeatures.productName.includes('Patient Portal')" :title="'Open Surveys'" :code="'OpenSurveys'">
          <template v-slot:image>
            <Img :src="getImgWithExt(['icons', 'dashboard-open-surveys', 'svg'])" />
          </template>
          <template v-slot:info>
            <div>{{openSurveysCount}}</div>
          </template>
        </simple-info>
        <simple-info style="width: 285px;" :title="'Protocols Ending'" :code="'ProtocolEndings'">
          <template v-slot:image>
            <Img :src="getImgWithExt(['icons', 'dashboard-protocol-ending', 'svg'])" />
          </template>
          <template v-slot:info>
            <div>{{protocolEndingsCount}}</div>
          </template>
        </simple-info>
        <simple-info v-if="subscriptionFeatures.features.$values.includes('Leads')" style="width: 285px;" :title="'Leads'" :code="'Leads'">
          <template v-slot:image>
            <Img :src="getImgWithExt(['icons', 'dashboard-protocol-ending', 'svg'])" />
          </template>
          <template v-slot:info>
            <div>{{leadsCount}}</div>
          </template>
        </simple-info>
      </v-col>
    </v-row>
    <add-patient v-if="dialogs.addPatient.isOpen || dialogs.editPatient.isOpen" @showPopup="showPopup" />
    <add-visit v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
        <router-link v-if="newPatientLink" :to="`patients/${newPatientLink.id}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span></router-link>
      </v-alert>
    </div>
    <confirm v-if="dialogs.confirm.isOpen" />
  </div>
</template>

<script>
import PatientList from '@/views/home-dashboard/patient-list'
import ChartWrapper from '@/views/home-dashboard/chart-wrapper'
import SimpleInfo from '@/views/home-dashboard/simple-info'
import Button from '@/components/elements/Button'
import addPatient from '@/components/layouts/dialogs/add-patient'
import addVisit from '@/components/layouts/dialogs/add-visit'
import { mapActions, mapMutations, mapState } from 'vuex'
import Image from '@/mixins/Image'
import confirm from '@/components/layouts/dialogs/confirm-dialog'

export default {
  name: 'dashboard',
  mixins: [Image],
  components: { SimpleInfo, ChartWrapper, PatientList, Button, addPatient, addVisit, confirm },
  data: () => ({
    popupMessage: null,
    newPatientLink: null,
    alertIsOpen: false,
    periodLine: 'Week',
    periodDoughnut: 'Week',
    staticBgColor: [
      '#F16063',
      '#008C89',
      '#668BFF',
      '#e6ee76',
      '#F7936F',
      '#FF92AE',
      '#51AC70',
      'rgba(80,103,160,0.5)',
      'rgba(0, 140, 137, 0.16)',
      'rgba(255, 237, 227, 0.7)'
    ]
  }),
  mounted () {
    document.title = 'Dashboard - Maestro Practitioner Portal'
    this.appSettings()
    sessionStorage.setItem('selectedPage', this.$route.path)
    this.getPatientsFollowUpReminders()
    this.getPatientsRecentlyViewed()
    this.getPatientsChartLineData({ period: this.periodLine[0].toLowerCase() + this.periodLine.slice(1) })
    this.getTopSupplementsChartData({ period: this.periodDoughnut[0].toLowerCase() + this.periodDoughnut.slice(1) })
    this.getOpenChatsCount()
    this.getOpenSurveysCount()
    this.getProtocolEndingsCount()
    this.getLeadsCount()
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions(['appSettings']),
    ...mapActions('organizations', ['getPatientsFollowUpReminders', 'getPatientsRecentlyViewed', 'getOpenChatsCount', 'getOpenSurveysCount', 'getProtocolEndingsCount', 'getLeadsCount']),
    ...mapActions('charts', ['getPatientsChartLineData', 'getTopSupplementsChartData']),
    addNewPatient () {
      this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: true, title: 'Add Patient' })
    },
    updateLineChartData ({ value, type }) {
      if (type === 'line') {
        this.periodLine = value[0].toUpperCase() + value.slice(1)
        this.getPatientsChartLineData({ period: value[0].toLowerCase() + value.slice(1) })
      }
      if (type === 'doughnut') {
        this.periodDoughnut = value[0].toUpperCase() + value.slice(1)
        this.getTopSupplementsChartData({ period: value[0].toLowerCase() + value.slice(1) })
      }
    },
    showPopup (res) {
      this.alertIsOpen = true
      switch (res.type) {
        case 'add-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was created  successfully. You can see at `
          this.newPatientLink = res.data
          break
        case 'edit-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
          break
        case 'add-visit':
          if (res.data.record.redirectToVisit) {
            this.alertIsOpen = false
            sessionStorage.setItem('selectedPage', `/patients/visit/${res.data.record.id}`)
            this.$router.push({ name: 'View Evaluation', params: { id: res.data.record.id } })
          } else {
            this.popupMessage = 'Evaluation date was added. The invitation was sent to Patient Email.'
          }
          this.getPatientsFollowUpReminders()
          break
        case 'invite-patient':
          this.popupMessage = 'The Invitiation Email was sent to the Patient. If Patient will confirm the registration he will be able to join the Patient Portal and fill in the Survey(s).'
      }
      if (res.type === 'edit-patient') {
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
      }
      if (res.type === 'invite-patient') {
        this.TOGGLE_DIALOG({ name: 'invitePatient', isOpen: false })
      }
    }
    /* randomBetween (min, max) {
      return min + Math.floor(Math.random() * (max - min + 1))
    } */
  },
  computed: {
    dialogs () {
      return this.$store.state.dialogs
    },
    ...mapState({
      patientsFollowUpReminders: state => state.organizations.patientsFollowUpReminders?.data,
      patientsRecentlyViewed: state => state.organizations.patientsRecentlyViewed?.data,
      patientsChartData: state => state.charts.patientsChartLine?.record?.$values,
      topSupplementsChartData: state => state.charts.topSupplementsChart?.record?.$values,
      openChatsCount: state => state.organizations.openChatsCount?.record,
      openSurveysCount: state => state.organizations.openSurveysCount?.record,
      protocolEndingsCount: state => state.organizations.protocolEndingsCount?.record,
      leadsCount: state => state.organizations.leadsCount?.record,
      subscriptionFeatures: state => state.subscription
    }),
    mockLineChartData () {
      return {
        labels: this.patientsChartData.map(label => this.periodLine === 'Year' ? this.$moment(label.key).format('MMM') : this.periodLine === 'Month' ? this.$moment(label.key).format('MM/ddd/YYYY') : this.$moment(label.key).format('ddd')), // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        datasets: [{
          label: `Patients/${this.periodLine}`,
          data: this.patientsChartData.map(item => item.value), // [1, 23, 56, 12, 12, 45, 19, 8, 12, 7, 3, 8, 12, 120, 4, 7]
          backgroundColor: 'rgba(75, 192, 192, 0.1)',
          borderColor: '#008C89',
          borderWidth: 2
        }]
      }
    },
    mockDoughnutChartData () {
      return {
        labels: this.topSupplementsChartData?.map(label => label.key.supplementName),
        datasets: [{
          label: `Top Supplement/${this.periodDoughnut}`,
          data: this.topSupplementsChartData?.map(item => item.value), // [1, 23, 56, 12, 12, 45, 19, 8, 12, 7, 3, 8, 12, 120, 4, 7]
          backgroundColor: this.staticBgColor, // this.topSupplementsChartData.map(item => `rgb(${this.randomBetween(0, 255)}, ${this.randomBetween(0, 255)}, ${this.randomBetween(0, 255)})`),
          // borderColor: this.topSupplementsChartData.map(item => `rgb(${this.randomBetween(0, 255)}, ${this.randomBetween(0, 255)}, ${this.randomBetween(0, 255)})`),
          borderWidth: 2
        }]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-dashboard {
  background: #F7FAFC;
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
  ::v-deep .v-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    border-radius: 8px;
  }
  ::v-deep .add-patient .v-dialog__container .v-card {
    min-height: 670px;
  }
  ::v-deep .add-patient .v-dialog {
    overflow-y: unset!important;
    .v-card {
      border-radius: 16px !important;
      overflow-y: auto;
    }
  }
}
</style>
