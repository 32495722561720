import Vue from 'vue'

const state = () => ({
  visit: null,
  model: null,
  visitPreview: null,
  visitPreviewInvitation: null
})

const getters = {}

const actions = {
  async addVisit ({ commit }, params) {
    const result = await Vue.$http.post('/Visits/Add', { ...params })
    if (result?.status === 200) {
      // commit('TOGGLE_DIALOG', { name: 'addVisit', isOpen: false }, { root: true })
      console.log(`Respons: The visit was added; Status: ${result?.status}`)
      return result?.data
    }
  },
  async getVisit ({ commit }, { id, orderMethod, calcMethod }) {
    const additionalUrl = orderMethod ? `?orderMethodCode=${orderMethod}` : ''
    const maxAdditionalUrl = calcMethod ? `${additionalUrl}&calcMethodCode=${calcMethod}` : additionalUrl
    const result = await Vue.$http.get(`/Visits/${id}/Get${maxAdditionalUrl}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['visit', result.data?.record])
      return true
    } else if (result?.status === 400) {
      return result
    }
  },
  async visitProcess ({ commit }, params) {
    const { data } = params
    const result = await Vue.$http.put('/Visits/process', { ...data })
    if (result?.status === 200) {
      return { res: true, data: result?.data.record }
    } else if (result?.status === 400) {
      return result
    }
  },
  async endProtocol ({ commit }, visitId) {
    const result = await Vue.$http.get(`/Visits/${visitId}/endprotocol-dialog`)
    if (result?.status === 200) {
      return result?.data.record
    } else if (result?.status === 400) {
      return result
    }
  },
  async concludeDialog ({ commit }, visitId) {
    const result = await Vue.$http.get(`/Visits/${visitId}/conclude-dialog`)
    if (result?.status === 200) {
      return result?.data.record
    } else if (result?.status === 400) {
      return result
    }
  },
  async deleteVisit ({ commit }, id) {
    const result = await Vue.$http.delete(`/Visits/${id}`)
    if (result?.status === 204) {
      return true
    }
  },
  async getModel ({ commit }, id) {
    const result = await Vue.$http.get(`/Visits/GetModel?PatientId=${id}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['model', result.data?.record])
      return result.data?.record
    }
  },
  async updateReschedule ({ commit }, params) {
    const { id, data } = params
    const result = await Vue.$http.put(`/Visits/${id}/Reschedule`, { ...data })
    if (result?.status === 200) {
      commit('SET_DATA', ['model', result.data?.record])
      return result.data?.record
    }
  },
  async updateNotes ({ commit }, params) {
    const { apiUrl, field, dataForSend, visitId } = params
    const result = await Vue.$http.put(`Visits/${visitId}/${apiUrl}`, { visitId, ...dataForSend })
    if (result?.status === 204 || result?.status === 200) {
      if (field) {
        commit('UPDATE_DATA_POINT', { key: field, value: dataForSend.notes || dataForSend.complaints })
        return result?.data.record
      }
    }
  },
  async updateDaysSupply ({ commit }, params) {
    const { id, data } = params
    const result = await Vue.$http.put(`/Visits/${id}/DaysSupply`, { ...data })
    if (result?.status === 200) {
      return true
    }
  },
  async getVisitPreview ({ commit }, params) {
    const result = await Vue.$http.post('/Visits/Preview', { ...params })
    if (result?.status === 200) {
      // commit('TOGGLE_DIALOG', { name: 'addVisit', isOpen: false }, { root: true })
      console.log(`Respons: The visit was added; Status: ${result?.status}`)
      commit('SET_DATA', ['visitPreview', result.data?.record])
      return result?.data
    }
  },
  async getVisitInvitation ({ commit }, params) {
    const result = await Vue.$http.post('/Visits/PreviewInvitation', { ...params })
    if (result?.status === 200) {
      // commit('TOGGLE_DIALOG', { name: 'addVisit', isOpen: false }, { root: true })
      console.log(`Respons: The visit was added; Status: ${result?.status}`)
      commit('SET_DATA', ['visitPreviewInvitation', result.data?.record])
      return result?.data
    }
  },
  async markForReview({ commit }, visitId) {
    const result = await Vue.$http.put(`/visits/${visitId}/mark-for-review`)
    if (result?.status === 200) {
      window.location.reload()
      return true
    }
  },
  updateTotalSurveyAnsweredQuestions({ commit }, data) {
    commit('UPDATE_VISIT_TOTAL_SURVEY_ANSWERED_QUESTIONS', data.totalSurveyAnsweredQuestions)
  },
  updateTotalBloodEnteredValues({ commit }, data) {
    commit('UPDATE_VISIT_TOTAL_BLOOD_ENTERED_VALUES', data.totalBloodEnteredValues)
  },
  updateTotalInvoices({ commit }, data) {
    commit('UPDATE_VISIT_TOTAL_INVOICES', data.totalInvoices)
  },
  updateTotalSelectedResultSupplements({ commit }, data) {
    console.log({here: data});
    commit('UPDATE_VISIT_TOTAL_SELECTED_RESULT_SUPPLEMENTS', data.totalSelectedResultSupplements)
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_DATA_POINT: (state, payload) => {
    if (payload.key === 'surveyComplaints') {
      state.visit[payload.key].$values = payload.value
    } else {
      state.visit[payload.key] = payload.value
    }
  },
  UPDATE_SUPPLY_DAYS: (state, payload) => {
    state.visit.supplyDays = payload
  },
  UPDATE_VISIT_TOTAL_SURVEY_ANSWERED_QUESTIONS: (state, payload) => {
    state.visit.totalSurveyAnsweredQuestions = payload
  },
  UPDATE_VISIT_TOTAL_BLOOD_ENTERED_VALUES: (state, payload) => {
    state.visit.totalBloodEnteredValues = payload
  },
  UPDATE_VISIT_TOTAL_INVOICES: (state, payload) => {
    state.visit.totalInvoices = payload
  },
  UPDATE_VISIT_TOTAL_SELECTED_RESULT_SUPPLEMENTS: (state, payload) => {
    state.visit.totalSelectedResultSupplements = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
