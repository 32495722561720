<template>
  <div class="view-patient">
    <v-row v-if="patient" class="row no-margin px-4 py-2">
      <v-col class="patient-details px-0 py-0">
        <card :isDisabled="!patient.active" class="custom-card">
          <template v-slot:body>
            <v-row class="no-margin">
              <v-col cols="auto" class="d-flex align-center py-0 pr-3 pl-0">
                <v-avatar size="46" v-if="patient.profileAvatar">
                  <v-img :src="patient.profileAvatar" alt="profile-avatar" />
                </v-avatar>
              </v-col>

              <v-col class="px-0 py-0">
                <div class="d-flex align-center mb-1">
                  <div v-show="patient.status !== 'None'" style="width: fit-content">
                    <status v-if="getStatusCodeName[patient.status]"
                            :style="{
                                backgroundColor: getStatusCodeName[patient.status]?.background || '#EDF2F7',
                                color: getStatusCodeName[patient.status]?.text || '#A0AEC0'
                              }"
                    >
                      {{getStatusCodeName[patient.status].name || '-'}}
                    </status>
                  </div>
                  <v-tooltip top v-if="patient.status === 'InProtocol' || patient.status === 'ProtocolEnding'">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="in-protocol d-flex justify-center ml-auto">
                        <Link :to="patient.lastVisitDate ? `patients/visit/${patient.lastVisitId}` : ''">
                          <Img v-if="patient.status === 'InProtocol'" :src="getImgWithExt(['icons', 'protocol-green-icon', 'svg'])" style="width: 24px" />
                          <Img v-if="patient.status === 'ProtocolEnding'" :src="getImgWithExt(['icons', 'protocol-pink-icon', 'svg'])" style="width: 24px" />
                        </Link>
                      </div>
                    </template>
                    <span>Patient is in protocol of the evaluation</span>
                  </v-tooltip>
                </div>

                <h4 class="full-name">{{patient.firstName}} {{patient.lastName}}</h4>
                <span class="joined">Joined {{$moment(patient.createdOn).format('MMM D, YYYY')}}</span>
              </v-col>
            </v-row>
            <v-row class="no-margin">
              <v-col class="d-flex align-center px-0 pt-7 pb-1">
                <v-btn class="custom-edit-btn" outlined @click.native="edit">
                  <Img :src="getImgWithExt(['icons', 'edit', 'svg'])" />
                  Edit
                </v-btn>
                <div class="additional-info-icons">
                  <v-tooltip top v-if="patient.vegetarian">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="width: 25px">
                        <Img :src="getImgWithExt(['icons', 'vegan_icon', 'svg'])" />
                      </div>
                    </template>
                    <span>Patient is Vegetarian</span>
                  </v-tooltip>
                  <v-tooltip top v-if="patient.glutenFree" >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="width: 25px">
                        <Img :src="getImgWithExt(['icons', 'gluten_icon', 'svg'])" />
                      </div>
                    </template>
                    <span>Patient is Gluten Free Dietary</span>
                  </v-tooltip>
                  <v-tooltip top v-if="patient.registerPortal" >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="width: 25px">
                        <Img :src="getImgWithExt(['icons', 'portal_regestreted_icon', 'svg'])" />
                      </div>
                    </template>
                    <span>Patient is registered on Patient Portal</span>
                  </v-tooltip>
                  <v-tooltip top v-if="patient.lead" >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="mt-1" style="width: 25px">
                        <v-icon color="primary">mdi-human-queue</v-icon>
                      </div>
                    </template>
                    <span>Patient has leads</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <v-row class="no-margin">
              <v-col cols="5" class="px-0 pt-0 pb-3">
                <div class="label">Gender</div>
                <div class="text">{{patient.sex}}</div>
              </v-col>
              <v-col cols="7" class="px-0 pt-0 pb-3">
                <div class="label">Birthday</div>
                <div class="text">
                  {{ patient.birthDate ? $moment(patient.birthDate).format('MM/DD/YYYY') + ' (' + $moment().diff(patient.birthDate, 'years') + ')' : '-' }}
                </div>
              </v-col>
              <v-col cols="5" class="px-0 py-0">
                <div class="label">Weight</div>
                <div class="text">{{patient.weight}} lb</div>
              </v-col>
              <v-col cols="7" class="px-0 py-0">
                <div class="label">Height</div>
                <div class="text">{{ formatHeight(patient.height) }}</div>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <v-row class="contact-info no-margin">
              <v-col cols="12" class="contact-item px-0 py-0">
                <div class="d-flex">
                  <Img :src="getImgWithExt(['icons', 'phone-icon', 'svg'])" />
                  <div>
                    <div class="label">Phone Numbers</div>
                    <div v-if="patient.cellPhone" class="text">{{patient.cellPhone}}
                      <span style="color: #718096; font-size: 14px; font-weight: 400"> Cell</span>
                      <span class="copy-icon" @click="copyToClipboard(patient.cellPhone, 'phone')">
                        <Img :src="getImgWithExt(['icons', 'copy-icon', 'svg'])" alt="copy" />
                      </span>
                    </div>
                    <div v-if="patient.homePhone" class="text">{{patient.homePhone}}
                      <span style="color: #718096; font-size: 14px; font-weight: 400"> Home</span>
                      <span class="copy-icon" @click="copyToClipboard(patient.homePhone, 'phone')">
                        <Img :src="getImgWithExt(['icons', 'copy-icon', 'svg'])" alt="copy" />
                      </span>
                    </div>
                    <div v-if="patient.workPhone" class="text">{{patient.workPhone}}
                      <span style="color: #718096; font-size: 14px; font-weight: 400"> Work</span>
                      <span class="copy-icon" @click="copyToClipboard(patient.workPhone, 'phone')">
                        <Img :src="getImgWithExt(['icons', 'copy-icon', 'svg'])" alt="copy" />
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="contact-item px-0 py-0">
                <div class="d-flex">
                  <Img :src="getImgWithExt(['icons', 'email-icon', 'svg'])" alt="email" />
                  <div>
                    <div class="label">E-mail</div>
                    <a v-if="patient.email" class="text email" :href="`mailto:${patient.email}`">{{ patient.email }}</a>
                    <span v-if="patient.email" class="copy-icon" @click="copyToClipboard(patient.email, 'email')">
                      <Img :src="getImgWithExt(['icons', 'copy-icon', 'svg'])" alt="copy" />
                    </span>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="contact-item px-0 py-0">
                <div class="d-flex">
                  <Img :src="getImgWithExt(['icons', 'address-icon', 'svg'])" />
                  <div>
                    <div class="label">Address</div>
                    <div class="text">
                      <p v-if="patient.line1" class="my-0">{{ patient.line1 }}</p>
                      <p v-if="patient.city" class="my-0">{{ patient.city }}</p>
                      <p class="my-0"><span v-if="patient.state">{{ patient.state }}</span><span v-if="patient.zip">, {{ patient.zip }}</span></p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-3 mx-0">
              <v-col cols="12" class="px-0 py-0">
                <div class="d-flex align-items-center">
                  <div class="patient-link" v-if="patient.portal && patient.registerPortal">
                    <v-row class="no-margin">
                      <v-col class="d-flex align-center px-0 py-0">
                        <Img :src="getImgWithExt(['icons', 'login-icon', 'svg'])" alt="login icon" />
                        <a style="text-decoration: none; color: #008c89; font-weight: 600; cursor: pointer;" :href="`${patient.domain}/redirect/${patient.id}/${patient.token}`" target="_blank">Login to Patient Portal</a>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="patient-link" v-if="!patient.registerPortal">
                    <v-row class="no-margin">
                      <v-col class="d-flex align-center px-0 py-0">
                        <v-icon style="color: #008C89; margin-right: 10px;">mdi-account-plus</v-icon>
                        <span style="text-decoration: none; color: #008c89; font-weight: 600; cursor: pointer;" @click="handleInviteClick()">Invite Patient to join Portal</span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
        </card>
      </v-col>
      <v-col class="px-0 py-0" style="position: relative;">
        <Button
          v-if="!patient.nextVisitDate"
          class="evaluation-btn"
          btn-bg="dark-green"
          @click.native.stop="TOGGLE_DIALOG({ id: $route.params.id, name: 'addVisit', title:'Add new evaluation date', isOpen: true, data: patient })"
        >
          <v-icon style="color:#FFFFFF;">mdi-plus</v-icon>
          <span style="color: #FFFFFF">Evaluation</span>
        </Button>
        <card :isDisabled="!patient.active" v-if="patient.visits" class="tab-container pl-4">
          <template v-slot:body>
            <Tabs :items="tabs"
                  @update:tab="tab"
                  :tab-index="tabIndex"
                  left
                  :isDisabled="!patient.active"
                  :withoutPadding="tabs[componentInd].component === 'chat' || tabs[componentInd].component === 'reports' || tabs[componentInd].component === 'history' ? 'chat-without-padding' : ''"
                  :id="tabs[componentInd].component === 'chat' ? 'chat' : undefined">
              <template v-slot:sliderColor>
                <v-tabs-slider></v-tabs-slider>
              </template>
              <template v-slot:[`${tabs[componentInd].component}`]>
                <component :patient="patient" :is="tabs[componentInd].component"></component>
              </template>
            </Tabs>
          </template>
        </card>
      </v-col>
    </v-row>

    <add-patient v-if="dialogs.editPatient.isOpen" @showPopup="showPopup"/>
    <add-visit :query="query" v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
    <confirm :query="query" v-if="dialogs.confirm.isOpen" />
    <invite-patient v-if="dialogs.invitePatient.isOpen"  @showPopup="showPopup" />

    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
        <router-link v-if="newPatientLink" :to="`patients/${newPatientLink.id}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span>
        </router-link>
        <router-link v-if="redirectToNewVisit" :to="`visit/${redirectToNewVisit}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">View Evaluation</span>
        </router-link>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Button from '@/components/elements/Button'
import Link from '@/components/elements/Link'
import card from '@/components/layouts/card'
import addPatient from '@/components/layouts/dialogs/add-patient'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import invitePatient from '@/components/layouts/dialogs/invite-patient'
import status from '@/components/layouts/status'
import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
import Tabs from '@/components/layouts/Tabs'
import history from '@/views/patients/history'
import chat from '@/views/patients/chat'
// import simulate from '@/views/patients/simulate'
import events from '@/views/patients/events'
import reports from '@/views/patients/reports'
import Image from '@/mixins/Image'
import addVisit from '@/components/layouts/dialogs/add-visit'
import Img from '@/components/elements/Img'

const SURVEY_TAB_INDEX = 0
const RESULT_TAB_INDEX = 2

export default {
  name: 'view-patient',
  mixins: [Pagination, Image, Mapping],
  components: { Button, Link, addPatient, card, Tabs, history, chat, reports, addVisit, events, confirm, invitePatient, Img, status }, // simulate
  data: () => ({
    patient: null,
    componentInd: 0,
    tabIndex: 0,
    popupMessage: null,
    newPatientLink: null,
    redirectToNewVisit: null,
    alertIsOpen: false,
    availableTabs: [
      { title: 'evaluations', component: 'history', countKey: 'cntVisits' },
      { title: 'survey forms', component: 'reports', countKey: 'cntForms' },
      { title: 'events', component: 'events', countKey: 'cntEvents' }
    ]
  }),
  async mounted () {
    const { id } = this.$route?.params
    if (id) {
      this.addUrlParam({ includeVisitsHistory: true })
      await this.getPatient({ id, urlParams: this.query })

      this.patient = Object.assign({}, this.$store.state.patients.patient)

      document.title = `Patient ${this.patient.lastName} ${this.patient.firstName} - Maestro Practitioner Portal`
      if (this.patient.registerPortal && this.subscriptionFeatures.features.$values.includes('Portal')) {
        this.availableTabs.splice(1, 0, {
          title: 'chat',
          component: 'chat',
          countKey: 'cntChats'
        })
      }
      this.SET_PAGE_SETTINGS({ title: `${this.patient.firstName} ${this.patient.lastName}` })
    }

    if (this.$route.hash === '#chat') {
      this.$nextTick(() => {
        this.scrollToChat()
      })
    }
  },
  destroyed () {
    this.SET_PAGE_SETTINGS(null)
    this.alertIsOpen = false
  },
  methods: {
    ...mapActions('patients', ['getPatient']),
    ...mapMutations(['SET_PAGE_SETTINGS']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async showPopup (res) {
      this.alertIsOpen = true
      this.newPatientLink = res.type === 'add-patient' ? res.data : null
      if (res.type === 'edit-patient') {
        this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
        this.addUrlParam({ includeVisitsHistory: true })
        await this.getPatient({ id: this.$route.params.id, urlParams: this.query })
        this.patient = Object.assign({}, this.$store.state.patients.patient)
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
        if (res.data.record.redirectToVisit) {
          this.alertIsOpen = false
          sessionStorage.setItem('selectedPage', `/patients/visit/${res.data.record.id}`)
          this.$router.push({ name: 'View Evaluation', params: { id: res.data.record.id } })
        } else {
          this.popupMessage = `Evaluation date was added. Now you can `
          this.redirectToNewVisit = res.data.record.id
          await this.getPatient({ id: res.data.record.patientId, urlParams: this.query })
        }
      }
      if (res.type === 'invite-patient') {
        this.TOGGLE_DIALOG({ name: 'invitePatient', isOpen: false })
      }
    },
    edit ($event) {
      $event.preventDefault()
      const { id } = this.$route?.params
      if (id) {
        this.TOGGLE_DIALOG({ id, name: 'editPatient', isOpen: true, title: 'Edit patient' })
      }
    },
    navToResult (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: RESULT_TAB_INDEX } })
    },
    navToSurvey (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: SURVEY_TAB_INDEX } })
    },
    tab ($event) {
      this.componentInd = $event

      if (this.$route.hash === '#chat' && this.componentInd !== this.getTabIndexFromHash())
        window.history.pushState("", document.title, window.location.pathname + window.location.search)
    },
    sendEmailToPatient (email) {
      if (email) {
        window.open('mailto:' + email)
      }
    },
    openReport (path, id) {
      this.$router.push({ path: `${path}/${id}`, query: { tabIndex: 2 } })
    },
    getTabIndexFromHash() {
      if (this.$route.hash === '#chat')
        return this.tabs.findIndex(elem => elem.component === 'chat') || 0
      return 0
    },
    scrollToChat() {
      const chatElement = document.getElementById('chat')
      if (chatElement) {
        setTimeout(() => {
          const chatRect = chatElement.getBoundingClientRect()
          const chatOffset = chatRect.top + window.scrollY
          const additionalOffset = 100

          window.scrollTo({
            top: chatOffset - additionalOffset,
            behavior: 'smooth'
          })
        }, 500)
      }
    },
    copyToClipboard(text, type) {
      navigator.clipboard.writeText(text).then(() => {
        this.alertIsOpen = true;
        this.popupMessage = type === 'email'
          ? 'Email address copied to clipboard!'
          : 'Phone number copied to clipboard!';
        setTimeout(() => {
          this.alertIsOpen = false;
        }, 5000);
      }).catch(err => {
        console.error('Error copying text: ', err);
      });
    },
    handleInviteClick() {
      if (!this.patient.email) {
        this.TOGGLE_DIALOG({
          id: this.patient.id,
          name: 'confirm',
          isOpen: true,
          data: this.patient,
          whose: 'patientInviteToPortal',
          title: 'Alert Invitation Patient to Portal',
          description: `This Patient does not have email. Please edit the Patient and setup email. Would you like to Edit Patient?`
        })
      } else {
        this.TOGGLE_DIALOG({
          id: this.patient.id,
          name: 'invitePatient',
          isOpen: true,
          title: 'Invite Patient to join Portal',
          data: this.patient
        })
      }
    },
    formatHeight(height) {
      if (!height) return ''
      const [feet, inches] = height.toString().split('.')
      return inches
        ? `${feet} ft ${inches} in`
        : `${feet} ft`
    },
  },
  computed: {
    ...mapState({
      // patient: state => state.patients.patient,
      subscriptionFeatures: state => state.subscription,
    }),
    dialogs () {
      return this.$store.state.dialogs
    },
    tabs () {
      return this.availableTabs.map(tab => ({
        ...tab,
        count: this.patient ? this.patient[tab.countKey] || null : null
      }));
    }
  }
}
</script>

<style lang="scss" scoped>
.no-margin {
  margin: 0 !important;
}

.v-btn {
  letter-spacing: 0;
}

.patient-details {
  flex: 0 0 300px;

  .status ::v-deep .v-chip {
    cursor: default !important;
  }
}

::v-deep .custom-card {
  .v-card {
    padding: 18px 20px 34px;
  }
}

::v-deep .tab-container {
  .v-card {
    padding: 13px 10px;
    border-radius: 0 !important;
    box-shadow: none;
    background-color: transparent;
  }
}

::v-deep .confirm-dialog .v-dialog {
  .v-card {
    background-color: #FFFFFF;
  }
}

.view-patient {
  background-color: #F7FAFC;

  .status {
    ::v-deep .v-chip {
      padding: 3px 8px;
    }
  }

  .full-name {
    color: #16192C;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 5px;
  }

  .joined {
    color: #718096;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .v-divider {
    border-color: #EDF2F7;
    border-width: 2px 0 0 0;
    border-radius: 16px;
  }

  .label {
    color: #718096;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    padding-bottom: 2px;
  }

  .text {
    color: #425466;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;

    &.email {
      word-break: break-all;
      color: #668BFF;
    }
  }
}

.custom-edit-btn {
  color: #A0AEC0;
  text-transform: none;
  padding: 12px 20px;

  .v-btn__content {
    .image {
      padding-right: 8px;
    }
  }
}

.additional-info-icons {
  display: flex;
  gap: 13px;
  padding-left: 16px;
}

.contact-info {
  .contact-item {
    margin-bottom: 20px;

    .image {
      margin-right: 10px;
      margin-top: 2px;
    }
  }
}

.patient-link {
  margin-left: 26px;

  .image {
    margin-right: 10px;
    margin-top: 1px;
  }
}

::v-deep .v-tabs-slider-wrapper {
  height: 1px !important;

  .v-tabs-slider {
    color: #4FAA6E;
    border-radius: 20px;
  }
}

.evaluation-btn {
  position: absolute;
  top: 6px;
  right: 10px;
  border-radius: 8px;
  z-index: 1;
  //z-index: 999;
}

.copy-icon {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 16px;
  margin: 0 0 0 6px !important;
  cursor: pointer;

  .image {
    width: 100%;
  }
}

.patients .custom-alert {
  max-width: 300px;
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 999;
}

@media (max-width: 1050px) {
  .evaluation-btn {
    ::v-deep .v-btn {
      width: 36px;
      min-width: 36px;
      height: 36px;
      border-radius: 50%;

      .v-btn__content span{
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .patient-details {
    flex: 0 0 270px;
  }
}
</style>
