<template>
  <div class="status">
    <v-chip :style="styles" color="#73D995">
      <slot>{{text}}</slot>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'status',
  props: {
    radius: String
  },
  computed: {
    styles () {
      return {
        'border-radius': this.radius + 'px',
        background: 'rgba(115, 217, 149, 0.2)',
        color: '#4FAA6E'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .status {
    ::v-deep .v-chip {
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
</style>
