 <template>
  <div class="add-visit">
    <Dialog width="640" :canCloseWithEsc="true" :showCloseIcon="true">
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" v-if="model && dialog.data" lazy-validation id="addVisit" class="px-3">
          <div class="main-row row-top">
            <div class="section__avatar" style="position: relative;">
              <v-avatar size="46">
                <v-img :src="dialog.data.profileAvatar"></v-img>
              </v-avatar>
              <Img
                v-if="model.isPatientPortalExists"
                :src="getImgWithExt(['icons', 'check-fill', 'svg'])"
                style="position: absolute; right: -6px; top: 27px; z-index: 9; cursor: pointer;"
              />
            </div>
            <div class="section__info">
              <div class="section__info-top">
                <div class="name">{{ dialog.data.firstName + ' ' + dialog.data.lastName }}</div>
                <status v-if="getStatusCodeName[dialog.data.status]"
                        :style="{
                                backgroundColor: getStatusCodeName[dialog.data.status]?.background || '#EDF2F7',
                                color: getStatusCodeName[dialog.data.status]?.text || '#A0AEC0'
                              }"
                >
                  {{getStatusCodeName[dialog.data.status].name || '-'}}
                </status>
              </div>
              <div class="joined">Joined {{ dialog.data.createdOn ? $moment(dialog.data.createdOn).format('MM/DD/YYYY') : '-' }}</div>
              <div class="email">{{ dialog.data.email }}</div>
            </div>
          </div>
          <v-row class="ma-0">
            <v-col class="eval-label">Select the Evaluation Type</v-col>
          </v-row>
          <v-row class="mx-0 mt-4 mb-0">
            <v-col class="px-0 py-0">
              <div class="d-flex align-center justify-space-between evaluation-type">
                <v-radio-group class="mx-0 my-0" v-model="selectedOption" row>
                  <v-radio
                    color="#008C89"
                    label="Survey Evaluation"
                    value="surveyEvaluation"
                  />
                  <v-radio
                    color="#008C89"
                    label="Non-Survey Evaluation"
                    value="nonSurvey"
                  />
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-0 mt-8 mb-0 row-grid">
            <v-col class="px-0 py-0 mb-1 input-container" style="position:relative;">
              <div class="label">Evaluation Date</div>
              <PhoneInput
                radius="8"
                :isAutofocus="isAutofocus"
                :mask="'##/##/####'"
                :placeholder="'03/05/2022'"
                :value="form.customDate"
                @input="form.date=$event"
              />
              <p @click="openDatePicker">
                <Img
                  :src="getImgWithExt(['icons', 'date', 'svg'])"
                  style="position: absolute; right: 17px; top: 38px; z-index: 9; cursor: pointer;"
                />
              </p>
              <datepicker
                ref="dateInput"
                style="position: absolute; top: 34px; right: 0; opacity: 0; width: 50%"
                :min="nowDate"
                @date:update="form.date = $moment($event).format('MM/DD/YYYY'), form.customDate=form.date"
                :value="model.date ? $moment.utc(model.date).format('YYYY-MM-DD') : ''"
                :rules="notEmtpy"
              />
            </v-col>
            <v-col class="px-0 py-0">
              <div class="label">Practitioners</div>
              <Select outlined
                      v-if="doctors"
                      radius="8"
                      itemText="name"
                      itemValue="id"
                      :items="doctors"
                      :value="selectedDoctor"
                      @change="form.doctorId=$event.id"
              />
            </v-col>
          </v-row>
          <v-row v-if="!model.isPatientPortalExists && subscriptionFeatures.features.$values.includes('Portal')" class="no-have-portal mx-0 mt-8 mb-0">
            <v-checkbox
              color="#008C89"
              v-model="form.sendInvite"
              label="Invite to the portal"/>
            <p>If you want the Patient to complete surveys online or just want them to have Portal Access.</p>
          </v-row>
          <v-row class="mx-0 mt-5 mb-0 flex-column email-send">
            <span class="text" :class="{mb: !showDontSendEmail}">
              {{
                !dialog.data.email
                  ? 'Invitation email will not be sent (no email)'
                  : ((dialog.data.email && (selectedOption === 'surveyEvaluation' || (!model.isPatientPortalExists && form.sendInvite))) && !form.notSendEmail)
                    ? 'Invitation email will be sent'
                    : 'Invitation email will not be sent'
              }}
            </span>
            <v-checkbox
              v-if="showDontSendEmail"
              class="mt-2 pt-0"
              color="#008C89"
              v-model="form.notSendEmail"
              label="Don`t send Email"/>
          </v-row>
          <v-row class="mx-0 mt-10 mb-0">
            <v-col class="pa-0 d-flex dialog-buttons">
              <Button btn-bg="white" class="white-btn mr-auto" @click.native="TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })">
                <span style="color: #16192C">Cancel</span>
              </Button>
              <div class="d-flex justify-end">
                <div v-if="(showDontSendEmail && !form.notSendEmail)" class="d-flex align-center preview-btn" @click="openPreviewEmail({ isPortal: false })">
                  <Img :src="getImgWithExt(['icons', 'preview-icon', 'svg'])" alt="preview" />
                  <span>Preview</span>
                </div>
                <Button btn-bg="dark-green" @click.native="save">
                  <span style="color: white">Schedule</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
    </Dialog>
    <preview-email-template v-if="dialog.name === 'previewEmailTemplateDialog'" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Select from '@/components/elements/Select'
import PhoneInput from '@/components/elements/phone-input'
import datepicker from '@/components/elements/datepicker'
import previewEmailTemplate from '@/components/layouts/dialogs/preview-email-template'
import Mapping from '@/mixins/Mapping'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'
import Validation from '@/mixins/Validation'
import status from '@/components/layouts/status.vue'

export default {
  name: 'add-visit',
  mixins: [ Validation, Image, Mapping ],
  props: {
    query: {
      type: Object
    }
  },
  components: {
    Dialog,
    Button,
    Select,
    datepicker,
    Img,
    PhoneInput,
    previewEmailTemplate,
    status
  },
  data: () => ({
    time: null,
    form: {
      customDate: null,
      date: null,
      doctorId: null,
      patientId: null,
      addSurveyForm: null,
      addSurveyFormCode: null,
      printSurveyForm: null,
      printSurveyFormCode: null,
      nonSurvey: false,
      notSendEmail: false,
      sendInvite: false,
      surveyEvaluation: true,
    },
    selectedOption: 'surveyEvaluation',
    nowDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10),
    loading: false,
    isAutofocus: true
  }),
  async mounted () {
    this.isAutofocus = false
    this.getDoctors()
    const { id } = this.dialog
    const res = await this.getModel(id)
    this.form.date = res.date
    this.form.customDate = this.$moment(res.date).format('MM/DD/YYYY')
    this.form.addSurveyFormCode = res.surveyFormCode
    console.log('dialog.data', this.dialog.data)
    this.form.sendInvite = !this.model.isPatientPortalExists && this.subscriptionFeatures.features.$values.includes('Portal') ? true : false
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', ['getDoctors', 'getPatients']),
    ...mapActions('visits', ['addVisit', 'getModel', 'getVisit']),
    async save () {
      this.loading = true
      if (this.validate()) {
        const form = Object.assign({}, this.form)
        delete form.customDate

        form.patientId = this.model.patientId
        form.doctorId = this.model.doctorId
        form.date = this.$moment(this.form.date).format('YYYY-MM-DDTHH:mm:ss')
        form.addSurveyForm = this.model.addSurveyForm
        form.addSurveyFormCode = this.form.addSurveyFormCode
        form.printSurveyForm = this.model.printSurveyForm
        form.printSurveyFormCode = this.form.printSurveyFormCode
        form.nonSurvey = this.selectedOption === 'nonSurvey'
        form.sendInvite = !this.model.isPatientPortalExists && this.subscriptionFeatures.features.$values.includes('Portal') ? form.sendInvite : false
        delete form.surveyEvaluation
        const res = await this.addVisit(form)
        this.$emit('showPopup', { type: 'add-visit', data: res })
        if (this.query) {
          this.query.PageSize = 15;
          await this.getPatients(this.query);
        }

        // this.query.PageSize = 15
        // await this.getPatients(this.query)
      }
      this.loading = false
    },
    openPreviewEmail ({ isPortal }) {
      const updatedData = {
        ...this.dialog.data,
        ...this.form,
        nonSurvey: this.selectedOption === 'nonSurvey',
        notSendEmail: this.form.notSendEmail,
        sendInvite: this.form.sendInvite,
      };

      this.TOGGLE_DIALOG({
        name: 'previewEmailTemplateDialog',
        isOpen: true,
        title: '',
        data: updatedData,
        isPortal
      })
    },
    openDatePicker() {
      this.$refs.dateInput.menu = true
    },
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    // settingsForms: state => state.settings.patients.forms.$values.map(item => { return { name: item.name.replace('Survey', '').trim(), code: item.code } }),
    doctors: state => state.patients?.doctors?.$values,
    model: state => state.visits?.model,
    subscriptionFeatures: state => state.subscription,
    showDontSendEmail() {
      return this.dialog.data.email && (this.selectedOption === 'surveyEvaluation' || (!this.model.isPatientPortalExists && this.form.sendInvite))
    },
    selectedDoctor() {
      if (!this.doctors || !this.model) return null
      return this.doctors.find(doctor => doctor.id === this.model.doctorId) || null
    },
  }),
}
</script>

<style lang="scss" scoped>
  .label {
    font-size: 16px;
    font-weight: 500;
    color: #2D3748;
    line-height: 1.1;
    padding-bottom: 9px;
  }

  .add-visit {
    .main-row {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      &.row-top {
        border-bottom: 1px solid #CBD5E0;
        padding-bottom: 26px;
        margin-bottom: 18px;
      }

      .section__info {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        &-top {
          display: flex;
          align-items: center;

          status {
            font-size: 12px;
            font-weight: 500;
            border-radius: 8px;
            padding: 1px 10px;
            margin-left: 10px;
          }
        }

        .name {
          font-size: 18px;
          font-weight: 600;
          color: #16192C;
          padding-right: 10px;
        }

        .joined {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.7;
          color: #718096;
        }

        .email {
          font-size: 14px;
          font-weight: 600;
          color: #668BFF;
        }
      }
    }

    .eval-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #27272E;
      padding: 0;
    }

    ::v-deep .v-input--radio-group {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      .v-input__control {
        .v-input__slot {
          margin: 0;

          .v-radio {
            min-width: 196px;
            padding: 10px 15px 10px 10px;
            margin-right: 24px;

            &.v-item--active {
              background-color: rgba(0, 140, 137, 0.1);
              border-radius: 8px;
            }

            .v-input--selection-controls__input {
              width: 20px;
              height: 20px;
              margin-right: 10px;

              &:hover {
                .v-input--selection-controls__ripple:before {
                  background: transparent;
                  transform: none;
                }
              }

              .v-icon {
                color: #C9CED6;
              }

              .v-input--selection-controls__ripple {
                height: 20px;
                width: 20px;
                left: 0;
                top: 0;
                margin: 0;
              }
            }

            label {
              color: #2D3748;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.6;
            }
          }
        }
        .v-messages {
          display: none;
        }
      }
    }

    .row-grid {
      display: grid;
      grid-template-columns: 196px 1fr;
      column-gap: 24px;
    }

    .phone-input {
      ::v-deep {
        .input {
          .v-input {
            font-size: 15px !important;

            .v-input__control {
              .v-input__slot {
                background-color: #EDF2F7 !important;
                border: none !important;
                box-shadow: none;

                input {
                  color: #2D3748 !important;
                }
              }
            }
          }
        }
      }
    }
    .select {
      ::v-deep {
        .v-input {
          box-shadow: none;
          border: none;
          background-color: #EDF2F7;

          .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
            color: #B5B5BD !important;
            background: transparent;
          }

          .v-select__selection {
            color: #2D3748;
          }
        }

        .v-menu__content {
          margin-top: 7px;

          .v-select-list .v-list-item--active {
            background-color: #EBF2FA !important;

            .v-list-item__title {
              color: #425466 !important;
            }
          }
        }
      }
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4A5568;

      &.mb {
        margin-bottom: 32px;
      }
    }

    .email-send {
      ::v-deep {
        .v-input {
          .v-input__control {
            .v-input__slot {
              margin-bottom: 0;

              .v-label {
                color: #4A5568;
                font-size: 14px;
                font-weight: 400;

              }

              i {
                color: #C9CED6;
              }

              .v-input--selection-controls__input {
                &:hover {
                  .v-input--selection-controls__ripple:before {
                    background: transparent !important;
                    transform: none;
                  }
                }
              }
            }

            .v-messages {
              display: none !important;
            }
          }
        }
      }
    }

    .dialog-buttons {
      .button {
        ::v-deep .v-btn {
          height: 56px;
          border-radius: 16px;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0;
          padding: 21px 23px;
        }

        &.white-btn {
          ::v-deep .v-btn {
            border: 1px solid #EDF2F7;
          }
        }
      }
    }

    .preview-btn {
      color: #16192C;
      font-weight: 600;
      font-size: 14px;
      border-radius: 16px;
      border: 1px solid #EDF2F7;
      cursor: pointer;
      padding: 0 23px;
      margin-right: 20px;

      .image {
        width: 16px;
        height: 16px;
      }

      span {
        padding-left: 10px;
      }
    }

    .input-container p {
      margin: 0;
    }

    ::v-deep .v-dialog__container {
      .v-card {
        &__title {
          color: #27272E;
          padding: 36px 36px 37px !important;
          position: relative;

          .v-image {
            position: absolute;
            right: 36px;
            top: 43px;
            cursor: pointer;
          }
        }

        &__text {
          .v-input {
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }

    .loading {
      min-height: 100px;
      position: absolute;
      height: 85%;
      width: 98%;
      top: 84px;
      left: 6px;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
    }

    .no-have-portal {
      background-color: #EBF2FA;
      border-radius: 16px;
      padding: 20px 25px 25px;
      margin-top: 21px;

      ::v-deep .v-label {
        color: #2D3748;
        font-weight: 600;
      }

      ::v-deep .v-input {
        margin: 0;
        padding: 0;

        &__control {
          .v-input__slot {
            margin-bottom: 0;

            i {
              color: #C9CED6;
            }

            .v-input--selection-controls__input {
              &:hover {
                .v-input--selection-controls__ripple:before {
                  background: transparent !important;
                  transform: none;
                }
              }
            }
          }

          .v-messages {
            display: none;
          }
        }
      }

      p {
        color: #2D3748;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
        margin: 14px 0 0;
      }
    }

    .preview-hide {
      visibility: hidden;
      pointer-events: none;
    }
  }
</style>
