<template>
  <div class="events-log">
    <div class="main-row justify-center">
      <h2 style="text-align: start; width: 100%; color: #27272E; font-size: 16px; font-weight: 500; line-height: 24px; margin-bottom: 20px">Events Log</h2>
      <Table
        :headers="eventsHeaders"
        @update:option="actionTableData"
        hideDefaultFooter>

        <template v-slot:body>
          <template v-for="item in eventsItems">
            <tr :key="item.id">
              <td class="d-flex">
                <div style="padding-top: 1px">
                  {{ $moment(item.createdOn).format('MM/DD/YYYY') }}
                </div>
              </td>

              <td>
                <v-timeline dense>
                  <v-timeline-item :color="getColor(item.color)">
                    <div class="d-flex">
                      <template v-if="item.message.includes('by')">
                        <p class="mark"><span :class="getColor(item.color)">{{ item.message.split('by')[0] }} </span> by {{ item.message.split('by')[1] }}</p>
                      </template>
                      <template v-else>
                        <p class="mark"><span :class="getColor(item.color)">{{ item.message }}</span></p>
                      </template>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </td>
            </tr>
          </template>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Table from '@/components/elements/Table'
import Mapping from '@/mixins/Mapping'
import Pagination from '@/mixins/Pagination'

export default {
  name: 'events',
  mixins: [ Mapping, Pagination ],
  components: { Table },
  data: () => ({
    eventsItems: null
  }),
  async mounted () {
    const { id } = this.$route.params
    const res = await this.getAuditEvents({ id })
    if (res) {
      this.eventsItems = this.auditEvents
      this.setupPagination(this.auditEvents)
    }
  },
  methods: {
    ...mapActions('patients', ['getAuditEvents']),
    actionTableData ($event) {
      if ($event.sortBy.length) {
        this.items = this.items.sort((a, b) => {
          const key = $event.sortBy[0]
          if (key === 'name') {
            if ($event.sortDesc[0]) {
              if (a[key].toLowerCase() < b[key].toLowerCase()) {
                return -1
              }
            } else {
              if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return 1
              }
            }
          } else {
            return $event.sortDesc[0] ? b[key] - a[key] : a[key] - b[key]
          }
        })
      }
    },
    getColor(colorCode) {
      switch (colorCode) {
        case 0:
          return 'create';
        case 1:
          return 'update';
        case -1:
          return 'delete'
        default:
          return 'create';
      }
    },
  },
  computed: {
    ...mapState({
      // paymentHistory: state => state.subscriptions.paymentHistory
      auditEvents: state => state.patients.auditEvents
    }),

    eventsHeaders () {
      return [
        {
          text: 'Date',
          value: 'createdOn',
          width: '10%',
          sortable: false,
          isReplacingSpace: 0
        },
        {
          text: 'Message',
          value: 'message',
          width: '90%',
          sortable: false,
          isReplacingSpace: 0
        }
      ]
    }
  },
  watch: {
    items (val) {
      this.eventsItems = val
    }
  }
}
</script>

<style scoped lang="scss">
.events-log {
  max-height: 100vh;
  min-height: 100%;
  overflow-y: auto;
  padding: 4px 0px 4px 12px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F7FAFC;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CBD5E0;
    border: 1px solid #CBD5E0;
    border-radius: 10px;
  }

  ::v-deep .v-data-table {
    background-color: transparent;

    .v-data-table-header {
      th {
        height: auto;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 0.65px;
        border-bottom: none !important;
        padding: 0 0 15px;

        &:last-child {
          padding-left: 51px;
        }

        &:hover {
          color: #8492A6;
        }
      }
    }

    td {
      color: #425466;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      padding: 0 0 0 15px;

      &:first-child {
        padding: 0 15px 0 0;
      }

      p {
        margin: 0;

        &.mark {
          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 13px;
            border-radius: 8px;
            padding: 4px 10px;

            &.create {
              background-color: rgba(0, 140, 137, 0.16);
              color: #008C89;
            }
            &.update {
              background-color: rgba(208, 220, 255, 0.5);
              color: #537CFF;
            }
            &.delete {
              background-color: rgba(255, 230, 228, 0.7);
              color: #CF4655;
            }
          }

        }
      }
    }
  }

  ::v-deep .v-timeline {
    padding-top: 0 !important;
    padding-bottom: 23px;

    &-item {
      padding-bottom: 0;

      &__body {
        max-width: 100% !important;
        color: #425466;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-left: 22px;
      }

      &__divider {
        min-width: 14px !important;
      }

      &__dot {
        width: 14px;
        height: 14px;
        box-shadow: none;
      }

      &__inner-dot {
        width: 14px;
        height: 14px;
        margin: 0;
        background-color: #EDF2F7;
        border: 3px solid;

        &.create {
          border-color: #008C89;
        }
        &.update {
          border-color: #668BFF;
        }
        &.delete {
          border-color: #F16063;
        }
      }
    }

    &:before {
      left: 6px !important;
    }
  }
}
</style>
