<template>
  <div class="btn-group">
    <v-row class="px-0 py-0 ma-0">
      <v-btn-toggle v-model="toggle" mandatory group>
        <v-btn v-for="(item, key) in btnsWithCounts" :key="key" small :ripple="false" @click="selectBtn(key)">
          {{item.name}}
          <span v-if="item.count" class="total">{{ item.count || 0 }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'btn-group',
  props: {
    btns: {
      type: Array
    },
    counts: {
      type: Object
    }
  },
  data: () => ({
    toggle: +localStorage.getItem('quickFilterIndex') || undefined
  }),
  created () {
    this.toggle = this.qFilterBtns.findIndex(filter => filter.code === this.$route.query.quickFilterCode)
  },
  methods: {
    selectBtn (key) {
      this.$emit('update:btn', key)
    }
  },
  watch: {
    toggle (val) {
      localStorage.setItem('quickFilterIndex', val)
      // this.$emit('update:btn', val)
    }
  },
  computed: {
    qFilterBtns () {
      return this.$store.state.settings?.patients?.quickFilters?.$values
    },
    btnsWithCounts() {
      if (!this.counts || Object.keys(this.counts).length === 0) {
        return this.btns;
      }

      return this.btns.map(btn => ({
        ...btn,
        count: this.counts[btn.code] || 0
      }));
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-group {
    // ..
  }
</style>
