<template>
  <div class="tab-settings d-flex flex-row">
    <v-tabs
      v-if="settingFilters2 && settingFilters2.length > 0 && Object.keys(form).length && !this.isLoading"
      v-model="activeTab"
      background-color="transparent"
      color="transparent"
      vertical
    >
      <v-tab
        v-for="setting in settingFilters2"
        :key="setting.id"
      >
        {{ setting.settingName }}
<!--        <span class="setting-description" v-if="setting.description">-->
<!--          {{ setting.description }}-->
<!--        </span>-->
      </v-tab>
      <v-tabs-items v-model="activeTab" style="box-shadow: 0px 10px 20px 0px rgba(113, 128, 150, 0.06); border-radius: 16px;">
        <v-tab-item
          v-for="setting in settingFilters2"
          :key="setting.id"
        >
          <v-card
            :style="{ 'border-radius': '16px', 'box-shadow': 'none !important' }"
            elevation="1"
          >
            <v-card-text style="padding: 27px 36px 36px; min-height: 600px; overflow-y: auto; display: flex; flex-direction: column;">
              <div v-for="filter in setting.filters" :key="filter.componentName" style="flex-grow: 1;">
                <component
                  :is="filter.componentName"
                  :title="filter.name"
                  :form="form"
                  v-model="loadParams"
                ></component>
              </div>
              <v-row class="mx-0 my-0" style="flex-grow: 0;">
                <v-col class="d-flex justify-end px-0 py-0">
                  <Button height="56"
                          width="100"
                          btn-bg="white"
                          :styles="{ 'color': '#16192C', 'margin-right': '20px', 'border': '1px solid #EDF2F7' }"
                          @click.native="cancel">
                    Cancel
                  </Button>
                  <Button height="56"
                          width="100"
                          btn-bg="dark-green"
                          dark
                          :styles="{ 'border-radius': '8px' }"
                          @click.native="update">Update</Button>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '@/components/elements/Button'

export default {
  name: 'tabSettings',
  components: {
    Button,
    general: () => import('@/views/settings/tabSettingFilters/general.vue'),
    remindersSchedule: () => import('@/views/settings/tabSettingFilters/remindersSchedule.vue'),
    protocolEnding: () => import('@/views/settings/tabSettingFilters/protocolEnding.vue'),
    evaluationNotification: () => import('@/views/settings/tabSettingFilters/evaluationNotification.vue'),
    followupstatusworkflow: () => import('@/views/settings/tabSettingFilters/followUpStatusWorkflow.vue'),
    surveyduestatus: () => import('@/views/settings/tabSettingFilters/surveyDueStatus.vue'),
    inprotocolstatus: () => import('@/views/settings/tabSettingFilters/inProtocolStatus.vue'),
    evaluations: () => import('@/views/settings/tabSettingFilters/evaluations.vue'),
    patientSchedule: () => import('@/views/settings/tabSettingFilters/patientSchedule.vue'),
    patientReceipt: () => import('@/views/settings/tabSettingFilters/patientReceipt.vue'),


    // calculationSettings: () => import('@/views/settings/tabSettingFilters/calculationSettings.vue'),

    // patientReportPutBelowSignature: () => import('@/views/settings/tabSettingFilters/patientReportPutBelowSignature.vue'),
    // visits: () => import('@/views/settings/tabSettingFilters/visits.vue'),
    // visitResultCalculation: () => import('@/views/settings/tabSettingFilters/visitResultCalculation.vue'),
    // defaultSystemsSurveyCalculationMethod: () => import('@/views/settings/tabSettingFilters/defaultSystemsSurveyCalculationMethod.vue'),
    // defaultBloodChemistryCalculationMethod: () => import('@/views/settings/tabSettingFilters/defaultBloodChemistryCalculationMethod.vue'),
    // commonUi: () => import('@/views/settings/tabSettingFilters/commonUi.vue')
  },
  async mounted () {
    this.appSettings()
    const res = await this.getSettingFilters()
    if (res) {
      const { adminSettings, doctorSettings, organizationSettings } = this.settingFilters
      this.form = Object.assign({}, { adminSettings, doctorSettings, organizationSettings })
    }
    this.getCurrentSubscriptionPlan()
  },
  data: () => ({
    isLoading: false,
    activeTab: 0,
    settingFilters2: [
      {
        id: 1,
        settingName: 'General',
        permission: 'All',
        filters: [
          {
            componentName: 'general',
            name: 'General'
          }
        ]
      },
      {
        id: 2,
        settingName: 'Reminders',
        permission: 'All',
        filters: [
          {
            componentName: 'remindersSchedule',
            name: 'Evaluation Reminders Schedule'
          },
          {
            componentName: 'protocolEnding',
            name: 'Protocol Ending Reminders'
          },
          {
            componentName: 'evaluationNotification',
            name: 'Next Evaluation Notification'
          }
        ]
      },
      {
        id: 3,
        settingName: 'Statuses',
        // description: 'Home Screen and Patient List Filter',
        permission: 'All',
        filters: [
          {
            componentName: 'followupstatusworkflow',
            name: 'Statuses'
          },
          {
            componentName: 'surveyduestatus',
            name: 'Evaluation Due Status'
          },
          {
            componentName: 'inprotocolstatus',
            name: 'In Protocol Status Buffer'
          }
        ]
      },
      {
        id: 4,
        settingName: 'Evaluations',
        permission: 'All',
        filters: [
          {
            componentName: 'evaluations',
            name: 'Evaluations'
          },
        ]
      },
      {
        id: 5,
        settingName: 'Reports',
        permission: 'All',
        filters: [
          {
            componentName: 'patientSchedule',
            name: 'Reports'
          },
        ]
      },
      {
        id: 6,
        settingName: 'Invoices',
        permission: 'All',
        filters: [
          {
            componentName: 'patientReceipt',
            name: 'Invoices'
          }
        ]
      },
      // {
      //   id: 2,
      //   settingName: 'Calculation Settings',
      //   permission: 'Survey',
      //   filters: [
      //     {
      //       componentName: 'calculationSettings',
      //       name: ''
      //     }
      //   ]
      // },
    ],
    loadParams: {},
    form: {},
    popupMessage: null,
    alertIsOpen: false
  }),
  methods: {
    ...mapActions(['appSettings']),
    ...mapActions('organizations', ['getSettingFilters', 'updateSettingFilters']),
    ...mapActions('subscriptions', ['getCurrentSubscriptionPlan']),
    async update () {
      const res = await this.updateSettingFilters(this.form)
      if (res) {
        this.showPopup()
      }
    },
    async cancel () {
      this.isLoading = true;
      const res = await this.getSettingFilters()
      if (res) {
        const { adminSettings, doctorSettings, organizationSettings } = this.settingFilters
        this.form = Object.assign({}, { adminSettings, doctorSettings, organizationSettings })
      }
      this.isLoading = false;
    },
    showPopup () {
      this.alertIsOpen = true
      this.popupMessage = 'Setting was updated successfully.'
    },
  },
  computed: {
    ...mapState({
      settingFilters: state => state.organizations.settingFilters?.record,
      currentSubscription: state => state.subscriptions.currentSubscription
    })
  },
  watch: {
    loadParams (val) {
      console.log('loadParams', val)
      this.form = Object.assign({ ...val })
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tab-settings {
  padding: 6px;

  ::v-deep .v-tabs {
    gap: 20px;

    .v-tabs-bar {
      flex: 0 0 256px;
      padding-top: 2px;

      .v-tab {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        border-bottom: 1px solid #E2E8F0;
        color: #2D3748 !important;
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0;
        padding: 0 0 11px;
        margin-top: 7px;

        //.setting-description {
        //  color: #718096;
        //  font-size: 12px;
        //  font-weight: 400;
        //  line-height: 23px;
        //}

        &.v-tab--active {
          position: relative;
          font-weight: 700;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 3px;
            width: 16px;
            height: 16px;
            background: url("@/assets/icons/right-arrow.svg") no-repeat center center;
          }
        }
      }
    }
  }

  ::v-deep .v-btn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-radius: 16px !important;
  }

  .loading {
    width: 100%;
    height: 100vh;

    ::v-deep .v-progress-circular {
      position: absolute;
      top: 50%;
    }
  }

  ::v-deep .v-card {
    background: white!important;
    flex-wrap: wrap!important;
    width: 100%!important;
  }

  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;

    .setting-title {
      font-weight: 600;
      font-size: 20px;
      color: #16192C;
    }
  }

  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
}
</style>
