<template>
  <div class="pending-evaluation">
    <Dialog width="580" :canCloseManually="false" :canCloseWithEsc="true" ::scrollable="false" :showCloseIcon="true">
      <template slot="title">
        <div style="display: flex; align-items: center">
          <Img :src="getImgWithExt(['images', 'pending-icon', 'svg'])" alt="pending icon" style="width: 63px; height: 63px;" />
          <span>
            This is a Pending (future) Evaluation!
          </span>
        </div>
      </template>
      <template slot="body">
        <div style="padding: 0px 36px 9px">
          <p>Your patient has not yet been notified of this evaluation as it was scheduled for a future date. The system will automatically send notification in {{ pendingEvaluation.pendingDays }} days.</p>
          <p>Alternatively, if you wish to begin working with this evaluation now, click Begin Evaluation. Your patient will be notified accordingly if a survey is to be completed.</p>
        </div>
      </template>
      <template slot="actions">
        <div class="d-flex justify-end w-100">
          <Button
            dark
            outlined
            @click.native="close"
            :styles="{ 'border': '1px solid #EDF2F7' }"
          >
            <span style="color: #16192C; letter-spacing: 0;">Cancel</span>
          </Button>
          <Button
            btn-bg="dark-green"
            @click.native="beginEvaluation"
          >
            <span style="color: #FFFFFF; letter-spacing: 0;">Begin Evaluation</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'

export default {
  name: 'pending-evaluation',
  mixins: [Image],
  components: { Dialog, Button, Img },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('visits', ['visitProcess']),
    close () {
      this.TOGGLE_DIALOG({ name: 'pendingEvaluation', isOpen: false })
      this.$router.push({ name: 'Patients' })
    },
    async beginEvaluation () {
      const data = {
        visitId: this.pendingEvaluation.visitId,
        visitStatus: 'Pending',
      }
      const res = await this.visitProcess({ data })

      if (res.res === true) {
        this.$emit('updateStatus', res.data.status)
      }

      this.TOGGLE_DIALOG({ name: 'pendingEvaluation', isOpen: false })
    }
  },
  computed: {
    ...mapState({
      pendingEvaluation: state => state.dialogs.pendingEvaluation
    })
  },
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.pending-evaluation {
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 36px 36px 25px !important;

        span {
          color: #2D3748;
          font-size: 28px;
          font-weight: 600;
          line-height: 1.3;
          padding-left: 20px;
          padding-right: 15px;
        }

        .image {
          cursor: pointer;
        }
      }

      &__text {
        p {
          color: #2D3748;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4;
        }
      }

      &__actions {
        padding: 20px 36px 36px;

        .button {
          .v-btn {
            height: 56px;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            padding: 21px 24px;
          }
          &+.button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
