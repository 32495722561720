<template>
  <div class="confirmation-end-protocol">
    <Dialog width="660px" :canCloseManually="false" :showCloseIcon="true">
      <template slot="title">
        <h1 class="title">{{ modalConfirmationEndProtocol.title }}</h1>
      </template>
      <template slot="body">
        <div class="content">
          <h2 class="content__title">What would you like to do with the Evaluation?</h2>
          <p class="content__description">Click on your choice and confirm</p>
          <div v-if="currentModal === 'End Protocol Early / Adjust Protocol'"
               class="wrapper-protocol">
            <div
              class="protocol protocol-adjust"
              :class="{ active: form.activeProtocol === 'adjust-protocol' }"
              @click="handleAction('adjust-protocol')">
              <h3 class="protocol__title">Adjust Protocol</h3>
              <div style="position: relative">
                Adjusting Protocol will close this evaluation, placing it into a Closed status, and create a new evaluation with an In Progress status.
                <v-tooltip attach="parent" :open-on-hover="false" v-model="showInfoAdjustProtocol">
                  <template v-slot:activator="{}">
                    <span style="color: #008C89" @click.stop="onShowInfoAdjustProtocol">More...</span>
                  </template>
                  <template v-slot:default>
                    <div class="custom-tooltip">
                      <p>
                        Adjusting Protocol will close this evaluation, placing it into a Closed status, and create a new evaluation with an In Progress status. Protocol supplements, patient notes and evaluation notes will be duplicated into the new evaluation for your convenience. However, all system generated reminders will be reset. Furthermore, since protocols are based on day's supply, if your patient has already begun utilizing this protocol, any changes may result in the shortages or overages of product for your patient.
                      </p>
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </div>
            <div
              class="protocol protocol-end"
              :class="{ active: form.activeProtocol === 'end-protocol' }"
              @click="handleAction('end-protocol')">
              <h3 class="protocol__title">End Protocol</h3>
              <p>Choose this option if you simply want to end this current protocol. This evaluation will change to a Closed status.</p>
            </div>
          </div>
          <div v-else-if="currentModal === 'Start Protocol / End Evaluation'"
               class="wrapper-protocol">
            <div
              class="protocol protocol-start"
              :class="{ active: form.activeProtocol === 'protocol-start' }"
              @click="handleAction('protocol-start')">
              <h3 class="protocol__title">Start Protocol</h3>
              <p>
                The system will track the protocol, provide reminders when the protocol is ending so that you can reevaluate your patient in a timely manner, and/or resupply them with product. Status will change to In Protocol.
              </p>
            </div>
            <div
              class="protocol protocol-end-evaluation"
              :class="{ active: form.activeProtocol === 'protocol-end-evaluation' }"
              @click="handleAction('protocol-end-evaluation')">
              <h3 class="protocol__title">End Evaluation</h3>
              <p>
                Protocol will not be tracked, reminders not sent. Status will be changed to Closed.
              </p>
            </div>
          </div>
          <div class="mt-6" style="height: 165px">
            <div v-if="modalConfirmationEndProtocol.data.addVisit && !isAddNextEvaluationDisabled">
              <div class="custom-checkbox">
                <v-checkbox
                  color="#008C89"
                  label="Add Next Evaluation Date"
                  @click="toggleNextEvaluation"
                  v-model="form.addNextEvaluation"
                ></v-checkbox>
              </div>
              <transition name="fade">
                <div :class="['elements-wrapper', form.addNextEvaluation ? 'visible' : 'hidden']">
                  <div class="elements">
                    <div class="evaluation-date mr-7">
                      <h3 class="elements__title">Evaluation Date</h3>
                      <datepicker
                        :min="nowDate"
                        v-model="form.evaluationDate"
                        @date:update="form.evaluationDate = $event"
                      />
                    </div>
                    <div class="evaluation-type">
                      <h3 class="elements__title">Select the Evaluation Type</h3>
                      <div class="elements__survey">
                        <v-checkbox
                          color="#008C89"
                          v-model="form.survey"
                          class="round-checkbox"
                          label="Survey"/>
                        <v-checkbox
                          color="#008C89"
                          v-model="form.nonSurvey"
                          class="round-checkbox"
                          label="Non-Survey"/>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </template>
      <template slot="actions">
        <div class="d-flex justify-end align-center pt-5" style="width: 100%">
          <Button height="56"
                  width="100"
                  btn-bg="white"
                  :styles="{ 'color': '#16192C', 'margin-right': '20px', 'border': '1px solid #EDF2F7' }"
                  @click.native="close">
            Cancel
          </Button>
          <Button height="56"
                  width="100"
                  btn-bg="dark-green"
                  dark
                  :disabled="isConfirmDisabled"
                  @click.native="confirm">Confirm</Button>
        </div>
      </template>
    </Dialog>
    <confirm v-if="dialogs.confirm.isOpen" @close="close"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import datepicker from '@/components/elements/datepicker'

export default {
  name: 'modal-confirmation-end-protocol',
  components: { Dialog, Button, confirm, datepicker },
  data: () => ({
    form: {
      activeProtocol: null,
      addNextEvaluation: false,
      survey: false,
      nonSurvey: false,
      evaluationDate: null,
    },
    showInfoAdjustProtocol: false,
    nowDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10),
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    close () {
      this.TOGGLE_DIALOG({ name: 'modalConfirmationEndProtocol', isOpen: false, root: true })
    },
    confirm() {
      if (!this.isConfirmDisabled) {
        if (this.form.activeProtocol === 'adjust-protocol') {
          this.form.addNextEvaluation = true
          this.form.nonSurvey = true
        }
        this.$emit('request', this.form)
      }
    },
    toggleNextEvaluation() {
      this.form.survey = false
      this.form.nonSurvey = false
    },
    handleAction(action) {
      this.form.activeProtocol = action
      if (action === 'adjust-protocol') {
        this.form.addNextEvaluation = false
      } else if (action === 'end-protocol' || action === 'protocol-end-evaluation') {
        if (action === 'end-protocol') {
          this.form.addNextEvaluation = false
        }
        this.form.survey = false
        this.form.nonSurvey = false
        this.form.evaluationDate = this.modalConfirmationEndProtocol.data.date ?
          new Date(this.modalConfirmationEndProtocol.data.date) :
          null
      }
    },
    onShowInfoAdjustProtocol() {
      this.showInfoAdjustProtocol = true

      this.$nextTick(() => {
        const tooltipContent = document.querySelector('.v-tooltip__content')
        if (tooltipContent) {
          tooltipContent.style.opacity = '1'
        }
      })

      clearTimeout(this.timerId)

      if (this.showInfoAdjustProtocol) {
        this.$nextTick(() => {
          this.timerId = setTimeout(() => {
            this.showInfoAdjustProtocol = false
            this.removeEventListeners()
          }, 10000)
        })
      }

      this.addEventListeners()
    },
    addEventListeners() {
      document.addEventListener('click', this.handleOutsideClick)
      document.addEventListener('keydown', this.handleKeyPress)
    },
    removeEventListeners() {
      document.removeEventListener('click', this.handleOutsideClick)
      document.removeEventListener('keydown', this.handleKeyPress)
    },
    handleOutsideClick(event) {
      const tooltipContent = document.querySelector('.v-tooltip__content')
      if (tooltipContent && !tooltipContent.contains(event.target)) {
        this.showInfoAdjustProtocol = false
        this.removeEventListeners()
      }
    },
    handleKeyPress(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.showInfoAdjustProtocol = false
        this.removeEventListeners()
      }
    },
  },
  computed: {
    ...mapState({
      modalConfirmationEndProtocol: state => state.dialogs.modalConfirmationEndProtocol
    }),
    dialogs () {
      return this.$store.state.dialogs
    },
    currentModal() {
      return this.modalConfirmationEndProtocol.title
    },
    isConfirmDisabled() {
      return !this.form.activeProtocol || (this.form.addNextEvaluation && !this.form.survey && !this.form.nonSurvey)
    },
    isAddNextEvaluationDisabled() {
      return (
        this.form.activeProtocol === 'adjust-protocol'
      )
    },
  },
  mounted() {
    if (this.modalConfirmationEndProtocol.data.date) {
      this.form.evaluationDate = new Date(this.modalConfirmationEndProtocol.data.date)
    } else {
      const today = new Date()
      const day = String(today.getDate()).padStart(2, '0')
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const year = today.getFullYear()
      this.form.evaluationDate = `${month}/${day}/${year}`
    }
  },
  watch: {
    'form.survey'(val) {
      if (val) {
        this.form.nonSurvey = false
      }
    },
    'form.nonSurvey'(val) {
      if (val) {
        this.form.survey = false
      }
    },
    showInfoAdjustProtocol(newValue) {
      if (!newValue) {
        this.addEventListeners()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-end-protocol {
  ::v-deep .v-dialog {
    .v-card {
      &__title {
        position: relative;
        letter-spacing: 0;
        padding: 36px 36px 20px !important;

        .image {
          position: absolute;
          top: 36px;
          right: 36px;
          cursor: pointer;
        }

        .title {
          color: #27272E;
          font-size: 28px !important;
          font-weight: 600;
          letter-spacing: 0 !important;
        }
      }
      &__text {
        .content {
          padding: 0 36px !important;

          &__title {
            color: #2D3748;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0;
            padding-bottom: 2px;
          }

          &__description {
            color: #718096;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0;
            margin: 0;
            padding-bottom: 17px;
          }

          .wrapper-protocol {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          }

          .protocol {
            border-radius: 16px;
            padding: 12px 20px 25px;
            transition: border-color 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;

            &.active {
              border-color: rgba(0, 140, 137, 0.5) !important;
              box-shadow: 0px 0px 20px 0px rgba(0, 140, 137, 0.5);
            }

            p {
              color: #4A5568;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.6;
              letter-spacing: 0;
              margin: 0;
            }

            &__title {
              font-size: 18px;
              font-weight: 600;
              line-height: 1.4;
              letter-spacing: 0;
              padding-bottom: 5px;
            }

            &.protocol-adjust {
              //position: relative;
            }

            &.protocol-adjust,
            &.protocol-start {
              border: 2px solid #E5F3F3;
              background-color: #E5F3F3;

              .protocol__title {
                color: #008C89;
              }
            }

            &.protocol-end,
            &.protocol-end-evaluation {
              border: 2px solid #EBF2FA;
              background-color: #EBF2FA;

              .protocol__title {
                color: #2D3748;
              }
            }
          }
        }
      }
      &__actions {
        padding: 0 36px 36px;
      }
    }
  }

  .custom-checkbox {
    ::v-deep .v-input {
      margin: 0;
      padding: 0;

      label {
        color: #2D3748;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
      }

      .v-input--selection-controls__input {
        margin-right: 13px;
        height: 16px;
        width: 16px;

        &:hover .v-input--selection-controls__ripple:before {
          opacity: 0;
        }

        .v-icon {
          color: #C9CED6;
        }

        .v-input--selection-controls__ripple {
          height: 16px;
          width: 16px;
          left: 0;
          top: 0;
          border-radius: 0;
          margin: 0;
        }
      }

      .v-messages  {
        display: none;
      }
    }
  }

  .elements-wrapper {
    transition: opacity 0.3s ease;

    &.hidden {
      opacity: 0;
    }

    &.visible {
      opacity: 1;
    }
  }

  .elements {
    display: flex;
    justify-content: flex-start;
    border-radius: 16px;
    background-color: #F7FAFC;
    padding: 22px 20px 26px;
    margin-top: 20px;

    &__title {
      color: #2D3748;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 10px;
    }

    &__survey {
      display: flex;
      align-items: center;
      margin-left: -10px;
      margin-top: -3px;

    }

    .round-checkbox {
      margin: 0;

      &::v-deep.v-input {
        background-color: transparent;
        border-radius: 8px;
        padding: 10px;
        margin: 0;

        label {
          color: #2D3748;
          font-size: 14px;
          font-weight: 400;
          line-height: 23px;
          margin-left: 0px;
        }

        .v-input--selection-controls__input {
          display: flex;
          justify-content: center;
          align-items: center;

          .v-icon {
            display: none;
          }
        }

        .v-input__control {
          .v-messages {
            display: none;
          }
        }

        .v-input--selection-controls__ripple {
          width: 20px;
          height: 20px;
          left: 0;
          top: 2px;
          border-radius: 50%;
          border: 2px solid #C9CED6;
          margin: 0;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            top: 3px;
            left: 3px;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color 0.2s ease;
            opacity: 1;
            transform: scale(1);
          }
        }

        &.v-input--is-dirty {
          background-color: #F7FAFC;

          .v-input--selection-controls__ripple {
            border: 2px solid #008C89;

            &::before {
              background-color: #008C89;
            }
          }
        }
      }
    }
  }

  ::v-deep .v-btn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-radius: 16px !important;

    &.v-btn--disabled {
      color: #A6B7D4 !important;
      background-color: #EBF2FA !important;
    }
  }

  .datepicker {
    width: 180px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;

}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-tooltip {
  display: inline-block;

  .v-tooltip__content {
    position: absolute;
    top: calc(100% - 35px) !important;
    left: 135px !important;

    .custom-tooltip {
      width: 350px;
      background-color: #FFFFFF;
      box-shadow: 0 0 15px 0 rgba(113, 128, 150, 0.24);
      border-radius: 16px;
      color: #2D3748;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      padding: 20px;
      margin: -8px  -20px;

      p {
        margin: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.confirmation-end-protocol {
  .datepicker {
    .input-with-icon {
      padding: 8px 11px;

      .input .v-input__control .v-input__slot .v-text-field__slot input {
        color: #2D3748 !important;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
</style>
