<template>
  <div class="patients">
    <template v-if="$route.name === 'Patients'">
      <v-container fluid>
        <v-row>
          <v-col class="patients__quick-filter d-flex flex-start">
            <quick-filter :items="qFilterBtns" :counts="filterCounts" @updateQuickFilter="updateQuickFilterValue" ref="qFilter" />
          </v-col>
        </v-row>
        <v-row class="mt-0 py-3">
          <v-col class="px-0 py-0" cols="12">
            <div class="patient-controls d-flex justify-space-between">
              <div class="patients__total d-flex align-center mr-2"><span class="num mr-2">{{total}}</span> Patients</div>
              <div class="d-flex align-center mr-6" v-if="qFilterBtns && qFilterBtns[qFilterIndex].code === 'FollowUp'">
                <div class="mr-4">Follow Up date range <!--<br>today VISIT-->
                </div>
                <datepicker class="mr-2" :max="patients.followUpDateTo"
                            style="width: 170px;"
                            placeholder="From"
                            :value="$moment.utc(query.followUpDateFrom).format('MM/DD/YYYY')"
                            @date:update="query.followUpDateFrom = $event;getPatients(query)"/>
                <datepicker :min="patients.followUpDateFrom"
                            style="width: 170px;"
                            placeholder="To"
                            :value="$moment.utc(query.followUpDateTo).format('MM/DD/YYYY')"
                            @date:update="query.followUpDateTo = $event;getPatients(query)"/>
              </div>
              <div class="d-flex align-center flex-grow-1 justify-end">
                <Input style="margin-top: 18px"
                       outlined
                       radius="8"
                       placeholder="Search..."
                       v-model="searchValue"
                       @keyup.enter="inputSearch(searchValue)"
                >
                  <template v-slot:prepend-inner>
                    <div style="cursor: pointer" @click="getPatients(query)">
                      <Img :src="getImgWithExt(['icons', 'search', 'svg'])" />
                    </div>
                  </template>
                </Input>
                <Button outlined
                        :styles="{ 'border': '1px solid #A6B7D4', 'min-width': '36px', 'padding': '0px', 'margin': '0 18px' }"
                        @click.native="refresh"
                >
                  <Img :src="getImgWithExt(['icons', 'refresh', 'svg'])" />
                </Button>
                <Button btn-bg="dark-green"
                        @click.native="TOGGLE_DIALOG({ name: 'addPatient', isOpen: true, title: 'Add Patient' })">
                      <span style="color: #FFFFFF">
                        <v-icon>mdi-plus</v-icon>
                        Add patient
                      </span>
                </Button>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="px-0 py-0" style="position: relative">
            <Table class="patient-table"
                   hide-default-footer
                   :actions="actionsWithDeactivate"
                   :actionsWithActivate="actionsWithActivate"
                   :extendedActions="extendedActionsWithDeactivate"
                   :extendedActionsWithActivate="extendedActionsWithActivate"
                   :headers="headers"
                   :items="patientItems"
                   :serverItemsLength="total"
                   :statusCounts="statusCounts"
                   @update:option="optionTableData($event, 'asc')"
                   @click:action="openDialog($event)"
                   @filterBox="filterBox"
                   @resetFilter="resetFilter"
                   @searchByInput="inputSearch($event, true)" >
<!--              <template v-slot:header:name>
                <filter-box :headers="headers" field-name="name" type="String" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:status>
                <filter-box :headers="headers" field-name="status" type="FixedEnum" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:nextVisitDate>
                <filter-box :headers="headers" field-name="nextVisitDate" type="Date" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:lastVisitDate>
                <filter-box :headers="headers" field-name="lastVisitDate" type="Date" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>-->

              <template v-slot:item:name={item}>
                <div class="d-flex" @click.stop="openPatientDetails(item.id)">
                  <v-badge
                    color="#7cdbc1"
                    :value="item.events"
                    :content="item.events"
                    overlap
                    bottom
                    bordered
                    offset-x="20"
                    offset-y="15"
                  >
                    <v-avatar class="patient-table__icon mr-2" :class="{ 'patient-is-registered': item.portal }" size="36">
                      <img v-if="item.profileAvatar" :src="item.profileAvatar" :alt="item.name" />
                    </v-avatar>
                  </v-badge>
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{item.name}}</span>
                    <v-tooltip top v-if="item.vegetarian">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-2" style="width: 24px">
                          <Img :src="getImgWithExt(['icons', 'vegan_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient is Vegetarian</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.glutenFree" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-2" style="width: 24px">
                          <Img :src="getImgWithExt(['icons', 'gluten_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient is Gluten Free</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.registerPortal" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-2" style="width: 24px">
                          <Img :src="getImgWithExt(['icons', 'portal_regestreted_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient Portal Registered</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.lead">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">mdi-human-queue</v-icon>
                      </template>
                      <span>Patient has leads</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
              <template v-slot:item:nextVisitDate="{ item }">
                <div class="next-visit">
                  <div class="next-visit__add" v-if="!item.nextVisitDate">
                    <Button color="transparent"
                            height="26"
                            @click.native.stop="TOGGLE_DIALOG({ id: item.id, name: 'addVisit', title:'Add New Evaluation', isOpen: true, data: item })"
                    >+ <span>Evaluation</span></Button>
                  </div>
                  <div class="next-visit__exist d-flex align-center" v-else>
                    <Link :to="`patients/visit/${item.nextVisitId}`" color="#2D3748" size="16" weight="400">
                      {{ $moment.utc(item.nextVisitDate).format('MM/DD/YYYY') }}
                    </Link>
                  </div>
                </div>
              </template>
              <template v-slot:item:lastVisitDate={item}>
                <div class="in-protocol d-flex justify-center">
                  <v-tooltip right v-if="item.lastVisitStatus === 'InProtocol' || item.lastVisitStatus === 'ProtocolEnding'">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="in-protocol d-flex justify-center">
                        <Link :to="item.lastVisitDate ? `patients/visit/${item.lastVisitId}` : ''">
                          <Img v-if="item.lastVisitStatus === 'InProtocol'" :src="getImgWithExt(['icons', 'protocol-green-icon', 'svg'])" style="width: 24px" />
                          <Img v-if="item.lastVisitStatus === 'ProtocolEnding'" :src="getImgWithExt(['icons', 'protocol-pink-icon', 'svg'])" style="width: 24px" />
                        </Link>
                      </div>
                    </template>
                    <span>Patient is in protocol of the evaluation</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item:number="{ item }">
                <div class="d-flex align-center" style="white-space: nowrap" @click.stop="callToPatient(item.number)"><!--width: 140px;-->
                  <Img :src="getImgWithExt(['icons', 'phone-icon-2', 'svg'])" class="mr-3" />
                  <div style="overflow: hidden; text-overflow: ellipsis; font-size: 16px; color: #2D3748">{{item.number || ' '}}</div>
                </div>
              </template>

              <template v-slot:item:email={item}>
                <div class="d-flex align-center" style="white-space: nowrap" @click.stop="sendEmailToPatient(item.email)"><!--width: 125px;-->
                  <Img :src="getImgWithExt(['icons', 'email-icon-2', 'svg'])" />
<!--                  <Link to="#" color="#425466" size="14" weight="400" style="overflow: hidden;text-overflow: ellipsis;">
                    {{ item.email }}
                  </Link>-->
                </div>
              </template>

              <template v-slot:item:vegetarian="{item}">
                <div class="d-flex align-center">
                  <checkbox :inputValue="item.vegetarian" disabled></checkbox>
                </div>
              </template>

              <template v-slot:item:glutenFree="{item}">
                <checkbox :inputValue="item.vegetarian" disabled></checkbox>
              </template>

              <template v-slot:item:status="{ item }">
                <v-tooltip right :open-on-hover="true"><!--:open-on-hover="false" :value="showDescriptionStatus && currentRow === item.id"-->
                  <template v-slot:activator="{ on, attrs }"><!--v-slot:activator="{}"-->
                    <div v-show="item.status !== 'None'"
                         @click="openByStatus(item)"
                         v-bind="attrs"
                         v-on="on"><!--@click="showStatusDescription(item.id)" without v-bind v-on-->
                      <status v-if="getStatusCodeName[item.status]"
                              :style="{
                                backgroundColor: getStatusCodeName[item.status]?.background || '#EDF2F7',
                                color: getStatusCodeName[item.status]?.text || '#A0AEC0'
                              }"
                      >
                        {{getStatusCodeName[item.status].name || '-'}}
                      </status>
                    </div>
                  </template>
                  <div style="display: block;max-width: 300px;" v-if="getStatusCodeName[item.status]">{{getStatusCodeName[item.status].description(item.statusDays) || '-'}}</div>
                </v-tooltip>
              </template>

              <template v-slot:footer>
                <div class="footer pl-5 d-flex align-center">
                  <div class="row-per-page d-flex align-center flex-grow-1">
                    <span class="row-per-page__lable mr-3">Rows per page: </span>
                    <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[2]" v-model="currentPageSize" @change="changeItermPerPage" />
                  </div>
                  <div class="flex-grow-2">
                    <pagination v-if="patientPageCount" :value="page" :length="patientPageCount" :totalVisible="7" @input="changePagination" />
                  </div>
                  <div class="switch d-flex align-start justify-end flex-grow-1 mr-6">
                    <span>Show inactive Patient</span>
                    <v-switch class="py-0 my-0" color="#008C89" inset @change="inactivePaitent" />
                  </div>
                </div>
              </template>
            </Table>
            <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
              <v-progress-circular
                indeterminate
                color="#008C89"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <add-patient :query="query" v-if="dialogs.addPatient.isOpen || dialogs.editPatient.isOpen" @showPopup="showPopup" />
      <add-visit :query="query" v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
      <confirm :query="query" v-if="dialogs.confirm.isOpen" />
      <invite-patient v-if="dialogs.invitePatient.isOpen"  @showPopup="showPopup" />
      <div class="custom-alert">
        <v-alert
          color="#7ac1fb"
          dismissible
          type="info"
          v-model="alertIsOpen"
        >
          <span style="font-size: 14px;">{{popupMessage}}</span>
          <router-link v-if="newPatientLink" :to="`patients/${newPatientLink.id}`">
            <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span>
          </router-link>
          <router-link v-if="redirectToNewVisit" :to="`patients/visit/${redirectToNewVisit}`">
            <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">View Evaluation</span>
          </router-link>
        </v-alert>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import Table from '@/components/elements/Table'
import QuickFilter from '@/views/patients/quick-filter'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import checkbox from '@/components/elements/checkbox'
import Link from '@/components/elements/Link'
import datepicker from '@/components/elements/datepicker'
import status from '@/components/layouts/status'
import pagination from '@/components/layouts/pagination'

import addPatient from '@/components/layouts/dialogs/add-patient'
import addVisit from '@/components/layouts/dialogs/add-visit'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import invitePatient from '@/components/layouts/dialogs/invite-patient'

import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
import Select from '@/components/elements/Select.vue'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'
import moment from 'moment'

const GET_ALL_PACIENTS = 'getPatients'
const IS_SLOTABLE = ['name', 'nextVisitDate', 'lastVisitDate', 'email', 'number', 'status', 'actions']
const IS_FILTERABLE = ['icon', 'address']

export default {
  name: 'patients',
  mixins: [Pagination, Mapping, Image],
  components: { Table, QuickFilter, Button, Input, checkbox, addPatient, addVisit, Link, confirm, datepicker, Select, pagination, status, invitePatient, Img },
  data: () => ({
    showDescriptionStatus: false,
    currentRow: null,
    timerId: 0,
    qFilterIndex: 0, // +localStorage.getItem('quickFilterIndex') || 0
    itemsPerPage: 15,
    page: 1,
    defaultButtonActionsWithDeactivate: [
      { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
      { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
      { icon: 'mdi-trash-can-outline', color: 'grey', name: 'Deactivate', type: 2 }
    ],
    defaultButtonActionsWithActivate: [
      { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
      { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
      { icon: 'mdi-account-reactivate-outline', color: 'grey', name: 'Activate', type: 4 }
    ],
    // extendedButtonActionsWithDeactivate: [
    //   { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
    //   { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
    //   { icon: 'mdi-trash-can-outline', color: 'grey', name: 'Deactivate', type: 2 },
    //   { icon: 'mdi-account-plus', color: 'grey', name: 'Invite Patient to join Portal', type: 3 }
    // ],
    extendedButtonActionsWithActivate: [
      { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
      { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
      { icon: 'mdi-account-reactivate-outline', color: 'grey', name: 'Activate', type: 4 },
      { icon: 'mdi-account-plus', color: 'grey', name: 'Invite Patient to join Portal', type: 3 }
    ],
    searchValue: null,
    popupMessage: null,
    newPatientLink: null,
    redirectToNewVisit: null,
    alertIsOpen: false,
    loading: true,
    currentPageSize: 15,
    filterCounts: {},
    statusCounts: {},
  }),
  created () {
    this.appSettings()
    sessionStorage.setItem('selectedPage', this.$route.path)
  },
  mounted () {
    if (this.patientItems.length > 0) {
      this.loading = false
    } else {
      this.loading = true
    }
    document.title = 'Patients - Maestro Practitioner Portal'
    // Watch to index of quick-filter component
    // this.$watch('$refs.qFilter.index', this.applyQFilter)
    // this.waitingRefs()
    /* if (this.$route.query.quickFilterCode) {
      this.addUrlParam({ quickFilterCode: this.$route.query.quickFilterCode })
      this.getPatients(this.query)
    } */
    /* else {
      this.addUrlParam({ quickFilterCode: this.qFilterBtns[this.qFilterIndex].code })
      this.getPatients({ ...this.query, from: 'mounted_2' })
    } */
    if (this.$route.query.modal) {
      if (this.$route.query.modal === 'addPatient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: true, title: 'Add Patient' })
      }
    }
    this.fetchQuickFiltersSummary()
    this.fetchStatusesSummary()
  },
  watch: {
    patients (val) {
      if (val) {
        this.query.followUpDateFrom = val.followUpDateFrom
        this.query.followUpDateTo = val.followUpDateTo
        this.fetchQuickFiltersSummary();
      }
    },
    '$route.name' (val) {
      if (val === 'Patients') {
        // this.waitingRefs()
        this.$forceUpdate()
        document.title = 'Patients - Maestro Practitioner Portal'
      }
    },
    patientListIsLoaded (val) {
      if (val) {
        this.loading = false
      } else {
        this.loading = true
      }
    },
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('patients', ['SET_DATA']),
    ...mapActions(['appSettings']),
    ...mapActions('patients', ['getPatients', 'quickFiltersSummary', 'statusesSummary']),
    updateQuickFilterValue (val) {
      if (val !== -1) {
        this.query.PageIndex = 0
        this.query.PageSize = this.currentPageSize
        this.qFilterIndex = val
        const code = this.qFilterBtns[val].code
        window.history.pushState(
          null,
          document.title,
          `${window.location.pathname}?quickFilterCode=${code}`
        )
        this.$route.query.quickFilterCode = code
        if (val === 3) {
          this.addUrlParam({
            followUpNotOlderDays: '30',
            followUpDaysFromToday: '60'
          })
        } else {
          this.removeUrlParam('followUpNotOlderDays')
          this.removeUrlParam('followUpDaysFromToday')
        }
        this.addUrlParam({ quickFilterCode: code, order: [{column: 'name', direction: 'asc'}] })
        this.page = 1
        this.getPatients(this.query)
      }
    },
    waitingRefs () {
      console.log('waitingRefs qFilter.index')
      const interval = setInterval(() => {
        if (this.$refs.qFilter) {
          console.log(this.$refs.qFilter)
          this.$watch('$refs.qFilter.index', this.applyQFilter)
          clearInterval(interval)
        }
      }, 50)
    },
    showPopup (res) {
      console.log('showPopup', res.type, res.data)
      this.alertIsOpen = true
      switch (res.type) {
        case 'add-patient':
          if (this.patientItems.length === 1) {
            this.getPatients(this.query)
          }
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was created  successfully. You can see at `
          this.newPatientLink = res.data
          this.fetchQuickFiltersSummary()
          break
        case 'edit-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
          break
        case 'add-visit':
          if (res.data.record.redirectToVisit) {
            this.alertIsOpen = false
            sessionStorage.setItem('selectedPage', `/patients/visit/${res.data.record.id}`)
            this.$router.push({ name: 'View Evaluation', params: { id: res.data.record.id } })
          } else {
            this.popupMessage = `Evaluation date was added. Now you can `
            this.redirectToNewVisit = res.data.record.id
          }
          break
        case 'invite-patient':
          this.popupMessage = 'The Invitiation Email was sent to the Patient. If Patient will confirm the registration he will be able to join the Patient Portal and fill in the Survey(s).'
      }
      if (res.type === 'edit-patient') {
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
      }
      if (res.type === 'invite-patient') {
        this.TOGGLE_DIALOG({ name: 'invitePatient', isOpen: false })
      }
    },
    inputSearch ($event, isNameSearch = false) {
      delete this.query.searchOnlyName;

      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize

      const searchParams = {
        search: { searchType: 1, value: $event }
      };

      if (isNameSearch) {
        searchParams.searchOnlyName = true;
      }
      this.addUrlParam(searchParams);

      this.page = 1
      this.getPatients(this.query);
    },
    applyQFilter (val) {
      console.log('applyQFilter', val)
      this.qFilterIndex = val
      this.addUrlParam({ quickFilterCode: this.qFilterBtns[val].code })
      if (val === 3) {
        this.addUrlParam({
          followUpNotOlderDays: '30',
          followUpDaysFromToday: '60'
        })
      } else {
        this.removeUrlParam('followUpNotOlderDays')
        this.removeUrlParam('followUpDaysFromToday')
      }
      this.getPatients(this.query)
    },
    cutName (name) {
      const [f, l] = name.split(' ')
      return this._.toUpper(f.charAt(0) + l.charAt(0))
    },
    openPatientDetails (id) {
      sessionStorage.setItem('selectedPage', `/patients/${id}`)
      this.$router.push({ name: 'View patient', params: { id }, query: this.$route.query })
    },
    callToPatient (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
      }
    },
    sendEmailToPatient (email) {
      if (email) {
        window.open('mailto:' + email)
      }
    },
    openDialog ($event) {
      switch ($event.action.type) {
        case 0:
          this.$router.push({ path: `patients/${$event.id}` })
          break
        case 1:
          this.TOGGLE_DIALOG({ id: $event.id, name: 'editPatient', isOpen: true, title: $event.action.name })
          break
        case 2:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'patients/deactivatePatient',
            afterAction: { name: 'patients/getPatients' },
            title: 'Confirm deactivating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> deactivation. If Patient will be marked as Inactive you will not see him in Recent or Follow Up areas and patient will not be able to access the Patient Portal if registered.<br>Are you sure want to deactivate the Patient?`
          })
          break
        case 3:
          if (!$event.data.email) {
            this.TOGGLE_DIALOG({
              id: $event.id,
              name: 'confirm',
              isOpen: true,
              data: $event,
              whose: 'patientInviteToPortal',
              title: 'Alert Invitation Patient to Portal',
              description: `This Patient does not have email. Please edit the Patient and setup email. Would you like to Edit Patient?`
            })
          } else {
            this.TOGGLE_DIALOG({ id: $event.id, name: 'invitePatient', isOpen: true, title: $event.action.name, data: $event.data })
          }
          break
        case 4:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'patients/activatePatient',
            afterAction: { name: 'patients/getPatients' },
            title: 'Confirm activating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> activation.<br>Are you sure want to activate the Patient?`
          })
          break
      }
    },
    inactivePaitent ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ showInactive: $event })
      this.page = 1
      this.getPatients(this.query)
    },
    filterBox ($event) {
      let columns = []
      if (this.query && this.query.columns) {
        columns = this.query.columns
        // columns.push($event)
        if ($event.type === 'Enum') {
          columns = columns.filter(item => item.type !== 'Enum')
        }
        if ($event.constraints.length) {
          columns.push($event)
        }
      } else {
        columns.push($event)
      }
      this.addUrlParam({ columns })
      this.getPatients(this.query)
    },
    resetFilter ($event) {
      const query = Object.assign({}, this.query)
      const columns = query.columns.filter(el => {
        if (el.rangeSearch) {
          return el.rangeSearch.data.toLowerCase() !== $event.toLowerCase()
        } else {
          return el.name.toLowerCase() !== $event.toLowerCase()
        }
      })
      this.addUrlParam({ columns })
      this.getPatients(this.query)
    },
    refresh () {
      delete this.query.searchOnlyName;
      this.searchValue = null
      delete this.query.search
      delete this.query.columns
      this.page = 1
      this.query.PageSize = this.currentPageSize
      this.query.PageIndex = 0
      this.$emit('clearInput');
      this.addUrlParam({ ...this.query })
      this.$nextTick(() => {
        this.getPatients(this.query)
      })
    },
    changeItermPerPage ($event) {
      this.itemsPerPage = $event
      this.currentPageSize = $event
      if ($event !== this.query.PageSize) {
        this.addUrlParam({
          PageIndex: 0, // this.page,
          PageSize: $event === 'All' ? '-1' : $event
        })
        this.query.PageIndex = 0
        this.getPatients(this.query)
        this.page = 1
      }
    },
    changePagination ($event) {
      this.page = $event
      localStorage.setItem('patientPaginationPageIndex', $event)
      this.addUrlParam({
        PageIndex: $event === 0 ? 0 : $event - 1,
        PageSize: this.itemsPerPage
      })
      this.getPatients(this.query)
    },
    showStatusDescription (id) {
      this.currentRow = id
      this.showDescriptionStatus = !this.showDescriptionStatus
      clearTimeout(this.timerId)
      if (this.showDescriptionStatus) {
        const self = this
        this.$nextTick(() => {
          self.timerId = setTimeout(function () {
            self.showDescriptionStatus = false
          }, 3000)
        })
      }
    },
    openByStatus (obj) {
      switch (obj.status) {
        case 'InActive':
          this.TOGGLE_DIALOG({
            id: obj.id,
            name: 'confirm',
            isOpen: true,
            action: 'patients/activatePatient',
            afterAction: { name: 'patients/getPatients' },
            title: 'Confirm activating the Patient',
            description: `Please confirm Patient <b>${obj.firstName} ${obj.lastName}</b> activation.<br>Are you sure want to activate the Patient?`
          })
          break
        case 'NeedSurvey':
          this.TOGGLE_DIALOG({
            id: obj.id,
            name: 'addVisit',
            title: 'Add New Evaluation',
            isOpen: true,
            data: obj
          })
          break
        case 'NeedReeval':
          this.TOGGLE_DIALOG({
            id: obj.id,
            name: 'addVisit',
            title: 'Add New Evaluation',
            isOpen: true,
            data: obj
          })
          break
        case 'SurveySent':
          sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}`)
          this.$router.push({ path: `patients/visit/${obj.nextVisitId}` })
          break
        case 'SurveyDue':
          sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}`)
          this.$router.push({ path: `patients/visit/${obj.nextVisitId}` })
          break
        case 'NeedReview':
          sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}`)
          this.$router.push({ path: `patients/visit/${obj.nextVisitId}` })
          break
        case 'Reviewed':
          sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}?tabIndex=1`)
          this.$router.push({ path: `patients/visit/${obj.nextVisitId}?tabIndex=1` })
          break
        case 'InProgress':
          if (this.subscription.features.$values.includes('Survey')) {
            sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}`)
            this.$router.push({ path: `patients/visit/${obj.nextVisitId}` })
          }
          if (this.subscription.features.$values.includes('Report')) {
            sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}?tabIndex=3`)
            this.$router.push({ path: `patients/visit/${obj.nextVisitId}?tabIndex=3` })
          }
          break
        case 'InProtocol':
          // sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}?tabIndex=3`)
          // this.$router.push({ path: `patients/visit/${obj.nextVisitId}?tabIndex=3` })
          break
        case 'Completed':
          sessionStorage.setItem('selectedPage', `patients/visit/${obj.nextVisitId}?tabIndex=2`)
          this.$router.push({ path: `patients/visit/${obj.nextVisitId}?tabIndex=2` })
          break
      }
    },
    async fetchQuickFiltersSummary() {
      try {
        const { followUpDateFrom, followUpDateTo } = this.query;

        const formattedDateFrom = moment.utc(followUpDateFrom).format('MM/DD/YYYY');
        const formattedDateTo = moment.utc(followUpDateTo).format('MM/DD/YYYY');

        const res = await this.quickFiltersSummary({ formattedDateFrom, formattedDateTo });
        this.filterCounts = res;
      } catch (error) {
        console.error('Error in fetchQuickFiltersSummary: ', error);
      }
    },
    async fetchStatusesSummary() {
      try {
        const { followUpDateFrom, followUpDateTo } = this.query;

        const formattedDateFrom = moment.utc(followUpDateFrom).format('MM/DD/YYYY');
        const formattedDateTo = moment.utc(followUpDateTo).format('MM/DD/YYYY');

        const res = await this.statusesSummary({formattedDateFrom, formattedDateTo});
        this.statusCounts = res;
      } catch (error) {
        console.error('Error in fetchStatusesSummary: ', error);
      }
    }
  },
  beforeDestroy () {
    this.SET_DATA(['patientListIsLoaded', false])
  },
  computed: {
    ...mapState('patients', {
      patientItems (state) {
        const items = Object.assign([], state.patients?.data?.$values)
        return items
      },
      total (state) {
        return state.patients?.pagination?.total
      },
      patientPageCount () {
        return (this.itemsPerPage) ? Math.ceil(this.total / this.itemsPerPage) : 1
      },
      patients (state) {
        return state.patients
      },
    }),
    headers() {
      return [
        { text: 'Name', value: 'name', align: 'Left', sortable: true, filterable: false,  isIcon: true, action: true, tag: 'input', width: 30, type: 'String' },
        { text: 'Evaluation Status', value: 'status', align: 'Left', sortable: true, filterable: true, width: '215', type: 'Enum', isFiltered: false },
        { text: 'Evaluation Date', value: 'nextVisitDate', align: 'Left', sortable: true, filterable: true, width: '195', type: 'Date', isFiltered: false },
        { text: 'In protocol', value: 'lastVisitDate', align: 'Left', sortable: true, filterable: false, width: '120', type: '' },
        { text: 'phone', value: 'number', align: 'Left', sortable: true, filterable: false, width: 20, type: 'String' },
        { text: 'e-mail', value: 'email', align: 'Left', sortable: true, filterable: false, width: '90', type: 'String' },
        { text: '', value: 'actions', isSlotable: true, width: '40', sortable: false }
      ].map(el => ({
        ...this._.omit(el, ['skip', '$id']),
        isSlotable: IS_SLOTABLE.includes(el.value),
        width: (this._.isNumber(el.width)) ? el.width + '%' : el.width
      })).filter(el => !IS_FILTERABLE.includes(el.value));
    },
    patientListIsLoaded () {
      return this.$store.state.patients?.patientListIsLoaded
    },
    subscription () {
      return this.$store.state.subscription
    },
    getAllFunc: () => GET_ALL_PACIENTS,
    dialogs () {
      return this.$store.state.dialogs
    },
    qFilterBtns () {
      return this.$store.state.settings?.patients?.quickFilters?.$values
    },
    statuses () {
      return this.$store.state.settings?.patients?.statuses?.$values
    },
    actionsWithDeactivate () {
      return this.defaultButtonActionsWithDeactivate
    },
    actionsWithActivate () {
      return this.defaultButtonActionsWithActivate
    },
    extendedActionsWithDeactivate () {
      console.log('extendedActionsWithDeactivate', this.subscription)
      if (this.subscription.features.$values.includes('Portal')) {
        return [
          { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
          { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
          { icon: 'mdi-trash-can-outline', color: 'grey', name: 'Deactivate', type: 2 },
          { icon: 'mdi-account-plus', color: 'grey', name: 'Invite Patient to join Portal', type: 3 }
        ]
      } else {
        return [
          { icon: 'mdi-eye-outline', color: 'grey', name: 'View patient', type: 0 },
          { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
          { icon: 'mdi-trash-can-outline', color: 'grey', name: 'Deactivate', type: 2 },
        ]
      }
    },
    extendedActionsWithActivate () {
      return this.extendedButtonActionsWithActivate
    },
    rowPerPageItems: () => ([5, 10, 15, 20, 'All'])
  }
}
</script>

<style lang="scss" scoped>
  .patients {
    background-color: #F7FAFC;
    //height: 100vh;
    height: 100%;
    min-height: 100px;

    .view-visit {
      ::v-deep .container {
        padding: 12px;
      }
    }

    ::v-deep .container {
      padding: 22px 37px;
    }

    .loading {
      height: 100%;
      min-height: 100px;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
    }
    ::v-deep .v-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
    }
    .next-visit {
      &__add {
        ::v-deep .v-btn {
          border: 1px solid $dark-green !important;

          .v-btn__content {
            color: $dark-green;
            font-weight: 500 !important;
            font-size: 12px;
            line-height: 10px;
            letter-spacing: 0;
          }
        }
      }
      &__exist {
        max-width: 145px;
        .link {
          width: auto;
          color: #425466;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding-left: 4px;
        }
        &:hover {
          background: #F7FAFC;
          border-radius: 8px;
          padding: 4px 0;
        }
      }
    }
    &__quick-filter {
      height: 48px;
      padding: 2px;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__total {
      color: #1F2D3D;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      span.num {
        color: $dark-green;
      }
    }
    .v-icon {
      cursor: pointer;
    }
    ::v-deep .v-btn {
      border-radius: 8px;
      &__content {
        font-weight: 600;
      }
    }
    ::v-deep .v-text-field {
      margin: 0;
      padding: 0;
      &__details {
        margin-bottom: 4px;
      }
    }
    .patient-table {
      &__name {
        //color: #2D3748;
        color: rgba(0, 0, 0, .87);
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        white-space: nowrap;
      }

      ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
        height: 4px;
      }
      ::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        border-radius: 10px;
      }

      ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
        background-color: #CBD5E0 !important;
        border-radius: 10px;
      }

      ::v-deep .v-data-table {
        &__wrapper {
          .v-data-table-header {
            .search-input-wrapper {
              .input.search-input {
                .v-input__slot {
                  position: relative;
                  height: 36px !important;
                  background-color: #EDF2F7 !important;
                  border: none !important;
                  box-shadow: none;
                  border-radius: 8px;
                  padding: 10px 45px 10px 40px !important;

                  .v-input__prepend-inner {
                    position: absolute;
                    top: 50%;
                    left: 17px;
                    transform: translateY(-50%);
                    margin: 0;
                    padding-top: 5px;
                  }

                  fieldset {
                    padding: 0;
                  }
                }
              }
            }
            .search-input-wrapper + .v-data-table-header__icon {
              display: none;
            }
            &__icon {
              height: 36px;
              padding-bottom: 1px;
              opacity: 1;

              &::before {
                content: url('~@/assets/icons/arrow-up-icon.svg');
              }
            }
            th {
              border-bottom: 1px solid #F7FAFC;
              padding: 8px !important;

              &:not(:last-child) {
                border-right: 1px solid  #F7FAFC;
              }
              div {
                color: #8492A6!important;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                &.column-title {
                  height: 36px;
                  align-items: center;
                }
              }
            }
          }
          tbody {
            tr {
              transition: background-color 0.3s ease;

              &:hover {
                background-color: #F7FAFC !important;
              }

              td {
                border-bottom: thin solid #F7FAFC !important;

                &:not(:last-child) {
                  border-right: 1px solid  #F7FAFC;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      height: 60px;
      ::v-deep .v-input {
        .v-messages {
          //display: none;
        }
      }
      .row-per-page {
        font-size: 14px;
        color: #718096;
        &__list {
          width: 60px;
        }
        ::v-deep .v-menu__content {
          min-width: 62px !important;
          .v-list-item {
            &__title {
              display: flex;
              justify-content: center;
            }
          }
        }
        ::v-deep .v-input {
          &__slot {
            margin: 0 !important;
          }
          &__append-inner {
            margin-left: 0;
            padding-left: 0;
            margin-top: 2px !important;
          }
        }
        ::v-deep .v-select {
          input {
            display: none;
          }
          &__selection {
            font-size: 14px;
            color: #718096;
          }
          &__selections {
            flex: unset !important;
            line-height: 17px;
            margin-left: 5px;
          }
        }
        .select {
          ::v-deep .v-input {
            &__control {
              .v-text-field__details {
                display: none!important;
              }
            }
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
      }

      .select {
        ::v-deep .v-select {
          border: none;
          box-shadow: none;
        }

        ::v-deep .v-input {
          .v-select__slot {
            .v-input__append-inner {
              .v-input__icon {
                .v-icon {
                  background: transparent;
                  border-radius: 0;
                  color: #718096;

                  &::before {
                    content: url('~@/assets/icons/chevron-down.svg');
                    display: inline-block;
                    margin-bottom: 2px;
                    border: none;
                  }
                }
              }
            }
          }

          &.v-select--is-menu-active {
            .v-select__slot {
              .v-input__append-inner {
                .v-input__icon {
                  .v-icon {
                    &::before {
                      margin-bottom: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .switch {
        span {
          color: #718096;
          font-weight: 400;
          font-size: 14px;
          line-height: 23px;
          margin-right: 16px;
        }
      }
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
    .patient-is-registered {
      border-radius: 50%;
      border: 2px solid green;
    }

    ::v-deep .status {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px !important;
      text-align: center !important;

      .v-chip {
        height: auto;
        justify-content: center !important;
        border-radius: 8px !important;
        background: unset !important;
        color: unset !important;
        padding: 4px 10px;
        cursor: pointer !important;
      }
    }
  }

  .patient-controls {
    ::v-deep .v-input {
      &__slot {
        box-shadow: none;
        padding-left: 40px !important;
      }

      &__prepend-inner {
        padding: 0;
        margin: 1px 0 0;
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
      }
    }
  }

  @media (max-width: 767px) {
    .patients {
      .next-visit {
        &__add {
          ::v-deep .v-btn {
            min-width: auto;

            .v-btn__content {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
