<template>
  <div class="reports">
    <v-row no-gutters style="margin-left: 6px">
      <v-col cols="12">
        <v-row no-gutters>
          <div class="report-label">Systems Survey Form 212 Q</div>
        </v-row>
        <div class="main-row mt-5">
          <div v-for="(item, index) in settingsForm.$values"
               :ref="item.code"
               :key="item.$id"
               class="btn-report"
               @click="getReport(item.code, item.code)"
               :class="{ 'report-active': index === 0 }"
          >
            <img :src="require(`@/assets/images/${item.code}.jpg`)" alt="report image" class="report-image" />
            <span class="report-title">{{ extractText(item.name) }}</span>
          </div>
        </div>
        <div class="reports__result mt-5">
          <div class="options">
            <div class="check-box-group">
              <div class="check-box-additional">
                <v-checkbox
                  style="margin-top: 0;padding-top: 0"
                  color="#C9CED6"
                  v-model="includeCurrentPatient"
                  :disabled="disabledCheckBox"
                  label="Include Patient"
                ></v-checkbox>
              </div>
              <div class="check-box-additional">
                <v-checkbox
                  style="margin-top: 0;padding-top: 0"
                  color="#C9CED6"
                  v-model="includeCurrentDoctor"
                  :disabled="disabledCheckBox"
                  label="Include Doctor"
                ></v-checkbox>
              </div>
              <div class="check-box-additional">
                <v-checkbox
                  style="margin-top: 0; padding-top: 0"
                  color="#C9CED6"
                  v-model="includeGroupDescriptions"
                  :disabled="disabledCheckBox"
                  label="Show Group Name"
                ></v-checkbox>
              </div>
            </div>
            <div class="actions">
              <div class="action-btn-report">
                <img @click="downloadFile" :src="getImgWithExt(['icons', 'report-download-icon', 'svg'])" alt="icon" />
              </div>
              <div class="action-btn-report">
                <img @click="print" :src="getImgWithExt(['icons', 'report-print-icon', 'svg'])" alt="icon" />
              </div>
              <div class="action-btn-report">
                <img @click="sendToPatientEmail" :src="getImgWithExt(['icons', 'report-send-email-icon', 'svg'])" alt="icon" />
              </div>
            </div>
          </div>
          <div v-if="dialogs.openReportPdfView && dialogs.openReportPdfView.isOpen && initialDoc" class="container-iframe">
            <iframe
              :src="initialDoc"
              class="mt-3"
              width="100%"
              id="pdfDocument">
            </iframe>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{ popupMessage }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Mapping from '@/mixins/Mapping'
import Image from '@/mixins/Image'

export default {
  name: 'reports',
  mixins: [ Mapping, Image ],
  data: () => ({
    initialDoc: null,
    activeLanguage: 'en',
    items: [1, 2, 3, 4, 5],
    includeCurrentPatient: false,
    includeCurrentDoctor: false,
    includeGroupDescriptions: false,
    disabledCheckBox: true,
    fileUrl: null,
    file: null,
    alertIsOpen: false,
    popupMessage: ''
  }),
  mounted() {
    if (this.settingsForm.$values.length > 0) {
      this.getReport(this.settingsForm.$values[0].code, this.settingsForm.$values[0].code);
    }
  },
  methods: {
    ...mapActions('patients', ['getPatient']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('reports', ['gerReportSurveyFormByCode', 'sendSurveyForm']),

    openReport () {
      this.initialDoc = 'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: 'Report' })
    },

    switchLanguage (lang) {
      this.activeLanguage = lang
    },

    async getReport (ref, reportCode) {
      Array.from(document.getElementsByClassName('btn-report')).forEach(item => {
        item.classList.remove('report-active')
      })
      this.$refs[ref][0].classList.add('report-active')
      this.disabledCheckBox = false
      localStorage.setItem('reportCode', reportCode)
      localStorage.setItem('reportName', this.upperCaseFirst(this.splitByUpperCase(ref)))
      const payload = {
        reportCode,
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.getReportNameByCode[ref]}` })
    },
    parseFile (file) {
      // this.initialDoc = null
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.fileUrl = URL.createObjectURL(file)
          this.initialDoc = this.fileUrl
          /* const self = this
          setTimeout(function () {
            const element = document.createElement('iframe')
            element.src = self.initialDoc
            element.id = 'print-frame'
            element.style.display = 'none'
            const body = document.getElementsByTagName('body')[0]
            body.appendChild(element)
            element.contentWindow.print()
          }, 1000) */
        }
        reader.readAsDataURL(file)
      } else {
        this.fileUrl = null
      }
    },
    splitByUpperCase (str) {
      return str.split(/(?=[A-Z])/).join(' ')
    },
    upperCaseFirst (str) {
      return str[0].toUpperCase() + str.slice(1)
    },

    async downloadFile () {
      if (!this.isMultipleReports) {
        const url = this.initialDoc // window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        document.getElementsByTagName('body')[0].appendChild(link)
        link.href = url
        link.download = 'Report.pdf'

        link.click()
        document.getElementsByTagName('body')[0].removeChild(link)
      } else {
        this.$emit('downloadMultipleReports')
      }
    },
    print () {
      const doc = document.getElementById('pdfDocument')
      doc.contentWindow.print()
    },
    async sendToPatientEmail () {
      const data = {
        reportCode: localStorage.getItem('reportCode'),
        patientId: this.patient.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: this.includeGroupDescriptions
      }

      const res = await this.sendSurveyForm(data)
      if (res.success) {
        this.alertIsOpen = true
        this.popupMessage = 'Survey Form was send successfully to Patient Email'
      }
    },
    extractText(name) {
      if (name.includes('Extended')) {
        const match = name.match(/(Extended \(.+\))/);
        return match ? match[1] : name;
      } else {
        const match = name.match(/\(([^)]+)\)$/);
        return match ? match[1] : name;
      }
    }
  },
  watch: {
    async includeCurrentPatient (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: val,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    async includeCurrentDoctor (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: val,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    async includeGroupDescriptions (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: val
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  },
  computed: {
    ...mapState({
      settingsForm: state => state.settings.patients.forms,
      patient: state => state.patients.patient
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.reports {
  padding: 20px 0;

  .language-switcher {
    border: 1px solid #f1f1f1;
    border-radius: 18px;
    padding: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    .button {
      ::v-deep .v-btn {
        background-color: rgba(255, 255, 255, 0.64) !important;
        color: black!important;
        font-weight: 600!important;
      }
    }
    .button.active-language {
      ::v-deep .v-btn {
        background-color: #4faa6e !important;
        color: white!important;
        font-weight: 600!important;
      }
    }
  }
  .report-label {
    color: #27272E;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .main-row {
    display: flex;
    gap: 20px;

    .btn-report {
      position: relative;
      width: 100%;
      height: 115px;
      border-radius: 6px;
      box-shadow: 0px 10px 20px rgba(113, 128, 150, 0.06);
      cursor: pointer;
      overflow: hidden;

      .report-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }

      .report-title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #E2E8F0;
        display: block;
        text-align: center;
        padding: 10px;
        color: #008C89;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
      }

      &.report-active {
        border: 2px solid rgba(0, 140, 137, 0.5);

        .report-title {
          background-color: #008C89;
          color: #FFFFFF;
        }
      }

      .custom-select {
        width: 50px;
        height: 21px;
        ::v-deep .v-input__control {
          min-height: unset!important;
          .v-text-field__details {
            display: none!important;
          }
          .v-input__slot {
            height: 21px!important;
            background: transparent!important;
            box-shadow: unset!important;
            padding: 0!important;
          }
        }
      }
      .select-label {
        position: absolute;
        top: -27px;
        right: -50px;
        font-weight: 500;
        font-size: 12px;
        color: #718096;
        width: max-content;
      }
      .bold {
        font-weight: 600;
        padding-left: 5px;
      }
      .additional-filters {
        display: flex;
        column-gap: 1rem;
      }
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }

  &__result {
    background-color: #EDF2F7;
    padding: 20px;

    .options {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      column-gap: 10px;

      .check-box-group  {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
      }
      .check-box-additional {
        ::v-deep .v-input {
          border-radius: 8px;
          padding: 10px !important;

          &.v-input--is-label-active {
            background-color: #F7FAFC;

            i {
              color: $dark-green !important;
              caret-color: $dark-green !important;
            }
          }

          .v-input__control {
            .v-input__slot {
              margin: 0;

              .v-input--selection-controls__input {
                width: 16px;
                height: 16px;
                margin-right: 12px;

                i {
                  color: #C9CED6;
                }

                .v-input--selection-controls__ripple {
                  width: 16px;
                  height: 16px;
                  left: 0;
                  top: calc(50% - 8px);
                  margin: 0;
                }

              }
            }
            .v-messages {
              display: none
            }
          }
          .v-label {
            color: #425466;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            white-space: nowrap;

            &.v-label--is-disabled {
              color: grey !important;
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .action-btn-report {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background-color: #F7FAFC;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 70.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1496px) and (min-width: 1199px) {
  .reports .main-row .btn-report {
    height: 145px;
  }
}

@media (max-width: 1198px) {
  .reports .main-row .btn-report {
    height: 155px;
  }
}
</style>
