<template>
  <div class="input" :class="inputClass">
    <v-text-field
      v-bind="$props"
      v-on="inputListeners"
      error-count="3"
      :style="styles"
      ref="textFieldInput"
      >
      <template v-slot:append>
        <slot name="append"></slot>
      </template>

      <template v-slot:prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    inputClass: { default: '', type: String },
    appendIcon: String,
    autocomplete: String,
    backgroundColor: { default: '#EDF2F7', type: String },
    clearable: Boolean,
    color: { default: '#3486e3', type: String },
    disabled: Boolean,
    errorMessage: { type: [String, Array, Number] },
    height: { default: 36, type: [String, Number] },
    hint: String,
    label: String,
    outlined: Boolean,
    persistentHint: Boolean,
    placeholder: String,
    prependIcon: String,
    prependInnerIcon: String,
    radius: [Number, String],
    readonly: Boolean,
    rules: [Array, String, Object],
    tabindex: String,
    type: String,
    value: {
      type: [String, Number]
    },
    min: {
      type: [Number, String]
    },
    max: {
      type: [Number, String]
    },
    isAutofocus: {
      type: Boolean,
      default: false
    },
    isDynamicRenderInput: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    firstRender: false,
  }),
  computed: {
    inputListeners: function () {
      const vm = this

      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            vm.$emit('input', event)
          },
          keydown: function (event) {
            if (event.keyCode === 13) {
              event.preventDefault()
              vm.$emit('enter')
            }
            if (vm.isDynamicRenderInput && event.keyCode === 9) {
              event.preventDefault()
              vm.$emit('tab')
            }
            if (vm.isDynamicRenderInput && event.keyCode === 16) {
              event.preventDefault()
              vm.$emit('shift')
            }
          }
        }
      )
    },

    styles () {
      return {
        'border-radius': this.radius + 'px'
      }
    }
  },
  mounted() {
    if (this.isAutofocus && !this.firstRender) {
      this.$nextTick(() => {
        this.$refs.textFieldInput?.$el.querySelector('input[type="text"]')?.focus();
        this.firstRender = true;
      });
    }
  },
}
</script>

<style lang="scss" scoped>
  .input {
    ::v-deep .v-input {
      //.v-input__slot {
      // box-shadow: 0px 0px 0px 0.01em rgba(27, 28, 26, 0.8); border color in all inputs
      //}
      &--is-focused {
        .v-input__slot {
          box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
          border: none !important;
        }
        .v-input__control {
          .v-input__slot {
            background-color: transparent!important;
          }
        }
      }
      &__slot {
        border: 1px solid transparent;
        box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
        border-color: #E7E7E7;
        min-height: auto !important;
        margin-bottom: 0px;
        padding: 0 16px !important;
        &::before,
        &::after {
          display: none;
        }
        fieldset {
          border: none !important;
        }
        input {
          color: #7A828A;
          &::placeholder {
            color: #7A828A; //#7A828A;
            font-weight: 500;
            font-size: 15px;
          }
        }
      }
      &__append-inner {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      &--is-label-active {
        .v-input__slot {
          border: 0px!important; // 1px solid #8CDFB3 !important;
          background-color: transparent!important; // green!important;
          input {
            color: #425466; //#4AAE8C;
          }
        }
      }
      &.error {
        &--text {
          .v-input__slot {
            border: 0px!important;
            background: $error-input-bg-color!important;
            input {
              color: $error-input-text-color;
              &::placeholder {
                color: $error-input-text-color;
              }
            }
          }
        }
      }
      &--is-label-active.error--text {
        .v-input__slot {
          border: 0px!important;
          background: $error-input-bg-color!important;
          input {
            color: $error-input-text-color;
            &::placeholder {
              color: $error-input-text-color;
            }
          }
        }
      }
    }
  }
  .input:not(.login) {
    ::v-deep .v-input {
      &--is-label-active:not(.login) {
        .v-input__slot {
          background: #DEFFEE !important;
        }
      }
    }
  }
  .input {
    ::v-deep .v-input {
      &--is-label-active:not(.login .registration) {
        .v-input__slot {
          border: 1px solid #cdcdcd!important;
          background: transparent!important; // $success-input-bg-color!important;
          input {
            color: #424040!important; // $success-input-text-color!important;
          }
        }
      }
      &--is-label-active.error--text:not(.login .registration) {
        .v-input__slot {
          border: 0px!important;
          background: $error-input-bg-color!important;
          input {
            color: $error-input-text-color!important;
            &::placeholder {
              color: $error-input-text-color;
            }
          }
        }
      }
      &--is-disabled:not(.login .registration) {
        .v-input__slot {
          border: 1px solid #cdcdcd!important; // 1px solid #8CDFB3 !important;
          background-color: transparent!important; // green!important;
          input {
            color: #A0AEC0!important; //#4AAE8C;
          }
        }
      }
    }
  }
</style>
