<template>
  <div style="display: flex; flex-direction: column; height: 100%">
<!--    <v-app-bar app height="95" :color="appColor">
      <profile />
    </v-app-bar>-->
    <app-bar-desktop class="desktop-version"
                     :leftMenuIsOpen="drawerState"
                     @toggleMenu="drawerMenu=$event"
                     :value="drawerMenu"
    />

    <drawer :items="menu" @toggleDrawer="checkToggleDrawerState"/>

    <v-navigation-drawer
      v-model="drawerMenu"
      app
      right
      temporary
      style="top: 57px"
      height
    ><!--absolute height :style="{'top':'50px'}"-->
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title @click="gotTo('/settings')">Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main app :style="styles">
      <template v-if="$route.name === 'Home'">
        <HomeDashboard/>
      </template>
      <template v-else>
        <router-view />
      </template>
    </v-main>

<!--    <v-footer app class="d-flex justify-center" style="text-align: center;z-index: 6">
      <span style="font-size: 11px;">Need to place FDA warning in the footer of all pages, as well as Maestro Nutrition logo and info, copyright, phone, email, terms and conditions, etc. (Statements herein have not been evaluated by the Food and Drug Administration. Products and recommendations are not intended to diagnose, treat, cure, or prevent any disease.)</span>
    </v-footer>-->
  </div>
</template>

<script>
import fakeData from '@/assets/fakeData'

import drawer from '@/components/layouts/drawer'
// import profile from '@/components/layouts/profile'
import AppBarDesktop from '@/components/layouts/App-bar-desktop'
import HomeDashboard from '@/views/home-dashboard'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Index',
  components: { drawer, AppBarDesktop, HomeDashboard }, // profile
  data: () => ({
    ...fakeData,
    drawerState: true,
    drawerMenu: false
  }),
  mounted () {
    // this.getPatients({
    //   PageIndex: 1,
    //   PageSize: 10,
    //   order: [{ column: 'name', direction: 'desc' }]
    // })
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['SET_DATA']),
    // ...mapActions('patients', ['getPatients']),
    checkToggleDrawerState (value) {
      this.drawerState = value
      this.SET_DATA(['drawerState', value])
    },
    gotTo (path) {
      this.$router.push({ path })
    }
  },
  computed: {
    appColor () {
      return (this.$route?.params?.id) ? '#F7FAFC' : '#FFF'
    },
    styles () {
      return {
        padding: this.drawerState ? '57px 0px 0px 180px' : '57px 0px 0px 56px' // 95 0 36 180/56
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/fonts.scss';

  .v-application {
    &--wrap {
      header {
        border: none !important;
      }
      .v-main {
        //height: 100vh;
      }
      .v-toolbar {
        box-shadow: unset !important;
        .profile {
          &__title {
            font-weight: 600;
            font-size: 28px;
            color: #27272E;
          }
          &__logout {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
