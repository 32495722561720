<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <template v-for="(item, index) in items">
        <v-stepper-step
          :key="`step-${index}`"
          :step="index + 1"
        >
          {{ item.title }}
        </v-stepper-step>
        <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    items: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-stepper {
  box-shadow: none !important;
  background-color: rgba(208, 220, 255, 0.5);
  border-radius: 25px;

  &__header {
    height: auto;
    box-shadow: none !important;
  }

  &__step {
    padding: 13px 25px 13px 14px;

    ::v-deep .v-stepper__label {
      text-shadow: none;
      color: #9FB6FF;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
    }

    ::v-deep .v-stepper__step__step {
      background-color: #FFFFFF !important;
      border-color: #FFFFFF !important;
      color: #9FB6FF;
      font-size: 14px;
      font-weight: 600;
    }

    & + ::v-deep .v-divider {
      border-color: #9FB6FF !important;
      flex: 0 0 30px;
    }

    &:not(.v-stepper__step--inactive) {
      ::v-deep .v-stepper__step__step {
        background-color: #668BFF !important;
        border-color: #668BFF !important;
        color: #FFFFFF;
      }
      ::v-deep .v-stepper__label {
        color: #537CFF !important;
        text-shadow: none;
      }
      & + ::v-deep .v-divider {
        border-color: #537CFF !important;
      }
    }
  }
}
</style>
