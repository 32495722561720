<template>
  <div class="message-component">
    <div class="wrap">
      <div class="d-flex"
           v-if="!owner && messageData.messageType !== 0">
        <div style="margin-top: 30px">
          <Img :src="getImgWithExt(['icons', 'system-avatar', 'svg'])" alt="system avatar" />
        </div>
        <div class="message-wrap__column">
          <div class="message-wrap__column-top">
            <span>System</span>
            <div class="time">
              {{ messageTime }}
            </div>
          </div>
          <div  class="system-message"
                :class="getClassByMessageType">
            {{ getTextByMessageType }}
          </div>
        </div>
      </div>
      <div class="message-wrap" :class="{'owner-line-msg': owner}">
        <div class="avatar" v-if="!owner && messageData.srcMemberName" style="margin-top: 30px;">
          <v-avatar>
            <v-img :src="getAvatarById(messageData.srcMemberId)"></v-img>
          </v-avatar>
        </div>
        <div v-else-if="!owner && messageData.srcMemberName == null" style="margin-top: 30px">
          <Img :src="getImgWithExt(['icons', 'system-avatar', 'svg'])" alt="system avatar" />
        </div>
        <div class="message-wrap__column">
          <div class="message-wrap__column-top">
            <span v-if="!owner && messageData.srcMemberName">{{ messageData.srcMemberName }}</span>
            <span v-else-if="!owner && messageData.srcMemberName == null">System</span>
            <div class="time">
              {{ messageTime }}
            </div>
          </div>
          <div class="message" :class="{ owner, 'system': messageData.srcMemberName == null }">
            <p v-html="formatMessage(messageData.message)"></p>
            <div class="is-read-msg" v-show="owner && messageData.isRead">
              <Img :src="getImgWithExt(['icons', 'read-icon', 'svg'])" class="read icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Image from "@/mixins/Image";
import Img from '@/components/elements/Img'

export default {
  name: 'Message',
  mixins: [Image],
  components: { Img },
  props: {
    messageData: { type: Object },
    owner: { type: Boolean, default: false },
    avatarsAndLogos: { type: Object }
  },
  methods: {
    getAvatarById (id) {
      return this.avatarsAndLogos[id] || this.getImgWithExt(['icons', 'avatar-default', 'svg'])
    },
    formatMessage(text) {
      return text.replace(/\n/g, '<br>')
    }
  },
  computed: {
    getClassByMessageType () {
      return {
        'survey-sent': this.messageData.messageType === 1,
        'in-protocol': this.messageData.messageType === 2
      }
    },
    getTextByMessageType () {
      switch (this.messageData.messageType) {
        case 1:
          return 'Survey sent'
        case 2:
          return 'In protocol'
        default:
          return ''
      }
    },
    messageTime () {
      const createdOn = this.messageData.createdOn

      if (createdOn.endsWith('Z')) {
        return new Date(createdOn).toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })
      } else {
        return new Date(createdOn).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })
      }

      // const diffHour = new Date().getUTCDay()
      // return moment(new Date(this.messageData.createdOn).setHours(diffHour + new Date(this.messageData.createdOn).getHours())).format('LT')
    }
  }
}
</script>

<style scoped lang="scss">
.message-component {
  .wrap {
    display: flex;
    flex-direction: column;
    padding-top: 14px;

    .message-wrap {
      display: flex;

      &__column {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-left: 15px;

        &-top {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          span {
            color: #4A5568;
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            margin-right: 9px;
          }
        }
      }
    }

   .owner-line-msg {
     align-self: end;
     justify-content: end;
     width: 100%;
     padding-right: 20px;

     .message-wrap__column {
       display: flex;
       flex-direction: column;
       align-items: flex-end;
       margin-left: 0;
     }
   }

    .system-message {
      padding: 10px 20px;
      border-radius: 18px;
      width: fit-content;
      font-size: 16px;
      font-weight: 400;
    }

    .survey-sent {
      background: rgba(0, 140, 137, 0.16);
      color: #008C89;
    }

    .in-protocol {
      background-color: rgba(208, 220, 255, 0.5);
      color: #668BFF;
    }

    .message {
      position: relative;
      background-color: #EDF2F7;
      border-radius: 18px;
      padding: 10px 20px;

      &.owner {
        background-color: rgba(0, 140, 137, 0.16);
        align-self: flex-end;
      }

      &.system {
        background-color: rgba(208, 220, 255, 0.5);
      }

      p {
        color: #2D3748;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        max-width: 280px;
      }

      .is-read-msg {
        position: absolute;
        width: 16px;
        height: 16px;
        right: -20px;
        bottom: 0;
      }
    }
  }

  .time {
    color: #718096;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  .avatar {
    ::v-deep .v-avatar {
      width: 36px !important;
      height: 36px !important;
      min-width: 36px !important;
    }
  }
}
</style>
