const Validation = {
  computed: {
    getStatusCodeName () {
      return {
        InActive: {
          name: 'Inactive',
          class: 'in-active',
          description: (param) => {
            return 'Patient was set inactive'
          }
        },
        'In Active': {
          name: 'Inactive',
          class: 'in-active',
          description: (param) => {
            return 'Patient was set inactive'
          }
        },
        'In active': {
          name: 'Inactive',
          class: 'in-active',
          description: (param) => {
            return 'Patient was set inactive'
          }
        },
        InProgress: {
          name: 'In Progress',
          class: 'in-progress',
          description: (param) => {
            return 'The evaluation is in progress'
          },
          background: 'rgba(208, 220, 255, 0.5)',
          text: '#668BFF'
        },
        NeedSurvey: {
          name: 'Need Survey',
          class: 'need-survey',
          description: (param) => {
            return 'Patient has no evaluation date'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#F16063'
        },
        'Need Survey': {
          name: 'Need Survey',
          class: 'need-survey',
          description: (param) => {
            return 'Patient has no evaluation date'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#F16063'
        },
        'Need survey': {
          name: 'Need Survey',
          class: 'need-survey',
          description: (param) => {
            return 'Patient has no evaluation date'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#F16063'
        },
        NeedReeval: {
          name: 'Need Reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days since last evaluation`
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#D46A51'
        },
        'Need Reeval': {
          name: 'Need Reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days since last evaluation`
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#D46A51'
        },
        'Need reeval': {
          name: 'Need Reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days since last evaluation`
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#D46A51'
        },
        SurveySent: {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: (param) => {
            return 'Waiting for Survey Completion'
          },
          background: 'rgba(0, 140, 137, 0.16)',
          text: '#008C89'
        },
        'Survey Sent': {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: (param) => {
            return 'Waiting for Survey Completion'
          },
          background: 'rgba(0, 140, 137, 0.16)',
          text: '#008C89'
        },
        'Survey sent': {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: (param) => {
            return 'Waiting for Survey Completion'
          },
          background: 'rgba(0, 140, 137, 0.16)',
          text: '#008C89'
        },
        SurveyDue: {
          name: 'Evaluation Due',
          class: 'survey-due',
          description: (param) => {
            return 'Evaluation is due'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Survey Due': {
          name: 'Evaluation Due',
          class: 'survey-due',
          description: (param) => {
            return 'Good as is'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Survey due': {
          name: 'Evaluation Due',
          class: 'survey-due',
          description: (param) => {
            return 'Good as is'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        NeedReview: {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return 'Survey Ready to Review'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Need Review': {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return 'Survey Ready to Review'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Need review': {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return 'Survey Ready to Review'
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        Reviewed: {
          name: 'Reviewed',
          class: 'reviewed',
          description: (param) => {
            return 'Ready to Consult Patient'
          },
          background: 'rgba(0, 140, 137, 0.16)',
          text: '#008C89'
        },
        InProtocol: {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `Patient is in protocol for ${param} more days`
          },
          background: 'rgba(208, 220, 255, 0.5)',
          text: '#668BFF'
        },
        'In Protocol': {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `Patient is in protocol for ${param} more days`
          },
          background: 'rgba(208, 220, 255, 0.5)',
          text: '#668BFF'
        },
        'In protocol': {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `Patient is in protocol for ${param} more days`
          },
          background: 'rgba(208, 220, 255, 0.5)',
          text: '#668BFF'
        },
        ProtocolEnding: {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol is about to end`
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        protocolEnding: {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol is about to end`
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        'Protocol Ending': {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol Ending`
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        Completed: {
          name: 'Closed',
          class: 'completed',
          description: (param) => {
            return 'The visit is finalized. All activities were completed'
          },
          background: '#EBF2FA',
          text: '#A0AEC0'
        },
        Closed: {
          name: 'Closed',
          class: 'completed',
          description: (param) => {
            return 'The visit is finalized. All activities were completed'
          },
          background: '#EBF2FA',
          text: '#A0AEC0'
        },
        NeedProduct: {
          name: 'Need Product',
          class: 'need-product',
          description: (param) => {
            return 'Need Product Need Product Need Product Need Product Need Product'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        'Need Product': {
          name: 'Need Product',
          class: 'need-product',
          description: (param) => {
            return 'Need Product Need Product Need Product Need Product Need Product'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        'Need product': {
          name: 'Need Product',
          class: 'need-product',
          description: (param) => {
            return 'Need Product Need Product Need Product Need Product Need Product'
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        completed: {
          name: 'Completed',
          class: 'completed',
          description: (param) => {
            return ''
          },
          background: '#EBF2FA',
          text: '#A0AEC0'
        },
        canceled: {
          name: 'Canceled',
          class: 'canceled',
          description: (param) => {
            return ''
          },
          background: 'rgba(255, 230, 228, 0.7)',
          text: '#CF4655'
        },
        EvaluationDue: {
          name: 'Evaluation Due',
          class: 'evaluation-due',
          description: (param) => {
            return ''
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Evaluation Due': {
          name: 'Evaluation Due',
          class: 'evaluation-due',
          description: (param) => {
            return ''
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },
        'Evaluation due': {
          name: 'Evaluation Due',
          class: 'evaluation-due',
          description: (param) => {
            return ''
          },
          background: 'rgba(255, 237, 227, 0.7)',
          text: '#F7936F'
        },

        Pending: {
          name: 'Pending',
          class: 'pending',
          description: (param) => {
            return 'Future Eval Scheduled'
          },
          background: '#F1E7FF',
          text: '#7952B3'
        },
        None: {
          name: '',
          class: 'none',
          description: (param) => {
            return ''
          },
          background: 'transparent',
          text: 'transparent'
        },
      }
    },
    getStatuses () {
      return [
        {
          statusCode: 'None',
          statusName: 'None'
        },
        {
          statusCode: 'InActive',
          statusName: 'Inactive'
        },
        {
          statusCode: 'InProgress',
          statusName: 'In Progress'
        },
        {
          statusCode: 'NeedSurvey',
          statusName: 'Need Survey'
        },
        {
          statusCode: 'NeedReeval',
          statusName: 'Need Reeval'
        },
        {
          statusCode: 'SurveySent',
          statusName: 'Survey Sent'
        },
        {
          statusCode: 'SurveyDue',
          statusName: 'Evaluation Due'
        },
        {
          statusCode: 'NeedReview',
          statusName: 'Need Review'
        },
        {
          statusCode: 'Reviewed',
          statusName: 'Reviewed'
        },
        {
          statusCode: 'InProtocol',
          statusName: 'In Protocol'
        },
        {
          statusCode: 'ProtocolEnding',
          statusName: 'Protocol Ending'
        },
        {
          statusCode: 'NeedProduct',
          statusName: 'Need Product'
        }
      ]
    },
    getEventType () {
      return {
        0: {
          name: 'Create'
        },
        1: {
          name: 'Read'
        },
        2: {
          name: 'Update'
        },
        3: {
          name: 'Delete'
        }
      }
    },
    getReportNameByCode () {
      return {
        SurveyForm2PCEng: 'Survey Form 2 Pages (English)',
        SurveyForm2PCSpn: 'Survey Form 2 Pages (Spanish)',
        SurveyForm3PCEng: 'Survey Form Circles (English)',
        SurveyForm3PNEng: 'Survey Form Numbers (English)'
      }
    },
    getDataAndStylesMainVisitControl () {
      return {
        Pending: {
          buttonTitle: 'Conclude Evaluation',
          class: 'in-progress',
          description: 'Consult with Patient, click to progress evaluation.',
          statusInPayloadRequest: 'InProtocol'
        },
        InProgress: {
          buttonTitle: 'Conclude Evaluation',
          class: 'in-progress',
          description: 'Consult with Patient, click to progress evaluation.',
          statusInPayloadRequest: 'InProtocol'
        },
        SurveySent: {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-sent',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Survey Sent': {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-sent',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Survey sent': {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-sent',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        SurveyDue: {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-due',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Survey Due': {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-due',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Survey due': {
          buttonTitle: 'Approve w/o Submission',
          class: 'survey-due',
          description: 'Alert! Survey not submitted by patient. Be sure it is complete before approving.',
          statusInPayloadRequest: 'Reviewed'
        },
        NeedReview: {
          buttonTitle: 'Approve Survey',
          class: 'need-review',
          description: 'Please review survey for completeness, then Approve to view results.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Need Review': {
          buttonTitle: 'Approve Survey',
          class: 'need-review',
          description: 'Please review survey for completeness, then Approve to view results.',
          statusInPayloadRequest: 'Reviewed'
        },
        'Need review': {
          buttonTitle: 'Approve Survey',
          class: 'need-review',
          description: 'Please review survey for completeness, then Approve to view results.',
          statusInPayloadRequest: 'Reviewed'
        },
        Reviewed: {
          buttonTitle: 'Conclude Evaluation',
          class: 'reviewed',
          description: 'Consult with Patient, click to progress evaluation.',
          statusInPayloadRequest: 'InProtocol'
        },
        InProtocol: {
          buttonTitle: 'End Protocol Early / Adjust Protocol',
          class: 'in-protocol',
          description: '',
          statusInPayloadRequest: 'Completed'
        },
        ProtocolEnding: {
          buttonTitle: 'End Protocol Early / Adjust Protocol',
          class: 'in-protocol',
          description: '',
          statusInPayloadRequest: 'Completed'
        },
        'In Protocol': {
          buttonTitle: 'End Protocol Early / Adjust Protocol',
          class: 'in-protocol',
          description: '',
          statusInPayloadRequest: 'Completed'
        },
        'In protocol': {
          buttonTitle: 'End Protocol Early / Adjust Protocol',
          class: 'in-protocol',
          description: '',
          statusInPayloadRequest: 'Completed'
        },
        Closed: {
          buttonTitle: 'Button invisible',
          class: 'closed',
          description: '',
          statusInPayloadRequest: null
        },
        Completed: {
          buttonTitle: 'Button invisible',
          class: 'closed',
          description: '',
          statusInPayloadRequest: null
        }
      }
    }
  }
}

export default Validation
