<template>
  <div class="add-patient">
    <Dialog width="580" :canCloseManually="false" :canCloseWithEsc="true" :showCloseIcon="true"><!--scrollable-->
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addForm" autocomplete="off">
          <template v-if="dialog.name === 'addPatient'">
            <Stepper :items="tabs" :step="tabIndex + 1"></Stepper>
          </template>
          <template v-else>
            <Tabs ref="tabsComponent" color="#008C89" :items="tabs" @update:tab="tabIndex = $event" style="margin-top: -4px"></Tabs><!--fixed-tabs-->
          </template>
         <template v-if="tabIndex == 0">
            <v-row style="margin-top: 26px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0" style="margin-right: 5px">
                <div class="label">First name<span class="required-field">*</span></div>
                <Input
                  outlined
                  autocomplete="off"
                  placeholder="First name"
                  radius="8"
                  :isAutofocus="isAutofocusFirstTab"
                  @input="form.firstName=$event"
                  :value="form.firstName"
                  :rules="[filedIsRequired.required('First Name')]"
                />
              </v-col>
              <v-col class="py-0 px-0" style="margin-left: 5px">
                <div class="label">Last name<span class="required-field">*</span></div>
                <Input
                  outlined
                  autocomplete="off"
                  placeholder="Last name"
                  radius="8"
                  @input="form.lastName=$event"
                  :value="form.lastName"
                  :rules="[filedIsRequired.required('Last Name')]"
                />
              </v-col>
            </v-row>
            <v-row style="margin-top: 8px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0">
                <div class="label">Email</div>
                <Input
                  ref="emailInput"
                  outlined
                  autocomplete="off"
                  placeholder="Email"
                  radius="8"
                  :disabled="dialog.name === 'editPatient' ? form.registerPortal : false"
                  @input="form.email=$event"
                  :value="form.email"
                  @focusout="checkEmail"
                  :rules="loginEmailRules"
                >
                  <div v-if="form.email && form.email.length > 2" slot="append" style="cursor: pointer" @click="clearField('email')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </Input>
              </v-col>
              <v-col cols="4" class="pb-0 mt-1 pt-1 d-flex align-center" v-if="!isValidEmail">
                <div v-if="!isValidEmail">
<!--                  <v-progress-circular
                    class="mr-2"
                    indeterminate
                    color="error"
                  ></v-progress-circular>-->
                  <span class="red--text">Patient with this email already exists</span>
                </div>
              </v-col>
            </v-row>
            <v-row style="margin-top: 8px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0">
                <div class="label">Assign to practitioner<span class="required-field">*</span></div>
                <Select
                  v-if="doctors"
                  outlined
                  itemText="name"
                  itemValue="id"
                  radius="8"
                  :items="doctors"
                  :value="doctors.length === 1 ? doctors[0].id : form.doctorAssignedId"
                  :disabled="userProfile.UserType === 'Doctor'"
                  @change="form.doctorAssignedId = $event"
                /><!--doctors[0]-->
              </v-col>
            </v-row>
            <v-row style="margin-top: 24px; margin-bottom: 0" class="mx-0">
              <v-col style="margin-right: 26px" class="py-0 px-0">
                <div class="label">Gender<span class="required-field">*</span></div>
                <v-btn-toggle
                  v-model="form.sex"
                  class="add-patient__gender"
                  color="#008C89"
                  tile
                  group
                  depressed
                  @change="genderIsTouched = true"
                >
                  <v-btn :value="'Male'" class="gender-btn">
                    Male
                  </v-btn>
                  <v-btn :value="'Female'" class="gender-btn">
                    Female
                  </v-btn>
                </v-btn-toggle>
                <div v-if="!form.sex && genderIsTouched" style="color: #ff5252;font-size: 12px;hyphens: auto;word-wrap: break-word;word-break: break-word;line-height: 12px; letter-spacing: 0; padding: 2px 12px 0">
                  This field is required
                </div>
              </v-col>

              <v-col style="margin-right: 10px" class="py-0 px-0 height-selection">
                <div class="label">Height (ft, in)</div>
                <v-menu
                  v-model="menuHeight"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <Input
                      outlined
                      type="text"
                      autocomplete="off"
                      placeholder="Height"
                      radius="8"
                      :min="0"
                      :value="displayHeight"
                      readonly
                      v-on="on"
                      @click="openSelector"
                    />
                  </template>

                  <v-card class="selector">
                    <v-card-text>
                      <div class="height-selector ">
                        <div class="picker arrows">
                          <div class="swiper-container feet">
                            <div class="swiper-wrapper">
                              <div class="swiper-slide"
                                   v-for="(feet, index) in extendedFeetOptions"
                                   :key="'feet-' + index">
                                {{ feet }}'
                              </div>
                            </div>
                          </div>
                          <div class="swiper-container inches">
                            <div class="swiper-wrapper">
                              <div class="swiper-slide"
                                   v-for="(inch, index) in extendedInchesOptions"
                                   :key="'inch-' + index">
                                {{ inch }}"
                              </div>
                            </div>
                          </div>
                          <div class="selected-values"></div>
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <Button btn-bg="white" class="white-btn selector__white-btn" @click.native="cancel">
                        <span style="color: #16192C; font-size: 13px; font-weight: 600; letter-spacing: 0">Cancel</span>
                      </Button>

                      <Button btn-bg="dark-green" class="ml-4 selector__green-btn" @click.native="confirm">
                        <span style="color: #ffffff; font-size: 12px; font-weight: 600; letter-spacing: 0">Ok</span>
                      </Button>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col class="py-0 px-0 weight-selection">
                <div class="label">Weight (lb)</div>
                <Input
                  outlined
                  type="number"
                  autocomplete="off"
                  placeholder="Weight"
                  radius="8"
                  :min="0"
                  @input="form.weight=+$event"
                  :value="form.weight"
                />
              </v-col>
            </v-row>
            <v-row style="margin-top: 24px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0 birthdate-cell" style="position: relative; margin-right: 26px; max-width: 198px">
                <div class="label">Birthdate</div><!--<span class="required-field">*</span>-->
                <PhoneInput
                  radius="8"
                  :mask="'##/##/####'"
                  :placeholder="' '"
                  :value="form.customBirthDate"
                  @input="form.birthDate=$event"
                />
                <p @click="openDatePickerBirthDate"><Img :src="getImgWithExt(['icons', 'date', 'svg'])"  style="z-index: 9;cursor: pointer;position: absolute;right: 17px;top: 38px;" class="input-icon" />
                </p>
                <datepicker
                  ref="birthdateInput"
                  style="position: absolute;right: 12px;opacity: 0;width: 48%;top: 21px;"
                  @date:update="form.birthDate = $moment($event).format('MM/DD/YYYY'), form.customBirthDate=form.birthDate"
                  :max="fiveYearsAgo"
                  :showCurrent="fiveYearsAgo"
                  :value="form.birthDate && form.birthDate.substring(0, 10)"
                /><!--:rules="[filedIsRequired.required('Birth Date required')]" v-if="form.birthDate || dialog.name == 'addPatient'"-->
              </v-col>
              <v-col class="py-0 px-0">
                <div class="label">Dietary</div>
                <div class="d-flex add-patient__dietary">
                  <v-checkbox
                    class="px-0 py-0 my-0"
                    color="#008C89"
                    v-model="form.vegetarian"
                    label="Vegetarian"/>
                  <v-checkbox
                    color="#008C89"
                    class="px-0 py-0 my-0"
                    v-model="form.glutenFree"
                    label="Gluten free"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0" v-if="false">
              <v-col>
                <v-switch v-if="dialog.name == 'editPatient'"
                  label="Active"
                  :input-value="toggleActivate"
                  @change="toggleActivate=$event"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="tabIndex == 1">
            <v-row style="margin-top: 26px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0" style="margin-right: 5px">
                <div class="label">Cell Phone</div>
                <div style="position: relative">
                  <PhoneInput
                    :isAutofocus="isAutofocusSecondTab"
                    radius="8"
                    :value="form.cellPhone"
                    @input="form.cellPhone=$event"
                  />
                  <div v-if="form.cellPhone && form.cellPhone.length > 2" style="position: absolute; top: 10px; right: 10px; cursor: pointer;" @click="clearField('cellPhone')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </div>
              </v-col>
              <v-col class="py-0 px-0" style="margin-left: 5px">
                <div class="label">Home Phone</div>
                <div style="position: relative">
                  <PhoneInput
                    radius="8"
                    :value="form.homePhone"
                    @input="form.homePhone=$event"
                  />
                  <div v-if="form.homePhone && form.homePhone.length > 2" style="position: absolute; top: 10px; right: 10px; cursor: pointer;" @click="clearField('homePhone')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row style="margin-top: 4px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0">
                <div class="label">Work Phone</div>
                <div style="position: relative">
                  <PhoneInput
                    radius="8"
                    :value="form.workPhone"
                    @input="form.workPhone=$event"
                  />
                  <div v-if="form.workPhone && form.workPhone.length > 2" style="position: absolute; top: 10px; right: 10px; cursor: pointer;" @click="clearField('workPhone')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </div>
              </v-col>
              <v-col class="px-0 py-0 mx-0 my-0 ml-5 d-flex align-center add-patient__language">
                <div class="label pb-0 pr-3">Language</div>
                <v-checkbox
                  class="px-0 py-0 my-0"
                  color="#008C89"
                  v-model="form.spanish"
                  label="Spanish"/>
              </v-col>
            </v-row>
            <v-row style="margin-top: 4px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0">
                <div class="label">Address</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.line1"
                  @input="form.line1=$event"
                >
                  <div v-if="form.line1 && form.line1.length > 2" slot="append" style="cursor: pointer" @click="clearField('line1')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </Input>
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 d-flex align-center">Line 2</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line2=$event" :value="form.line2" autocomplete="off" />
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 Sd-flex align-center">Line 3</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line3=$event" :value="form.line3" autocomplete="off" />
              </v-col>
            </v-row>
            <v-row style="margin-top: 4px; margin-bottom: 0" class="mx-0">
              <v-col class="py-0 px-0" style="flex: 0 0 180px; margin-right: 10px">
                <div class="label">City</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.city"
                  @input="form.city=$event"
                >
                  <div v-if="form.city && form.city.length > 2" slot="append" style="cursor: pointer" @click="clearField('city')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </Input>
              </v-col>
              <v-col class="py-0 px-0" style="flex: 0 0 180px;  margin-right: 10px">
                <div class="label">State</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.state"
                  @input="form.state=$event"
                >
                  <div v-if="form.state && form.state.length > 2" slot="append" style="cursor: pointer" @click="clearField('state')">
                    <img :src="getImgWithExt(['icons', 'clear', 'svg'])" alt="clear-icon" />
                  </div>
                </Input>
              </v-col>
              <v-col class="py-0 px-0" style="width: 130px">
                <div class="label">ZIP</div>
                <PhoneInput outlined
                       radius="8"
                       placeholder="XXXXX"
                       :mask="'#####'"
                       :value="form.zip"
                       @input="form.zip=$event"
                />
              </v-col>
            </v-row>
            <div>
              <div class="title">Price Details</div>
                <v-row
                  :class="['row-switch', 'd-flex', 'align-center', 'mx-0', 'mt-1', 'mb-0', 'py-1']"
                  style="height: 44px; border-radius: 2px; padding-left: 10px; padding-right: 2px;"
                  :style="useSalesTax ? 'background-color: #F7FAFC;' : ''"
                >
                <v-col cols="8" class="px-0 py-0">
                  <v-switch
                    v-model="useSalesTax"
                    color="#008C89"
                    label="Use Supplement Sales Tax %"
                  ></v-switch>
                </v-col>
                <v-col cols="3" class="px-0 py-0" style="max-width: 100px; margin-left: auto;">
                  <Input
                    v-if="useSalesTax"
                    type="Number"
                    min="0"
                    max="100"
                    radius="8"
                    :value="form.salesTaxPercent"
                    @input="form.salesTaxPercent=+$event"
                  />
                </v-col>
              </v-row>
              <v-row
                :class="['row-switch', 'd-flex', 'align-center', 'mx-0', 'mt-1', 'mb-0', 'py-1']"
                style="height: 44px; border-radius: 2px; padding-left: 10px; padding-right: 2px;"
                :style="useProductsSalesTaxPercent ? 'background-color: #F7FAFC;' : ''"
              >
                <v-col cols="8" class="px-0 py-0">
                  <v-switch
                    color="#008C89"
                    v-model="useProductsSalesTaxPercent"
                    label="Use Non-Supplement Sales Tax %"
                  ></v-switch>
                </v-col>
                <v-col cols="3" class="px-0 py-0" style="max-width: 100px; margin-left: auto;">
                  <Input
                    v-if="useProductsSalesTaxPercent"
                    type="Number"
                    min="0"
                    max="100"
                    radius="8"
                    :value="form.productsSalesTaxPercent"
                    @input="form.productsSalesTaxPercent=+$event"
                  />
                </v-col>
              </v-row>
              <v-row
                :class="['row-switch', 'd-flex', 'align-center', 'mx-0', 'mt-1', 'mb-0', 'py-1']"
                style="height: 44px; border-radius: 2px; padding-left: 10px; padding-right: 2px;"
                :style="usePriceModifier ? 'background-color: #F7FAFC;' : ''"
              >
                <v-col cols="8" class="px-0 py-0">
                  <v-switch
                    color="#008C89"
                    v-model="usePriceModifier"
                    label="Supplement Discount/Increase %"
                  ></v-switch>
                </v-col>
                <v-col cols="3" class="px-0 py-0" style="max-width: 100px; margin-left: auto;">
                  <Input
                    v-if="usePriceModifier"
                    radius="8"
                    type="Number"
                    :min="'-100'"
                    :max="100"
                    :value="form.priceModifierPercent"
                    @input="form.priceModifierPercent=+$event"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-else>
            <template v-if="dialog.name === 'addPatient'">
              <v-row style="margin-top: 29px; margin-bottom: 0" class="mx-0 custom-checkbox" v-if="subscriptionFeatures.features.$values.includes('Portal')">
                <v-col class="py-0 px-0">
                  <div class="add-patient__visit-invitation">
                    <v-checkbox
                      class="my-0 mx-0"
                      color="#008C89"
                      autofocus
                      tabindex="0"
                      v-model="form.registerPortal"
                      label="Invite to the portal"/>
                    <p>If you want the Patient to complete surveys online or just want them to have Portal Access, check here to Invite Patient to the Portal.</p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mx-0 mb-0" style="margin-top: 23px">
                <v-col class="px-0 py-0" style="color: #27272E; font-weight: 600; font-size: 16px;">
                  Select the Evaluation Type
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-4 mb-0">
                <v-col class="px-0 py-0">
                  <div class="d-flex align-center justify-space-between add-patient__visit-invitation">
<!--                    <v-checkbox-->
<!--                      color="#008C89"-->
<!--                      v-model="form.none"-->
<!--                      class="round-checkbox"-->
<!--                      :class="{'disabled': form.none}"-->
<!--                      label="None"/>-->
<!--                    <v-checkbox-->
<!--                      color="#008C89"-->
<!--                      v-model="form.visitInvitation"-->
<!--                      class="round-checkbox"-->
<!--                      :class="{'disabled': form.visitInvitation}"-->
<!--                      label="Survey Evaluation"/>-->
<!--                    <v-checkbox-->
<!--                      color="#008C89"-->
<!--                      v-model="form.nonSurvey"-->
<!--                      class="round-checkbox"-->
<!--                      :class="{'disabled': form.nonSurvey}"-->
<!--                      label="Non-Survey Evaluation"/>-->

                    <v-radio-group class="mx-0 my-0" v-model="selectedOption" row>
                      <v-radio
                        color="#008C89"
                        label="None"
                        value="none"
                      />
                      <v-radio
                        color="#008C89"
                        label="Survey Evaluation"
                        value="visitInvitation"
                      />
                      <v-radio
                        color="#008C89"
                        label="Non-Survey Evaluation"
                        value="nonSurvey"
                      />
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mx-0 mb-0" style="margin-top: 26px">
                <v-col class="px-0 py-0 add-patient__evaliation-date">
                  <p class="label">Add Evaluation Date</p>
                </v-col>
              </v-row>
              <v-row class="mx-0 mb-0" style="margin-top: 8px">
                <v-col class="px-0 py-0 d-flex align-end" style="flex: 0 0 198px">
                  <datepicker
                    :min="nowDate"
                    :disabled="selectedOption === 'none'"
                    v-model="form.plannedVisitDate"
                    @date:update="form.plannedVisitDate = $event" />
                </v-col>
                <v-col v-if="selectedOption !== 'none' && selectedOption !== 'nonSurvey' && (form.registerPortal || selectedOption === 'visitInvitation')" class="d-flex align-center justify-center add-patient__preview" @click="openPreviewEmail({ isPortal: true })">
                  <Img :src="getImgWithExt(['icons', 'preview-icon', 'svg'])" alt="preview" />
                  <span>Preview</span>
                </v-col>
              </v-row>


<!--              <v-row class="ml-2 mt-0">
                <v-col class="pb-0 pt-1">
                  <div class="label mb-1 d-flex justify-space-between">
                    <div>Optional Evaluation Notes</div>
                    <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: false })"><v-icon>mdi-email-open</v-icon> Preview</div>
                  </div>
                  <Textarea
                    dense
                    auto-grow
                    :rows="2"
                    outlined
                    @input:text-area="form.visitInvitationMessage=$event"
                    :value="form.visitInvitationMessage"
                    :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                  />
&lt;!&ndash;                  <div class="mt-1 short-message">
                    <span>Patient will recieve email with <span class="bold">instruction</span></span>
                  </div>&ndash;&gt;
                </v-col>
              </v-row>
              <v-row class="ml-2 mt-4 d-flex align-center justify-space-between">
                <v-col class="pt-0 add-patient__survey-form">
                  <v-checkbox
                    style="padding: 4px 0"
                    color="#008C89"
                    v-model="form.addSurveyForm"
                    label="Add Survey Form to Email"/>
                </v-col>
                <v-col class="pt-0" cols="5">
                  <Select outlined
                          radius="8"
                          itemText="name"
                          itemValue="code"
                          :items="settingsForms"
                          :value="settingsForms[0]"
                          @change="changeSurveyForm($event)"
                  />
                </v-col>
              </v-row>-->
<!--              <v-row class="ml-2 mt-1 d-flex align-center justify-space-between">
                <v-col class="pt-0 add-patient__survey-form">
                  <v-checkbox
                    style="padding: 4px 0"
                    color="#008C89"
                    v-model="form.printSurveyForm"
                    label="Print Survey Form"/>
                </v-col>
                <v-col class="pt-0" cols="5">
                  <Select outlined
                          radius="8"
                          itemText="name"
                          itemValue="code"
                          :items="settingsForms"
                          :value="settingsForms[0]"
                          @change="form.printSurveyFormCode=$event"
                  />
                </v-col>
              </v-row>-->
<!--              <template v-if="subscriptionFeatures.features.$values.includes('Portal') || subscriptionFeatures.productName.includes('Trial')">
                <v-row class="ml-2 mt-0">
                  <v-col class="pb-0 mt-2">
                    <div class="d-flex align-center add-patient__register-portal">
                      <v-checkbox
                        style="padding: 0"
                        color="#008C89"
                        v-model="form.registerPortal"
                        label="Invite to the portal"/>
                      &lt;!&ndash;                    <v-icon color="#BCB3E2">mdi-help-circle</v-icon>&ndash;&gt;
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mb-3 ml-2 mt-0">
                  <v-col class="pb-0 pt-1">
                    <div class="label mb-1 d-flex justify-space-between">
                      <div>Optional Portal Invite Note</div>
                      <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: true })"><v-icon>mdi-email-open</v-icon> Preview</div>
                    </div>
                    <Textarea
                      dense
                      auto-grow
                      outlined
                      :rows="2"
                      @input:text-area="form.registerPortalMessage=$event"
                      :value="form.registerPortalMessage"
                      :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                    />
&lt;!&ndash;                    <div class="mt-1 short-message">
                      <span>Patient will recieve email with <span class="bold">instruction to register on Patient Portal</span></span>
                    </div>&ndash;&gt;
                  </v-col>
                </v-row>
              </template>-->
            </template>
          </template>
        </v-form>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
      <template slot="actions" v-if="dialog.name == 'addPatient'">
        <div :class="['w100 d-flex dialog-buttons', { 'justify-between': tabIndex !== 0, 'justify-end': tabIndex === 0 }]">
          <Button btn-bg="white" class="white-btn" :class="{ 'ml-auto': tabIndex === 0 }" @click.native="TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })">
            <span style="color: #16192C">Cancel</span>
          </Button>

          <Button v-if="tabIndex !== 0" btn-bg="white" class="white-btn ml-auto" @click.native="prevStep">
            <Img :src="getImgWithExt(['icons', 'chevron-left', 'svg'])" alt="chevron left" />
            <span style="color: #16192C; padding-left: 10px;">Back</span>
          </Button>

          <Button v-if="tabIndex !== tabs.length - 1" btn-bg="grey" class="ml-5 grey-btn"  @click-btn="nextStep"
                  :disabled="!form.firstName || !form.lastName || customCheck() || !form.sex"
          >
            <span style="color: #27272E; padding-right: 10px;">Next</span>
            <Img :src="getImgWithExt(['icons', 'chevron-right', 'svg'])" alt="chevron right" />
          </Button>

          <Button v-if="tabIndex === tabs.length - 1" btn-bg="dark-green" class="ml-5" @click.native="add">
            <span style="color: white">Save</span>
          </Button>
        </div>
      </template>

      <template slot="actions" v-else-if="dialog.name == 'editPatient'">
        <div class="w100 d-flex dialog-buttons">
          <Button btn-bg="white" class="ml-auto white-btn" @click.native="TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })">
            <span style="color: #16192C">Cancel</span>
          </Button>
          <Button btn-bg="dark-green" class="ml-5" @click.native="update">
            <span style="color: white">Save</span>
          </Button>
        </div>
      </template>

      <template slot="actions" class="pt-1" v-else>
        <div class="w100 d-flex justify-space-between pt-0 py-4 pl-3 pr-3 align-center">
          <div class="legend-errors">
            <span class="required-field">*</span> - <span class="info-text">Required fields</span>
          </div>
        </div>
      </template>
    </Dialog>
    <preview-email-template v-if="dialog.name === 'previewEmailTemplateDialog'" @restoreTabIndex="restoreTabIndex" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Validation from '@/mixins/Validation'
import Dialog from '@/components/layouts/dialog'
import Input from '@/components/elements/Input'
import Select from '@/components/elements/Select'
import PhoneInput from '@/components/elements/phone-input'
import datepicker from '@/components/elements/datepicker'
import Button from '@/components/elements/Button'
import Tabs from '@/components/layouts/Tabs'
import Stepper from '@/components/layouts/Stepper'
// import customSwitch from '@/components/elements/custom-switch'
import Textarea from '@/components/elements/Textarea'
import Img from '@/components/elements/Img'
import Image from '@/mixins/Image'
import Swiper from 'swiper'
import previewEmailTemplate from '@/components/layouts/dialogs/preview-email-template'

const GENDER = [{ name: 'Male' }, { name: 'Female' }]

export default {
  name: 'add-patient',
  props: {
    query: {
      type: Object
    }
  },
  mixins: [Validation, Image],
  components: {
    Dialog,
    Input,
    Select,
    datepicker,
    PhoneInput,
    Button,
    Tabs,
    Stepper,
    Textarea,
    Img,
    previewEmailTemplate
  },
  data: () => ({
    radioGroup: null,
    toggleActivate: true,
    isValidEmail: true,
    genderIsTouched: false,
    form: {
      customDate: null,
      firstName: '',
      lastName: '',
      email: null,
      doctorAssignedId: null,
      height: null,
      weight: null,
      sex: null,
      vegetarian: false,
      glutenFree: false,
      birthDate: null,
      customBirthDate: null,
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      city: null,
      state: null,
      zip: null,
      homePhone: null,
      workPhone: null,
      cellPhone: null,
      number: null,
      spanish: false,
      none: true,
      visitInvitation: false,
      nonSurvey: false,
      plannedVisitDate: new Date(new Date().setDate(new Date().getDate() + 14)).toLocaleDateString('en-US'),
      registerPortal: true,
      // visitInvitationMessage: null,
      registerPortalMessage: null,
      addSurveyForm: null,
      addSurveyFormCode: null,
      printSurveyForm: null,
      printSurveyFormCode: null,
      salesTaxPercent: 0,
      productsSalesTaxPercent: 0,
      priceModifierPercent: 0,
    },
    selectedOption: 'none',
    useSalesTax: false,
    useProductsSalesTaxPercent: false,
    usePriceModifier: false,
    tabIndex: 0,
    nowDate: new Date().toISOString().slice(0, 10),
    fiveYearsAgo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10), // new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString().slice(0, 10),
    loading: false,
    isAutofocusFirstTab: true,
    isAutofocusSecondTab: false,
    isAutofocusThirdTab: false,
    menuHeight: false,
    selectedFeet: 6,
    selectedInches: 2,
    feetOptions: Array.from({ length: 9 }, (_, i) => i + 1),
    inchesOptions: Array.from({ length: 12 }, (_, i) => i)
    // endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()).toISOString().slice(0, 10)
  }),
  async created () {
    this.getDoctors()
    if (this.dialog.name === 'editPatient') {
      await this.getPatient({ id: this.dialog.id, urlParams: { includeVisitsHistory: true } })
      this.$set(this.$data, 'radioGroup', this.patient.sex === 'Female' ? 1 : 0)
      this.form = Object.assign({}, this.patient)
      this.form.customBirthDate = this.$moment(this.form.birthDate).format('MM/DD/YYYY')
      this.useSalesTax = this.patient.salesTaxPercent !== null ? 1 : 0
      this.useProductsSalesTaxPercent = this.patient.productsSalesTaxPercent !== null ? 1: 0
      this.usePriceModifier = this.patient.priceModifierPercent !== null ? 1 : 0
      this.form.doctorAssignedId = this.patient.doctorAssignedId
    }
  },
  mounted () {
    this.form.addSurveyFormCode = this.settingsForms[0].code
    this.isAutofocusFirstTab = false
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', [
      'getDoctors',
      'addPatient',
      'validateEmail',
      'getPatients',
      'getPatient',
      'updatePatient'
    ]),
    restoreTabIndex(previousTabIndex) {
      this.tabIndex = previousTabIndex
    },
    openDatePickerBirthDate() {
      this.$refs.birthdateInput.menu = true
    },
    customCheck() {
      if (this.doctors.length === 1) {
        return false
      } else {
        return !this.form.doctorAssignedId
      }
    },
    async add () {
      this.loading = true
      if (this.validate()) {
        this.form.visitInvitation = this.selectedOption === 'visitInvitation'
        this.form.nonSurvey = this.selectedOption === 'nonSurvey'
        this.form.none = this.selectedOption === 'none'

        if (!this.useSalesTax) {
          this.form.salesTaxPercent = null
        }

        if (!this.useProductsSalesTaxPercent) {
          this.form.productsSalesTaxPercent = null
        }

        if (!this.usePriceModifier) {
          this.form.priceModifierPercent = null
        }

        if(!this.subscriptionFeatures.features.$values.includes('Portal')) {
          this.form.registerPortal = false
        }

        if (this.form.birthDate === '') {
          this.form.birthDate = null;
        }

        const form = Object.assign({}, this.form)

        delete form.customDate
        delete form.customBirthDate

        form.visitInvitation = this.selectedOption === 'visitInvitation' || this.selectedOption === 'nonSurvey'
        // if (form.none) {
        //   form.visitInvitation = false
        // }
        //
        // if (form.visitInvitation || form.nonSurvey) {
        //   form.visitInvitation = true
        // }

        const res = await this.addPatient(
          Object.assign(form, {
            plannedVisitDate: this.$moment(form.plannedVisitDate).format('MM/DD/YYYY'),
            doctorAssignedId: this.doctors.length === 1 ? this.doctors[0].id : form.doctorAssignedId
          })
        )
        this.$emit('showPopup', { type: 'add-patient', data: res })
        // await this.getPatients(this.query)
      }
      this.loading = false
    },
    async update () {
      this.loading = true
      const isValid = await this.checkEmail()

      if(!isValid) {
        this.tabIndex = 0

        this.$nextTick(() => {
          this.$refs.tabsComponent.tab = 0

          if (this.$refs.emailInput && this.$refs.emailInput.$el) {
            const inputElement = this.$refs.emailInput.$el.querySelector('input')
            if (inputElement) {
              inputElement.focus()

              setTimeout(() => {
                inputElement.blur()
              }, 1)
            }
          }
        })
        this.loading = false
        return
      }

      if (this.validate()) {
        if (!this.useSalesTax) {
          this.form.salesTaxPercent = null
        }
        if (!this.useProductsSalesTaxPercent) {
          this.form.productsSalesTaxPercent = null
        }
        if (!this.usePriceModifier) {
          this.form.priceModifierPercent = null
        }
        if (this.form.birthDate === '') {
          this.form.birthDate = null;
        }

        const form = Object.assign({}, this.form)
        delete form.customDate
        delete form.customBirthDate
        const res = await this.updatePatient({ id: this.dialog.id, form: form })
        this.$emit('showPopup', { type: 'edit-patient', data: res })
        await this.getPatients(this.query)
      }
      this.loading = false
    },
    async nextStep() {
      const isEmailValid = await this.checkEmail()
      if (!isEmailValid) {
        return;
      }

      if (this.tabIndex < this.tabs.length - 1) {
        this.tabIndex++;
      }
    },
    prevStep() {
      if (this.tabIndex > 0) {
        this.tabIndex--;
      }
    },
    changeSurveyForm (data) {
      if (data.code) {
        this.form.addSurveyFormCode = data.code
      } else {
        this.form.addSurveyFormCode = data
      }
    },
    async checkEmail () {
      const { email } = this.form
      const params = { email }
      if (this.dialog.name === 'addPatient') {
        params.patientId = ''
      } else {
        params.patientId = this.dialog.id
      }
      const res = await this.validateEmail(params)

      this.setDynamicValidationRules({
        loginEmailRules: [
          res.data.validationResults.isValid || res.data.message
        ]
      })

      return res?.data?.success
    },
    openPreviewEmail ({ isPortal }) {
      this.form.visitInvitation = this.selectedOption === 'visitInvitation' || this.selectedOption === 'nonSurvey'
      this.TOGGLE_DIALOG({
        name: 'previewEmailTemplateDialog',
        isOpen: true,
        title: '',
        data: { ...this.form, previousTabIndex: this.tabIndex },
        isPortal,
        isPreviewInvitation: true
      })
    },
    openSelector() {
      this.menuHeight = true;
      this.$nextTick(() => {
        this.initializeSwiper();
      });
    },
    initializeSwiper() {
      const defaults = {
        slidesPerView: 5,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.25,
        freeModeVelocityRatio: 0.25,
        freeModeMinimumVelocity: 0.1,
        mousewheelControl: true,
        mousewheelSensitivity: 0.5,
        loop: true,
        loopAdditionalSlides: 3,
        direction: 'vertical',
        slideToClickedSlide: true,
        centeredSlides: true,
      };
      new Swiper('.swiper-container.feet', {
        ...defaults,
        initialSlide: this.selectedFeet - 1,
        on: {
          slideChange: (swiper) => {
            const currentSlide = swiper.slides[swiper.activeIndex];
            if (currentSlide) {
              this.selectedFeet = currentSlide.innerText.replace("'", "");
            }
          }
        }
      });
      new Swiper('.swiper-container.inches', {
        ...defaults,
        initialSlide: this.selectedInches,
        on: {
          slideChange: (swiper) => {
            const currentSlide = swiper.slides[swiper.activeIndex];
            if (currentSlide) {
              this.selectedInches = currentSlide.innerText.replace('"', "");

              if (this.selectedInches < 10) {
                this.selectedInches = `0${this.selectedInches}`;
              }
            }
          }
        }
      });
    },
    confirm() {
      if (this.selectedFeet !== null && this.selectedInches !== null) {
        this.form.height = parseFloat(`${this.selectedFeet}.${this.selectedInches}`)
      }
      this.menuHeight = false;
    },
    cancel() {
      this.selectedFeet = null;
      this.selectedInches = null;
      this.menuHeight = false;
    },
    clearField(field) {
      this.form[field] = '';
    },
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    patient: state => state.patients?.patient,
    genderBtns: () => GENDER,
    tabs () {
      if (this.dialog.name === 'addPatient') {
        return [
          { title: 'Personal', component: 'Personal Detais' },
          { title: 'Contact And Price', component: 'Contact Details' },
          { title: 'Invites', component: 'Add Evaluation' }
        ]
      }
      if (this.dialog.name === 'editPatient') {
        return [
          { title: 'Personal Details', component: 'Personal Detais' },
          { title: 'Contact And Price', component: 'Contact Details' }
        ]
      }
    },
    userProfile: state => state.userProfile,
    subscriptionFeatures: state => state.subscription,
    settingsForms: state => state.settings.patients.forms.$values.map(item => { return { name: item.name.replace('Survey', '').trim(), code: item.code } }),
    extendedFeetOptions() {
      return [...this.feetOptions, ...this.feetOptions];
    },
    extendedInchesOptions() {
      return [...this.inchesOptions, ...this.inchesOptions];
    },
    displayHeight() {
      if (this.form.height && typeof this.form.height === 'number') {
        const [feet, inches] = this.form.height.toString().split('.')
        return `${feet}' ${inches || 0}"`
      }
      return ''
    },
  }),
  watch: {
    radioGroup (val) {
      this.form.sex = GENDER[val].name
    },
    tabIndex (val) {
      // if (val !== 0 && val !== 1) {
      //   this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
      //   this.form.registerPortalMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease join out Patient Portal to manage your surveys`
      // }
      switch (val) {
        case 1:
          this.isAutofocusSecondTab = true
          setTimeout(() => {
            this.isAutofocusSecondTab = false
          }, 10)
          break
        case 2:
          this.isAutofocusThirdTab = true
          setTimeout(() => {
            this.isAutofocusThirdTab = false
          }, 10)
          break
      }
    },
    // 'form.plannedVisitDate' (val) {
    //   this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
    // }
    // 'form.none' (val) {
    //   if (val) {
    //     this.form.visitInvitation = false
    //     this.form.nonSurvey = false
    //   }
    // },
    // 'form.visitInvitation' (val) {
    //   if (val) {
    //     this.form.none = false
    //     this.form.nonSurvey = false
    //   }
    // },
    // 'form.nonSurvey' (val) {
    //   if (val) {
    //     this.form.none = false
    //     this.form.visitInvitation = false
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .label {
    color: #425466;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    padding-bottom: 9px;
  }
  .dialog-buttons {
    padding: 4px 20px 28px;

    .button {
      ::v-deep .v-btn {
        height: 56px;
        border-radius: 16px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0;
        padding: 21px 24px;
      }

      &.white-btn {
        ::v-deep .v-btn {
          border: 1px solid #EDF2F7;
        }
      }

      &.grey-btn {
        ::v-deep .v-btn {
          background-color: #E4ECF7;

          &[disabled] {
            pointer-events: none;
          }
        }
      }
    }
  }
  .add-patient {
    .v-form {
      ::v-deep .v-input:not(.v-input--checkbox, .v-input--switch, .v-input--radio-group) {
        &.v-input--is-label-active:not(.v-input--is-focused, .error--text) .v-input__slot {
          background-color: #EDF2F7 !important;
          border-color: transparent !important;
        }
      }
    }
    ::v-deep .v-text-field__details {
      padding-top: 2px;
      margin-bottom: 0;
    }

    .height-selection ::v-deep .v-text-field__details,
    .weight-selection ::v-deep .v-text-field__details {
      display: none;
    }

    ::v-deep .v-dialog__container {
      .v-card {
        display: flex;
        flex-direction: column;
        min-height: 780px;


        @media screen and (max-height: 650px) {
          height: 90vh;
          min-height: 500px !important;
          overflow: scroll;

          &__actions {
            padding-top: 50px;
            position: static !important;
          }
        }

        &__title {
          padding: 36px 36px 20px !important;
          line-height: 38px;
          letter-spacing: 0;
          position: relative;

          .v-image {
            position: absolute;
            right: 36px;
            top: 43px;
            cursor: pointer;
          }
        }

        &__text {
          padding: 0 36px !important;

          .v-tabs-bar {
            margin-left: 0;
          }
        }

        &__actions {
          margin-top: auto;
          width: 100%!important;
        }

        .v-tabs-slider-wrapper {
          height: 1px !important;

          .v-tabs-slider {
            color: #4FAA6E;
            border-radius: 20px;
          }
        }

        .title {
          color: #27272E;
          font-size: 16px;
          line-height: 25px;
          font-weight: 600;
          letter-spacing: 0;
        }

        .v-input--switch {
          margin: 0;
          padding: 0;

          .v-messages {
            display: none;
          }

          .v-input__slot {
            margin: 0;
          }

          .v-input--selection-controls {
            &__input {
              width: 40px;
              height: 22px;
              margin-right: 12px;

              .v-input--switch__thumb {
                background-color: #FFFFFF;
                width: 16px;
                height: 16px;
                box-shadow: none;
                top: calc(50% - 8px);
                left: 3px;
              }

              .v-input--switch__track {
                color: #E4EBF6;
                border-radius: 100px;
                height: 22px;
                width: 40px;
                left: 0;
                right: auto;
                top: 0;
                opacity: 1;
              }

              &:hover {
                .v-input--selection-controls__ripple:before {
                  background: transparent !important;
                }
              }
            }
          }

          &.v-input--is-dirty {
            .v-input--selection-controls__input {
              .v-input--switch__track {
                color: #008C89 !important;
                caret-color: #008C89 !important;

              }
              .v-input--switch__thumb {
                transform: translate(18px, 0);
              }
            }
          }

          .v-label {
            color: #2D3748;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
          }
        }

        .row-switch {
          .input {
            .v-input__slot {
              border: none !important;
              border-radius: 8px;
              background-color: #EDF2F7 !important;
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
    ::v-deep .tabs {
      .v-window {
        display: none;
      }
    }
    ::v-deep .checkbox {
      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .datepicker {
      width: 100%;
    }
    &__visit-invitation {
      & > p {
        color: #718096;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
        margin-top: 3px;
        margin-bottom: 0;
      }

      ::v-deep .v-input {
        display: inline-block;
        border-radius: 8px;
        background-color: #F7FAFC;
        padding: 10px;

        .v-input__slot {
          margin: 0;
        }

        label {
          color: #2D3748;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          margin-left: 4px;
        }
      }
    }
    &__gender {
      background-color: #F7FAFC;
      border-radius: 8px;

      .gender-btn {
        margin: 2px !important;
        border: 0 !important;
        height: 32px !important;
        width: 96px;

        & + .gender-btn {
          margin-left: 0px;
        }

        &.v-btn--active {
          background-color: #008C89;
          ::v-deep .v-btn__content {
            color: #FFFFFF;
          }

          &::before {
            opacity: 1;
          }
        }

        ::v-deep .v-btn__content {
          color: #425466;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0;
          text-transform: initial;
        }
      }

      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__dietary {
      ::v-deep .v-input {
        &:hover {
          background-color: transparent !important;
        }

        .v-input__slot {
          padding: 6px;
          margin-bottom: 0;

          label {
            color: #425466;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0;
          }

          .v-input--selection-controls__input {
            i {
              color: #C9CED6;
            }

            .primary--text {
              color: $dark-green !important;
              caret-color: $dark-green !important;
            }

            &:hover {
              .v-input--selection-controls__ripple {
                &:before {
                  background-color: transparent;
                }
              }
            }
          }
        }
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__language {
      ::v-deep .v-input {
        &__control {
          &:hover {
            background-color: transparent !important;
          }

          .v-input__slot {
            padding: 6px;
            margin-bottom: 0;

            label {
              color: #425466;
              font-weight: 400;
              font-size: 16px;
              letter-spacing: 0;
            }

            .v-input--selection-controls__input {
              i {
                color: #C9CED6;
              }

              .primary--text {
                color: $dark-green !important;
                caret-color: $dark-green !important;
              }

              &:hover {
                .v-input--selection-controls__ripple {
                  &:before {
                    background-color: transparent;
                  }
                }
              }
            }
          }
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__preview {
      flex-grow: 0;
      border-radius: 6px;
      background-color: #E4ECF7;
      padding: 12px 20px;
      margin-left: 20px;
      cursor: pointer;

      span {
        color: #2D3748;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        padding-left: 8px;
      }
    }
    &__gender,
    &__dietary,
    &__survey-form,
    &__register-portal,
    &__evaliation-date {
      .label {
        color: #425466;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        padding: 0;
        margin: 0;
      }

      ::v-deep .v-input {
        padding: 10px 13px 10px 13px;
        label {
          font-size: 14px;
          font-weight: 500;
        }
        &:hover {
          background: #F7FAFC;
          border-radius: 8px;
        }
      }
    }
    &__survey-form {
      ::v-deep .v-input {
       .v-messages {
         display: none!important;
       }
      }
    }
    ::v-deep .select {
      .v-input {
        .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
          color: #B5B5BD !important;
          background: transparent;
        }

        .v-menu__content {
          margin-top: 7px;

          .v-select-list .v-list-item--active {
            background-color: #EBF2FA !important;

            .v-list-item__title {
              color: #425466 !important;
            }
          }
        }
      }

      &.v-input--is-focused {
        .v-input__slot {
          box-shadow: none !important;
        }
      }

      .v-select {
        border: none !important;
        box-shadow: none !important;
      }

      .v-input__slot {
        background-color: #EDF2F7;
      }

      .v-text-field {
        &__details {
          display: none;
        }
      }
    }
    .birthdate-cell {
      .datepicker {
        right: 0 !important;
      }
    }
    .phone-input {
      ::v-deep .v-input {
        .v-input__slot {
          box-shadow: none !important;

          input::placeholder {
            color: #A0AEC0;
            font-weight: 500;
            font-size: 14px;
          }
        }

        &.v-input--is-focused {
          .v-input__slot {
            border: 1px solid #A6B7D4 !important;
            box-shadow: none !important;
            outline: 3px solid #EDF2F7 !important;
          }
        }
      }
    }
    .input {
      ::v-deep .v-input {
        &.error--text:not(.login .registration) .v-input__slot {
          border: 1px solid #FFE6E4 !important;
        }
        &.v-input--is-label-active:not(.login .registration) .v-input__slot input {
          color: #2D3748 !important;
        }
        &.v-input--is-focused {
          .v-input__slot {
            border: 1px solid #A6B7D4 !important;
            box-shadow: none !important;
            outline: 3px solid #EDF2F7;
          }
        }
        &__slot {
          background-color: #EDF2F7;
          box-shadow: none;
          border-color: #EDF2F7;
          border-radius: 8px;
          margin: 0;
          transition: all 0.2s ease-in-out;

          input::placeholder {
            color: #A0AEC0;
            font-weight: 400;
            font-size: 16px;
          }
        }
        .v-messages {
          //display: none;
        }
        &--selection-controls {
          margin: 0;
        }
      }
    }

    .short-message {
      .bold {
        font-weight: 600;
      }
    }
    .required-field {
      color: #F16063;
      padding-left: 4px;
    }
    .legend-errors {
      .info-text {
        font-size: 16px;
        font-weight: 500;
        color: #425466;
      }
    }
    .loading {
      min-height: 100px;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
      z-index: 1;
    }

    ::v-deep .v-input--radio-group {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      .v-input__control {
        .v-input__slot {
          .v-radio {
            padding: 10px;
            margin-right: 10px;

            &.v-item--active {
              background-color: #F7FAFC;
              border-radius: 8px;
            }
            .v-input--selection-controls__input {
              width: 20px;
              height: 20px;

              &:hover {
                .v-input--selection-controls__ripple:before {
                  background: transparent;
                  transform: none;
                }
              }

              .v-icon {
                color: #C9CED6;
              }

              .v-input--selection-controls__ripple {
                height: 20px;
                width: 20px;
                left: 0;
                top: 0;
                margin: 0;
              }
            }

            label {
              color: #2D3748;
              font-size: 14px;
              font-weight: 400;
            }
          }

        }
        .v-messages {
          display: none;
        }
      }
    }
    //.round-checkbox {
    //  margin: 0;
    //
    //  &::v-deep.v-input {
    //    background-color: transparent;
    //    border-radius: 8px;
    //    padding: 10px;
    //    margin: 0;
    //
    //    label {
    //      color: #2D3748;
    //      font-size: 14px;
    //      font-weight: 400;
    //      line-height: 23px;
    //      margin-left: 0px;
    //    }
    //
    //    .v-input--selection-controls__input {
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //
    //      .v-icon {
    //        display: none;
    //      }
    //    }
    //
    //    .v-input__control {
    //      .v-messages {
    //        display: none;
    //      }
    //    }
    //
    //    .v-input--selection-controls__ripple {
    //      width: 20px;
    //      height: 20px;
    //      left: 0;
    //      top: 2px;
    //      border-radius: 50%;
    //      border: 2px solid #C9CED6;
    //      margin: 0;
    //
    //      &::before {
    //        content: "";
    //        width: 10px;
    //        height: 10px;
    //        top: 3px;
    //        left: 3px;
    //        border-radius: 50%;
    //        background-color: transparent;
    //        transition: background-color 0.2s ease;
    //        opacity: 1;
    //        transform: scale(1);
    //      }
    //    }
    //
    //    &.v-input--is-dirty {
    //      background-color: #F7FAFC;
    //
    //      .v-input--selection-controls__ripple {
    //        border: 2px solid #008C89;
    //
    //        &::before {
    //          background-color: #008C89;
    //        }
    //      }
    //    }
    //  }
    //}
    .custom-checkbox {
      ::v-deep {
        .v-input {
          &__control {
            .v-input__slot {
              .v-input--selection-controls__input {
                i {
                  color: #C9CED6;
                }
              }
            }
            .v-messages {
              display: none!important;
            }
          }
        }
        .v-input.disabled {
          pointer-events: none!important;
        }
      }
    }
    .preview-hide {
      visibility: hidden!important;
      pointer-events: none!important;
    }
  }

  @media screen and (max-width: 959.98px) {
    ::v-deep .v-stepper:not(.v-stepper--vertical) {
      .v-stepper__label {
        display: block !important;
      }
      .v-stepper__step__step {
        margin-right: 5px !important;
      }
    }
  }

  .v-menu__content {
    border-radius: 16px;
    box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.08);

    .v-text-field__details {
      display: none;
    }
  }

  ::v-deep .v-card.selector {
    width: 220px;
    border-radius: 16px;
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.1);
    padding: 15px;

    .v-card__text {
      padding: 0;
    }

    .height-selector {
      display: flex;
      justify-content: space-between;
    }

    .v-card__actions {
      justify-content: flex-end;
      padding: 15px 0 0;

      .selector__green-btn {
       .v-btn {
          border-radius: 8px;
          min-width: 57px;
        }
      }
      .selector__white-btn {
        .v-btn {
          border: 1px solid #EDF2F7;
          border-radius: 8px;
          padding: 0 18px;
        }
      }
    }
  }

  .picker {
    position: relative;
    width: 310px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  .swiper-container {
    width: 80px;
    height: 215px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #718096;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .swiper-slide-active {
    color: #425466;
    font-size: 20px;
    line-height: 50px;
  }

  .selected-values  {
    height: 54px;
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    transform: translateY(-50%);
    font-size: 20px;
    border-radius: 18px;
    background-color: #EBF2FA;
  }
</style>
