<template>
  <div class="quick-filter d-flex justify-center">
    <div class="qickfilter__btns d-flex">
      <btn-group :btns="items" :counts="counts" @update:btn="index=$event" group />
    </div>
  </div>
</template>

<script>
import BtnGroup from '@/components/layouts/btn-group'

export default {
  name: 'quick-filter',
  props: {
    items: {
      type: Array
    },
    counts: {
      type: Object
    }
  },
  components: { BtnGroup },
  data: () => ({
    index: 0
  }),
  watch: {
    index (val) {
      this.$emit('updateQuickFilter', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .quick-filter {
    ::v-deep .v-item-group {
      border-radius: 8px;
    }

    ::v-deep .btn-group {
      .v-btn {
        display: flex;
        align-items: center;
        height: 44px;
        letter-spacing: 0;
        border: none;
        border-radius: 8px;
        padding: 12px 10px;
        margin: 0;

        &__content {
          color: #27272E;
          font-weight: 500;
          font-size: 14px;
          line-height: 12px;
          text-transform: none;

          .total {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            color: #718096;
            font-weight: 500;
            font-size: 12px;
            background-color: #E4ECF7;
            border-radius: 18px;
            padding: 1px 6px;
            margin-left: 4px;
          }
        }

        &--active {
          background-color: #FFFFFF !important;
          box-shadow: 0px 0px 3px rgba(12, 26, 75, 0.1);

          .v-btn__content {
            font-weight: 600;
          }

          .total {
            color: $dark-green;
            background-color: rgba(0, 140, 137, 0.16);
          }

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
</style>
