<template>
  <div class="trial-subscription">
    <Dialog width="50%" :showCloseIcon="true" :canCloseWithEsc="true" :scrollable="false" @destroyDialog="onCLoseHandler">

      <template slot="title">
        <template v-if="!previewEmailDialog.isPortal">
          <div style="font-size: 18px" v-if="visitPreview">{{visitPreview.subject}}</div>
        </template>
        <template v-if="previewEmailDialog.isPortal">
          <div style="font-size: 18px" v-if="patientPortalInvitePreview">{{patientPortalInvitePreview.subject}}</div>
        </template>
      </template>

      <template slot="body">
        <template v-if="!previewEmailDialog.isPortal">
          <div class="main-row justify-end" style="color: #008C89" v-if="visitPreview && visitPreview.email">
            E-mail: {{visitPreview.email}}
          </div>
          <div class="main-row justify-center" v-if="visitPreview" v-html="visitPreview.body"></div>
        </template>
        <template v-if="previewEmailDialog.isPortal">
          <div class="main-row justify-end" style="color: #008C89" v-if="patientPortalInvitePreview && patientPortalInvitePreview.email">
            E-mail: {{patientPortalInvitePreview.email}}
          </div>
          <div class="main-row justify-center" v-if="patientPortalInvitePreview" v-html="patientPortalInvitePreview.body"></div>
        </template>
        <template v-if="previewEmailDialog.isPreviewInvitation">
          <div class="main-row justify-end" style="color: #008C89" v-if="visitPreviewInvitation && visitPreviewInvitation.email">
            E-mail: {{visitPreviewInvitation.email}}
          </div>
          <div class="main-row justify-center" v-if="visitPreviewInvitation" v-html="visitPreviewInvitation.body"></div>
        </template>
      </template>

    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Image from '@/mixins/Image'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'preview-email-template',
  components: { Dialog },
  mixins: [Image],
  data: () => ({
    activePaying: 'month',
    tabIndex: null
  }),
  mounted () {
    const data = {
      doctorId: this.previewEmailDialog.data.doctorAssignedId || this.previewEmailDialog.data.doctorId,
      patientId: this.previewEmailDialog.data.patientId || this.previewEmailDialog.data.id || null,
      notes: this.previewEmailDialog.data.notes || this.previewEmailDialog.data.visitInvitationMessage,
      date: this.previewEmailDialog.data.plannedVisitDate ? new Date(this.previewEmailDialog.data.plannedVisitDate) : new Date(this.previewEmailDialog.data.date),
      addSurveyForm: this.previewEmailDialog.data.addSurveyForm || true,
      addSurveyFormCode: this.previewEmailDialog.data.addSurveyFormCode.replace(' ', 'Survey'),
      nonSurvey: this.previewEmailDialog.data.nonSurvey,
      sendInvite: this.subscriptionFeatures.features.$values.includes('Portal') ? this.previewEmailDialog.data.registerPortal : false,
      patientName: `${this.previewEmailDialog.data.firstName} ${this.previewEmailDialog.data.lastName}`,
      visitInvitation: this.previewEmailDialog.data.visitInvitation || this.previewEmailDialog.data.nonSurvey ? true : false
    }
    this.tabIndex = this.previewEmailDialog.data.previousTabIndex || 0
    if (this.previewEmailDialog.isPreviewInvitation) {
      delete data.patientId
      delete data.notes
      delete data.addSurveyForm
      delete data.addSurveyFormCode
      this.getVisitInvitation(data).then(res => {
        this.previewEmailDialog.title = this.visitPreview.subject
      })
    } else {
      data.sendInvite = this.subscriptionFeatures.features.$values.includes('Portal') ? this.previewEmailDialog.data.sendInvite : false
      if (!this.previewEmailDialog.isPortal) {
        if (!data.patientId) {
          data.patientName = this.previewEmailDialog.data.firstName
        }
        this.getVisitPreview(data).then(res => {
          this.previewEmailDialog.title = this.visitPreview.subject
        })
      } else if (this.previewEmailDialog.isPortal) {
        this.getPatientPortalPreviewInvite({
          id: this.previewEmailDialog.data.patientId || this.previewEmailDialog.data.id || null,
          notes: this.previewEmailDialog.data.notes || this.previewEmailDialog.data.visitInvitationMessage,
          name: this.previewEmailDialog.data.firstName
        }).then(res => {
          this.previewEmailDialog.title = this.patientPortalInvitePreview.subject
        })
      }
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('visits', ['getVisitPreview', 'getVisitInvitation']),
    ...mapActions('patients', ['getPatientPortalPreviewInvite']),
    switchPaying (val) {
      this.activePaying = val
    },
    goToSettings () {
      if (this.$route.name === 'Settings') {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
      } else {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
        this.$router.push({ name: 'Settings' })
      }
    },
    onCLoseHandler () {
      this.$emit('restoreTabIndex', this.tabIndex)
      if (this.subscription.expired) {
        if (this.$route.name !== 'Settings') {
          this.$router.push({ name: 'Settings' })
        }
      }
    }
  },
  computed: {
    subscription () {
      return this.$store.state.subscription
    },
    previewEmailDialog () {
      return this.$store.state.dialogs.previewEmailTemplateDialog
    },
    visitPreview () {
      return this.$store.state.visits.visitPreview
    },
    visitPreviewInvitation () {
      return this.$store.state.visits.visitPreviewInvitation
    },
    patientPortalInvitePreview () {
      return this.$store.state.patients.patientPortalPreviewInvite
    },
    subscriptionFeatures: state => state.subscription,
  }
}
</script>

<style lang="scss" scoped>
.trial-subscription {
  ::v-deep .v-dialog__container {
    overflow: hidden;

    .v-dialog__content {
      .v-card {
        height: 80vh;
        &__title {
          //padding: 25px 10px 7px 16px !important;
          background: #edf2f7;
          position: relative;
          justify-content: flex-start !important;

          .v-image {
            right: 20px;
            top: 18px;
          }

          .v-icon {
            bottom: 0!important;
            right: 10px!important;
          }
        }
        &__text {
          overflow-y: scroll;
          height: 70vh;
        }
      }
    }

  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
  .progress-title {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #27272E;
    .days-left {
      color: #008C89;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .progress-block {
    width: 93%;
  }
  .percent-value {
    text-align: end;
    width: 7%;
    font-weight: 600;
    font-size: 14px;
    color: #27272E;
  }
  .footer {
    flex-wrap: wrap;
    .information {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #7A828A;
      .link {
        cursor: pointer;
        color: #668BFF;
      }
    }
  }
  //::-webkit-scrollbar {
  //  width: 5px;
  //  margin-right: 10px;
  //}
  //::-webkit-scrollbar-track {
  //  background: #c3d2e5;
  //}
  //::-webkit-scrollbar-thumb {
  //  background: red;
  //}
}
</style>
