<template>
  <v-dialog
    :persistent="!canCloseManually"
    attach
    v-bind="$props"
    :value="dialog"
    @click:outside="destroyModal"
  >
    <v-card>
      <v-card-title v-if="!hideTitle" class="px-0 pt-0 d-flex justify-space-between">
        <slot name="title" />
        <Img v-if="showCloseIcon" @click.native="close" :src="getImgWithExt(['icons', 'close', 'svg'])" alt="close" />
      </v-card-title>

      <v-card-text>
        <slot name="body" />
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'

export default {
  name: 'Dialog',
  components: { Img },
  mixins: [Image],
  props: {
    width: [String, Number],
    scrollable: {
      type: Boolean
    },
    fullscreen: {
      type: Boolean
    },
    hideOverlay: {
      type: Boolean
    },
    hideTitle: {
      type: Boolean
    },
    showIconClose: {
      type: Boolean,
      default: true
    },
    canCloseManually: {
      type: Boolean,
      default: true
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    canCloseWithEsc: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    destroyModal () {
      if(this.canCloseManually) {
        const dialog = Object.assign({}, this.dialog)
        if (!dialog.canCloseManually) {
          dialog.isOpen = false
          this.TOGGLE_DIALOG({ ...dialog })
          this.$emit('destroyDialog')
        }
      }
    },
    close () {
      if (this.dialog.name === 'pendingEvaluation') {
        this.$router.push({ name: 'Patients' })
      }
      this.TOGGLE_DIALOG({ name: this.dialog.name, state: false })
      // this.$emit('destroyDialog')
    },
    handleEscKey(event) {
      if (this.canCloseWithEsc && event.key === 'Escape') {
        this.close()
      }
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  }),
  mounted() {
    if (this.canCloseWithEsc) {
      window.addEventListener('keydown', this.handleEscKey)
    }
  },
  beforeDestroy() {
    this.$emit('destroyDialog')
    if (this.canCloseWithEsc) {
      window.removeEventListener('keydown', this.handleEscKey)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    border-radius: 16px !important;
  }
  .v-card {
    &__title {
      padding: 41px 30px 0 46px !important;
      font-size: 28px !important;
      font-weight: 600 !important;

      .v-icon {
        position: relative;
        bottom: 20px;
        cursor: pointer;
      }
    }
  }
</style>
