<template>
  <div class="tabs" :class="withoutPadding">
    <v-tabs v-model="tab" v-bind="$props" :arrow-show="false" class="tab-container" :class="{'is-disabled': isDisabled}">
      <slot name="sliderColor"/>
        <v-tab v-for="(item, i) in items" :key="i">
          {{item.title || item.label}}
          <span v-if="item.hasOwnProperty('count') && item.count !== null && (item.title || item.label).toLowerCase() !== 'events'" class="badge" :class="{'active-badge': i === tabIndex}">
            {{item.count}}
          </span>
        </v-tab>
      <slot name="buttonControlsForAllTabs"></slot>
    </v-tabs>
    <slot name="newLineControlForAllTabs"></slot>
    <div class="divider" :style="{'height': dividerHeight}"></div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) in items" :key="i">
        <v-card flat class="px-0 py-0" :class="{'is-disabled': isDisabled, 'hidden-bg': isHiddenBg, 'elevation-custom': isElevation}">
          <v-card-text>
            <slot :name="_.toLower(item.component)" />
          </v-card-text>
        </v-card>
        <slot name="totalResult"></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    items: { type: Array },
    tabIndex: {
      type: [Number, String]
    },
    isDisabled: [Boolean],
    isHiddenBg: [Boolean],
    isElevation: [Boolean],
    fixedTabs: Boolean,
    color: String,
    centered: Boolean,
    withoutPadding: String,
    dividerHeight: String
  },
  mounted () {
    this.tab = parseFloat(this.tabIndex) || 0
  },
  data: () => ({
    tab: null
  }),
  watch: {
    tab (val) {
      this.$emit('update:tab', val)
    },
    tabIndex (val) {
      this.tab = val
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  ::v-deep .v-tab {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #CBD5E0 !important;
    text-transform: capitalize;
    ::v-deep .v-window-item {
      background: red!important;
    }
    &--active {
      color: #27272E !important;
    }
    &::before {
      opacity: 0 !important;
    }
  }
  ::v-deep .v-slide-group {
    &__content {
      text-transform: capitalize;
    }
  }
  .v-ripple {
    &__container {
      display: none !important;
    }
  }
  .badge {
    width: 20px;
    height: 20px;
    background-color: #E4ECF7;
    color: #718096;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-left: 5px;
    font-size: 12px;
  }
  .badge.active-badge {
    background-color: rgb(0 140 137 / 16%);
    color: #008C89;
  }
}

::v-deep .v-card.is-disabled {
  background: #f7fafc;
}

::v-deep .v-card.hidden-bg {
  background: transparent!important;
}

::v-deep .v-card.elevation-custom {
  box-shadow: 0px 10px 20px 0px rgba(113, 128, 150, 0.06);
}

::v-deep .v-tabs.is-disabled {
  .v-tabs-bar {
    background: #f7fafc!important;
  }
}

.chat-without-padding {
  ::v-deep .v-window {
    .v-card {
      padding: 0;
      &__text {
        padding: 0;
      }
    }
  }
}

.tab-container:not(.profile .tab-container) {
  ::v-deep .v-tabs-bar {
    height: 40px;
    background-color: transparent !important;
    border-bottom: 1px solid #E2E8F0;
    margin-left: 6px;
  }

  ::v-deep .v-tab {
    display: flex;
    align-items: baseline;
    color: #718096 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: capitalize;
    padding: 0;
    min-width: auto;
  }

  ::v-deep .v-tab + .v-tab {
    margin-left: 50px;
  }

  ::v-deep .v-tab--active {
    color: #2D3748 !important;
  }
}

::v-deep .v-tabs-items {
  background-color: transparent;
}
</style>
